import React, { useState, useContext } from "react";
import { postWithStatus } from "@services/axiosCalls";
import { API } from "@services/urlConstants";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { errorToast } from "@helpers/toastFunctions";
import { UserContext } from "@contexts/UserContext";
import { useTranslation } from "react-i18next";
import Select from "@components/Select";
import { getFilterCancellationReason } from "@services/Filtros/filtros";

const ModalCancelReservation = ({
  reservation,
  setModal,
  status,
  setReservation,
  setLead
}) => { 
  const { t } = useTranslation("modalCancelReservation");
  const [reason, setReason] = useState("");
  const [idStatus, setIdStatus] = useState(null);
  const { user } = useContext(UserContext);

  const saveData = async () => {
    if(!idStatus){
      return errorToast(<span>{t("errors.errorSelect")}</span>)
    }
    try{
      let response = await postWithStatus(API.CANCEL_RESERVA, {
        id_lead: reservation.id_lead,
        id_vehicle: reservation.id_vehiculo,
        id_status: idStatus.value,
        name_status: idStatus.label,
        reason,
        id_reservation: reservation.id,
        id_agent: user,
      });
      if(response.status === 204){
       setReservation(null)
       setModal(false)
       setLead((prevLead) => ({
        ...prevLead,
        id_estado_lead: idStatus.value,
        nombre_estado_lead: idStatus.label,
      }));
      }
    } catch(e) {
      errorToast(<span>{t("errors.errorCancel")}</span>);
    }
  }

  return (
    <Modal
    className="modal-lg"
    centered
    isOpen={status}
    toggle={() => setModal()}
  >
    <ModalHeader>Cancelar Reserva</ModalHeader>
    <ModalBody className="p-4">
      <Select
        placeholder="Motivo de Cancelación"
        loadOptions={getFilterCancellationReason}
        value={idStatus}
        onChange={(opt) => setIdStatus(opt)}
      />

      <input
        type="text"
        className={"mt-2"}
        placeholder={"Añadir comentario (opcional)"}
        value={reason}
        onChange={(e) => setReason(e.target.value)}
      />
    </ModalBody>
    <ModalFooter>
      <button type="button" className="btn btn-light btn-outline-dark btn-flexicar" onClick={() => saveData()}>Guardar</button>
      <button type="button" className="btn btn-light btn-outline-dark btn-flexicar" onClick={() => setModal()}>Cerrar</button>
    </ModalFooter>
  </Modal>
  );
};

export default ModalCancelReservation;