import React from "react";

const Pagination = ({ showNumb, total, changePagination }) => (
  <span>
    <span className="text-primary">
      <span
        className={showNumb === 25 ? "underline" : "pointer"}
        onClick={() => changePagination(0, 25)}
      >
        25
      </span>{" "}
      &nbsp;&nbsp;&nbsp;
      <span
        className={showNumb === 50 ? "underline" : "pointer"}
        onClick={() => changePagination(0, 50)}
      >
        50
      </span>{" "}
      &nbsp;&nbsp;&nbsp;
      <span
        className={showNumb === 100 ? "underline" : "pointer"}
        onClick={() => changePagination(0, 100)}
      >
        100
      </span>{" "}
    </span>
  </span>
);

export default Pagination;
