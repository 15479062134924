import React, { useContext, useRef, useState } from "react";
import { Button, Spinner } from "reactstrap";
import { useTranslation } from "react-i18next";

import Form from "@components/Form/Form";
import FormInput from "@components/Form/Input/FormInput";
import GenericModal from "@components/GenericModal";
import { addLeadCustomerCatcherSchema, addLeadSchema } from "@schema/addleadSchema";
import { API } from "@services/urlConstants";
import instance from "@services/base";
import { UserContext } from "@contexts/UserContext";
import { checkCustomerCatcherRoles, checkTradingAgentRoles } from "@constants/roles";

const AddLead = ({
	isOpen,
	setIsOpen,
	setPhoneState,
	setPlateState,
	setShowModalCreateClient,
	setClientsPurchaseLead,
	setShowModalClientsTable,
	handleClose,
	showModalClientsTable
}) => {
	const { i18n, t } = useTranslation("fichaLeadsCompra");
	const { rol } = useContext(UserContext);

	const isCustomerCatcherUser = checkCustomerCatcherRoles(rol);
	const isTradingAgentUser = checkTradingAgentRoles(rol);
	const isCustomerCatcherAndNotTradingAgentUser = isCustomerCatcherUser && !isTradingAgentUser;

	const locale = i18n.language;
	const formRef = useRef();

	const [loading, setLoading] = useState(false);

	const handleCustomerCatcherPhone = (phone) => {
		setPhoneState(phone);
		return {
			phone: phone
		};
	};

	const handlePhoneOrPlate = (phoneOrMatricula) => {
		if (phoneOrMatricula.length === 9) {
			setPhoneState(phoneOrMatricula);
			return {
				phone: phoneOrMatricula
			};
		}
		if (phoneOrMatricula.trim().length === 7 || phoneOrMatricula.trim().length === 6) {
			setPlateState(phoneOrMatricula);
			return {
				plate: phoneOrMatricula
			};
		}

		return null;
	};

	const checkClientsPurchase = async (data) => {
		try {
			setLoading(true);

			let payload;

			if (isCustomerCatcherAndNotTradingAgentUser) {
				payload = handleCustomerCatcherPhone(data.phoneOrMatricula);
			} else {
				payload = handlePhoneOrPlate(data.phoneOrMatricula);
			};

			const response = await instance.get(API.GET_CLIENTS_QUERY, {
				params: payload
			});

			if (response.data.length) {
				setClientsPurchaseLead(response.data);
				setShowModalClientsTable(true);
			} else {
				setShowModalCreateClient(true);
			}
			if (showModalClientsTable) {
				setIsOpen(false);
			}
		} catch (e) {
			<span>{t("errorAddLead")}</span>;
		} finally {
			setLoading(false);
		}
	};

	return (
		<GenericModal
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			onClose={handleClose}
			headContent={t("addLead")}
			bodyContent={
				<Form
					i18nErrorsFile="fichaLeadsCompra"
					schema={() =>
						isCustomerCatcherAndNotTradingAgentUser
							? addLeadCustomerCatcherSchema
							: addLeadSchema(locale)
					}
					ref={formRef}
					onSubmit={checkClientsPurchase}
				>
					<FormInput
						name="phoneOrMatricula"
						placeholder={
							isCustomerCatcherAndNotTradingAgentUser
								? t("contactPhonecustomerCatcher")
								: t("contactPlate")
						}
					/>
				</Form>
			}
			footerContent={
				<Button
					className="btn-flexicar-orange"
					onClick={() => formRef.current.triggerSubmit()}
					style={{ width: "150px" }}
					disabled={loading}
				>
					{loading ? <Spinner size={"sm"} /> : <span>{t("addLead")}</span>}
				</Button>
			}
		/>
	);
};

export default AddLead;
