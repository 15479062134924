import { BOOKING_PAYMENT_METHODS } from "@constants/bookings";
import * as Yup from "yup";

const bookingFormFirstStepSchema = Yup.object({
	paymentMethod: Yup.string()
		.oneOf(Object.values(BOOKING_PAYMENT_METHODS))
		.required("Por favor selecciona una forma de pago")
});

const bookingFormSecondStepSchema = Yup.object({
	carPayment: Yup.boolean().required(
		"Por favor selecciona si el coche será usado como forma de pago"
	),
	financingWants: Yup.boolean().required("Por favor selecciona si desea financiar"),
	financingBank: Yup.boolean().required("Por favor selecciona si su banco le ofrece financiación")
});

export { bookingFormFirstStepSchema, bookingFormSecondStepSchema };
