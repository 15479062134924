import GenericModal from "@components/GenericModal";

const CatcherWarningModal = ({ isOpen, setIsOpen }) => (
	<GenericModal
		isOpen={isOpen}
		setIsOpen={setIsOpen}
		headContent={<span>Lead asignado</span>}
		bodyContent={<span>Cliente con Lead asignado con menos de 72 horas.</span>}
		footerContent={
			<button
				className="btn btn-flexicar-orange"
				onClick={() => setIsOpen(false)}
			>
				Aceptar
			</button>
		}
	/>
);

export default CatcherWarningModal;
