import { useEffect, useRef, useCallback } from "react";
const useAbortController = () => {
    const abortControllersMapRef = useRef(new Map());

    // Cleanup al desmontar: Cancela todas las solicitudes en curso.
    useEffect(() => {
			const abortControllerMap = abortControllersMapRef.current;
			return () => {
				abortControllerMap.forEach((controller) => controller.abort());
				abortControllerMap.clear();
			};
		}, []);

    /**
     * Crea una nueva señal de abort para una solicitud específica.
     * Si una solicitud con el mismo `id` ya existe, la cancela antes de crear una nueva.
     *
     * @param {string} id - Identificador único de la solicitud.
     * @returns {AbortSignal} - La señal de abort asociada a la solicitud.
     */
    const createAbortSignal = useCallback((id) => {
        const controllers = abortControllersMapRef.current;

        if (controllers.has(id)) {
            controllers.get(id).abort()
            controllers.delete(id);
        }

        const newController = new AbortController();
        controllers.set(id, newController);

        return newController.signal;
    }, []);

    /**
     * Cancela una solicitud específica por su `id`.
     *
     * @param {string} id - Identificador de la solicitud a cancelar.
     */
    const abortRequest = useCallback((id) => {
        const controllers = abortControllersMapRef.current;

        if (controllers.has(id)) {
            controllers.get(id).abort(); 
            controllers.delete(id);
        }
    }, []);

    /**
     * Cancela todas las solicitudes en curso.
     */
    const abortAll = useCallback(() => {
        abortControllersMapRef.current.forEach((controller) => controller.abort());
        abortControllersMapRef.current.clear();
    }, []);

    return { createAbortSignal, abortRequest, abortAll };
};
export default useAbortController