import React, { useContext } from "react";
import { FaGoogle } from "react-icons/fa";

import styles from "./GoogleLogin.module.scss";

import { UserContext } from "@contexts/UserContext";
import { useHistory, useLocation } from "react-router-dom";

export default function GoogleLogin() {
	const { isAuthenticated, user, logInSocial, error } = useContext(UserContext);
	const history = useHistory();
	const location = useLocation();

	if (isAuthenticated && user) {
		const { from } = location.state || { from: { pathname: "/" } };
		history.replace(from);
	}

	return (
		<div className={styles.loginWrapper}>
			<div className="d-flex align-items-center flex-column justify-content-between py-4">
				<img
					src="https://crm-dev.flexicar.es/public/logo.png"
					className="mb-3"
					alt="flexicar-logo"
				/>
				<h2>Acceso con Google</h2>

				<div className="mt-4 d-flex justify-content-center mb-4">
					<button
						className="btn-flexicar d-block"
						onClick={logInSocial}
					>
						<FaGoogle
							className="mr-2 mt-1"
							style={{ verticalAlign: "top" }}
							size={22}
						/>
						<span className="font-weight-bold">CONECTAR</span>
					</button>
				</div>
				{error && (
					<div
						className="alert alert-danger"
						role="alert"
					>
						<p>{error.message}</p>
					</div>
				)}
			</div>
		</div>
	);
}
