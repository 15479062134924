import { useContext, useState } from "react";

import SellContract from "@views/SellContract/SellContract";
import { UserContext } from "@contexts/UserContext";
import instance from "@services/base";
import { API } from "@services/urlConstants";
import {
	getFilterDealershipsLocals,
	getFilterGestorias,
	getFilterProvinces
} from "@services/Filtros/filtros";
import { errorToast, successToast } from "@helpers/toastFunctions";
import {
	insouranceOptions,
	ivaTypeOptions,
	termOptions,
	transportDeliveryTypeOptions,
	transportSellTypeOptions,
	yesOrNotSelect,
	payOptions
} from "@constants/selectOptions";
import BankOptions from "@constants/bankOptions";
import { SELL_CONTRACT_DEFAULT_PRICES } from "@constants/sellContratDefaults";
import { getWarrantiesOptions } from "@services/Warranties/warranties";
import { formatDateWithoutHour } from "@helpers/formatDate";

const CreateSellContract = ({ data, onCreate }) => {
	const { user: employeeId } = useContext(UserContext);

	const [dealershipsOptions, setDealershipsOptions] = useState([]);
	const [gestoriasOptions, setGestoriasOptions] = useState([]);
	const [provincesOptions, setProvincesOptions] = useState([]);
	const [warrantyOptions, setWarrantyOptions] = useState([]);

	const loadDefaultValues = async () => {
		const [dealerships, gestorias, provinces, warranties] = await Promise.all([
			getFilterDealershipsLocals(),
			getFilterGestorias(),
			getFilterProvinces(),
			getWarrantiesOptions(data.lead.vehicle_id)
		]);

		setDealershipsOptions(dealerships);
		setGestoriasOptions(gestorias);
		setProvincesOptions(provinces);
		setWarrantyOptions(warranties);

		const findValue = (arr, findValue) => arr.find((x) => x.value === findValue);

		const agent = `${data.reservation.datos.booking.agent.nombre} ${data.reservation.datos.booking.agent.apellido1} ${data.reservation.datos.booking.agent.apellido2}`;

		const clientDefault = {
			name: data.reservation.nombre,
			firstSurname: data.reservation.apellido1,
			secondSurname: data.reservation.apellido2,
			phone: data.reservation.telefono,
			email: data.reservation.email,
			dni: data.reservation.datos.DNI,
			province: provinces.find(
				(province) => province.value === data.reservation.datos.id_provincia
			),
			town: data.reservation.datos.poblacion,
			address: data.reservation.datos.direccion,
			cp: data.reservation.datos.CP,
			dateOfBirth: data.reservation.datos.fecha_nacimiento
		};

		const vehicleDefault = {
			plate: data.reservation.datos.matricula,
			bastidor: data.reservation.datos.bastidor,
			km: data.reservation.datos.kilometros,
			dealership: dealerships.find((dealership) =>
				dealership.label === (data.reservation.datos.local ?? data.lead.nombre_local)
			),
			dealershipProvince: {
				label: data.reservation.datos.provincia_local,
				value: data.reservation.datos.provincia_local_id
			},
			companie: {
				label: data.reservation.datos.empresa_nombre,
				value: data.reservation.datos.empresa_id
			},
			iva:
				ivaTypeOptions.find((x) => x.value === data.reservation.datos.aplica_iva) ??
				data.lead.aplica_iva
					? ivaTypeOptions[1]
					: ivaTypeOptions[0],
			dateLastITV: data.reservation.datos.fecha_ultima_itv || null,
			dateExpirationITV: data.reservation.datos.fecha_caducidad_itv || null,
			conformidades: data.reservation.datos.conformidades || null
		};

		const bookingDefault = {
			date: formatDateWithoutHour(data.reservation.datos.booking?.date),
			agent,
			price: data.reservation.datos.booking?.price,
			concept: data.reservation.datos.booking?.concept,
			observations: data.reservation.datos.booking?.observations,
			payOptions: findValue(payOptions, data.reservation.datos.booking?.paymentType)
		};

		const sellInfoDefault = {
			priceType: data.reservation.datos.priceType,
			ownershipChangeAmount: data.reservation.datos.coste_transferencia,
			courtesy: data.reservation.datos.agentDiscount.total || 0,
			gestoria: {
				label: data.reservation.datos.gestoria,
				value: data.reservation.datos.id_gestoria
			},
			financing: {
				wants: findValue(yesOrNotSelect, data.reservation.datos.financia),
				discount: data.reservation.datos.importe_financia,
				financialEntity: findValue(BankOptions, data.reservation.datos.entidad_financiera),
				TIN: data.reservation.datos.TIN || SELL_CONTRACT_DEFAULT_PRICES.TIN,
				term: findValue(termOptions, data.reservation.datos.term),
				quota: data.reservation.datos.quota || SELL_CONTRACT_DEFAULT_PRICES.QUOTA
			},
			transport: {
				sellType: transportSellTypeOptions.find(
					(x) => x.value === data.reservation.datos.tipo_venta
				),
				wants: findValue(yesOrNotSelect, data.reservation.datos.transporte),
				delivery: {
					amount: data.reservation.datos.importe_transporte || 350,
					type: findValue(transportDeliveryTypeOptions, data.reservation.datos.entrega),
					dealership:
						data.reservation.datos.local_entrega && data.reservation.datos.local_entrega_id
							? {
									label: data.reservation.datos.local_entrega,
									value: data.reservation.datos.local_entrega_id
							  }
							: null,
					provinceDealership:
						data.reservation.datos.provincia_local_entrega &&
						data.reservation.datos.provincia_local_entrega_id
							? {
									label: data.reservation.datos.provincia_local_entrega,
									value: data.reservation.datos.provincia_local_entrega_id
							  }
							: null,
					useSameAddress: data.reservation.datos.entrega_cambio,
					alternativeAddress: {
						province: provinces.find((x) => x.label === data.reservation.datos.provincia_entrega),
						town: data.reservation.datos.poblacion_entrega,
						address: data.reservation.datos.direccion_entrega,
						CP: data.reservation.datos.CP_entrega
					}
				}
			},
			warranty: warranties ? findValue(warranties, data.reservation.datos.warranty?.typeId) : null,
			warrantyAmount: data.reservation.datos.warranty?.price,
			warrantyIBAN: data.reservation.datos.warranty?.iban_extra,
			insurance: {
				wants: findValue(yesOrNotSelect, data.reservation.datos.seguro_auto),
				company: insouranceOptions.find((x) => x.value === data.reservation.datos.aseguradora_auto),
				financing: findValue(yesOrNotSelect, data.reservation.datos.financia_seguro),
				amount:
					data.reservation.datos.importe_seguro || SELL_CONTRACT_DEFAULT_PRICES.INSURANCE_AMOUNT
			}
		};

		const agentDiscountDefault = {
			total: data.reservation.datos.agentDiscount.total,
			transferCost: {
				selected: data.reservation.datos.agentDiscount.transferCost > 0,
				amount: data.reservation.datos.agentDiscount.transferCost
			},
			transport: {
				selected: data.reservation.datos.agentDiscount.transport > 0,
				amount: data.reservation.datos.agentDiscount.transport
			},
			warranty: {
				selected: data.reservation.datos.agentDiscount.warranty > 0,
				amount: data.reservation.datos.agentDiscount.warranty
			}
		};

		const payAmountsDefault = {
			financingAmount: data.reservation.datos.pago_financiado ?? 0,
			dealershipTPVAmount: data.reservation.datos.pago_tpv_tienda ?? 0,
			virtualTPVAmount: data.reservation.datos.pago_tpv_pasarela ?? 0,
			cashAmount: data.reservation.datos.pago_efectivo ?? 0,
			transferenceAmount: data.reservation.datos.pago_transferencia ?? 0,
			carPayAmount: data.reservation.datos.pago_coche_forma_pago ?? 0
		};

		const defaultValues = {
			client: clientDefault,
			vehicle: vehicleDefault,
			booking: bookingDefault,
			sellInfo: sellInfoDefault,
			agentDiscount: agentDiscountDefault,
			payAmounts: payAmountsDefault,
			observations: data.reservation.datos.observaciones || null
		};

		return defaultValues;
	};

	const createContract = async (formData) => {
		const clientData = {
			nombre: formData.client.name,
			apellido1: formData.client.firstSurname,
			apellido2: formData.client.secondSurname || null,
			telefono: formData.client.phone,
			email: formData.client.email,
			DNI: formData.client.dni,
			id_provincia: formData.client.province.value,
			provincia: formData.client.province.label,
			poblacion: formData.client.town,
			direccion: formData.client.address,
			CP: formData.client.cp,
			fecha_nacimiento: formData.client.dateOfBirth
		};

		const vehicleData = {
			matricula: formData.vehicle.plate,
			kilometros: formData.vehicle.km,
			bastidor: formData.vehicle.bastidor,
			id_local: formData.vehicle.dealership.value,
			local: formData.vehicle.dealership.label,
			id_provincia_local: formData.vehicle.dealershipProvince.value,
			provincia_local: formData.vehicle.dealershipProvince.label,
			empresa_id: formData.vehicle.companie.value,
			empresa_nombre: formData.vehicle.companie.label,
			aplica_iva: formData.vehicle.iva.value,
			fecha_ultima_itv: formData.vehicle.dateLastITV,
			fecha_caducidad_itv: formData.vehicle.dateExpirationITV,
			conformidades: formData.vehicle.conformidades || null
		};

		const sellData = {
			priceType: formData.sellInfo.priceType,
			coste_transferencia: formData.sellInfo.ownershipChangeAmount,
			id_gestoria: formData.sellInfo.gestoria.value,
			gestoria: formData.sellInfo.gestoria.label,
			// Financing
			financia: formData.sellInfo.financing.wants.value,
			importe_financia: formData.sellInfo.financing.wants.value
				? formData.sellInfo.financing.discount
				: 0,
			entidad_financiera:
				formData.sellInfo.financing.wants.value && formData.sellInfo.financing.financialEntity
					? formData.sellInfo.financing.financialEntity.value
					: null,
			meses_financia:
				formData.sellInfo.financing.wants.value && formData.sellInfo.financing.financialEntity
					? "24 meses"
					: null,

			// Transport
			tipo_venta: formData.sellInfo.transport.sellType.value,
			entrega: formData.sellInfo.transport.delivery.type.value,
			transporte: formData.sellInfo.transport.wants.value,
			importe_transporte:
				formData.sellInfo.transport.wants.value === yesOrNotSelect[0].value
					? formData.sellInfo.transport.delivery.amount
					: 0,

			local_entrega_id:
				formData.sellInfo.transport.wants.value === yesOrNotSelect[0].value &&
				formData.sellInfo.transport.delivery.type.value === transportDeliveryTypeOptions[0].value
					? formData.sellInfo.transport.delivery.dealership.value
					: null,
			local_entrega:
				formData.sellInfo.transport.wants.value === yesOrNotSelect[0].value &&
				formData.sellInfo.transport.delivery.type.value === transportDeliveryTypeOptions[0].value
					? formData.sellInfo.transport.delivery.dealership.label
					: null,
			provincia_local_entrega_id:
				formData.sellInfo.transport.wants.value === yesOrNotSelect[0].value &&
				formData.sellInfo.transport.delivery.type.value === transportDeliveryTypeOptions[0].value
					? formData.sellInfo.transport.delivery.provinceDealership.value
					: null,
			provincia_local_entrega:
				formData.sellInfo.transport.wants.value === yesOrNotSelect[0].value &&
				formData.sellInfo.transport.delivery.type.value === transportDeliveryTypeOptions[0].value
					? formData.sellInfo.transport.delivery.provinceDealership.label
					: null,
			entrega_cambio: formData.sellInfo.transport?.delivery?.useSameAddress ?? true,
			// Insurance
			seguro_auto: formData.sellInfo.insurance.wants.value
		};

		if (formData.sellInfo.financing.wants.value) {
			sellData.TIN = formData.sellInfo.financing.TIN;
			sellData.term = formData.sellInfo.financing.term.value;
			sellData.quota = formData.sellInfo.financing.quota;
		}

		// Direccion alternativa
		if (!sellData.entrega_cambio) {
			sellData.provincia_entrega =
				formData.sellInfo.transport.delivery.alternativeAddress.province.label;
			sellData.poblacion_entrega = formData.sellInfo.transport.delivery.alternativeAddress.town;
			sellData.direccion_entrega = formData.sellInfo.transport.delivery.alternativeAddress.address;
			sellData.CP_entrega = formData.sellInfo.transport.delivery.alternativeAddress.CP;
		}

		if (formData.sellInfo.insurance.wants.value) {
			sellData.aseguradora_auto = formData.sellInfo.insurance.company.value;
			sellData.financia_seguro = formData.sellInfo.insurance.financing.value;
			sellData.importe_seguro = formData.sellInfo.insurance.amount;
		}

		const agentDiscountData = {
			total: formData.agentDiscount.total,
			transferCost: formData.agentDiscount.transferCost.selected
				? formData.agentDiscount.transferCost.amount
				: 0,
			transport: formData.agentDiscount.transport.selected
				? formData.agentDiscount.transport.amount
				: 0,
			warranty: formData.agentDiscount.warranty.selected
				? formData.agentDiscount.warranty.amount
				: 0
		};

		const payAmountData = {
			pago_financiado: formData.payAmounts.financingAmount,
			pago_tpv_tienda: formData.payAmounts.dealershipTPVAmount,
			pago_tpv_pasarela: formData.payAmounts.virtualTPVAmount,
			pago_efectivo: formData.payAmounts.cashAmount,
			pago_transferencia: formData.payAmounts.transferenceAmount,
			pago_coche_forma_pago: formData.payAmounts.carPayAmount,
			total_pagos:
				formData.payAmounts.financingAmount +
				formData.payAmounts.dealershipTPVAmount +
				formData.payAmounts.virtualTPVAmount +
				formData.payAmounts.cashAmount +
				formData.payAmounts.transferenceAmount +
				formData.payAmounts.carPayAmount
		};

		const bookingData = {
			price: formData.booking.price,
			concept: formData.booking.concept,
			observations: formData.booking.observations,
			paymentType: formData.booking.payOptions.value
		};

		const datos = {
			tipo: data.lead.carroceria,
			marca: data.lead.nombre_marca_v,
			modelo: data.lead.nombre_modelo_v,
			booking: bookingData,
			...clientData,
			...vehicleData,
			...sellData,
			agentDiscount: agentDiscountData,
			...payAmountData,
			observaciones: formData.observations || null
		};

		const warrantyData = {
			typeId: formData.sellInfo.warranty.value,
			price: formData.sellInfo.warrantyAmount,
			iban_extra: formData.sellInfo.warrantyIBAN || null
		};

		const payload = {
			agente: employeeId,
			vehiculo: data.reservation.id_vehiculo,
			lead: data.reservation.id_lead,
			cliente: data.reservation.id_cliente,
			datos: datos,
			warranty: warrantyData
		};

		try {
			const response = await instance.post(API.CREATE_CONTRACT_VENTA, payload);
			onCreate(response.data.insertId);
			successToast("Contrato creado correctamente");
		} catch (err) {
			errorToast("Error al crear contrato");
		}
	};

	return (
		<SellContract
			mode="create"
			defaultValues={loadDefaultValues}
			onSubmit={createContract}
			gestoriasOptions={gestoriasOptions}
			provincesOptions={provincesOptions}
			dealershipsOptions={dealershipsOptions}
			warrantyOptions={warrantyOptions}
			flexicarVehicleId={data.lead.vehicleIdFlexicar}
			booking={data.reservation.datos.booking}
		/>
	);
};

export default CreateSellContract;
