import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import styles from "./index.module.scss";
import { AiOutlineCheckCircle, AiOutlineInfoCircle } from "react-icons/ai";
import moment from "moment";
import { getVerification, updateVerification } from "@services/Preparation/preparation.service";
import { errorToast, successToast } from "@helpers/toastFunctions";
import { useParams } from "react-router-dom";
import RouteLeavingGuard from "@components/RouteLeavingGuard/RouteLeavingGuard";
import WarningDialog from "@components/WarningDialog";
import PaneHeader from "@components/PaneHeader";
import Loading from "@views/Loading/Loading";

const Verification = () => {
	const { id: vehicleId } = useParams();

	const [isLoading, setIsLoading] = useState(true);

	const [checkboxOptions, setCheckboxOptions] = useState([]);
	const [status, setStatus] = useState(false);
	const [validationInfo, setValidationInfo] = useState({});
	const [showVerificationModal, setShowVerificationModal] = useState(false);
	const [isVerifiable, setIsVerifiable] = useState(false);

	const loadVerificationChecks = async () => {
		try {
			const apiResponse = await getVerification(vehicleId);
			setCheckboxOptions(apiResponse.checklist);
			setStatus(apiResponse.status);
			setValidationInfo(apiResponse.validation);
			if (apiResponse.checklist.some((item) => item.verified === true)) {
				setIsVerifiable(true);
			}

			return apiResponse.checklist;
		} catch (err) {
			errorToast("Error al cargar la verificación de vehículo");
		} finally {
			setIsLoading(false);
		}
	};

	const {
		register,
		handleSubmit,
		getValues,
		formState: { isDirty },
		reset
	} = useForm({ defaultValues: loadVerificationChecks });

	useEffect(() => {
		reset({
			...getValues()
		});
		const hasCheckedItems = Object.values(getValues()).some((value) => value);
		setIsVerifiable(hasCheckedItems);
	}, [checkboxOptions, getValues, reset]);

	const saveVerification = async (data, verify) => {
		const selectedCheckboxes = Object.entries(data)
			.filter(([key, value]) => typeof value === "boolean" && value === true)
			.map(([key, value]) => {
				const option = checkboxOptions
					.map((x) => x.checks)
					.flat()
					.find((option) => option.name === key);
				return { id: option.id, name: key, verified: true };
			});

		const checklistIds = selectedCheckboxes.map((option) => option.id);

		try {
			await updateVerification({ vehicleId, checklistIds, verify });
			await loadVerificationChecks();
			successToast("Datos guardados correctamente");
		} catch (err) {
			errorToast("Error al actualizar la verificación de vehículo");
		}
	};

	const saveDraft = async (data) => {
		await saveVerification(data, false);
	};

	const finishVerification = async (data) => {
		await saveVerification(data, true);
		setShowVerificationModal(!showVerificationModal);
	};

	const buttons = (
		<>
			<button
				disabled={!isDirty || status}
				className="btn-flexicar-inverse mr-3"
				type="button"
				onClick={handleSubmit(saveDraft)}
			>
				Guardar Borrador
			</button>
			<button
				disabled={(!isDirty || status) && !(isVerifiable && !status)}
				className={`${status ? "btn-flexicar-inverse" : "btn-flexicar-orange"} mr-3`}
				type="button"
				onClick={() => setShowVerificationModal(!showVerificationModal)}
			>
				Verificar Preparación
			</button>
		</>
	);

	return (
		<div>
			<RouteLeavingGuard when={isDirty} />

			<PaneHeader
				title={
					<div>
						Verificación Pre-entrega {status ? <AiOutlineCheckCircle /> : <AiOutlineInfoCircle />}
					</div>
				}
				buttons={buttons}
			/>

			{isLoading ? (
				<Loading />
			) : (
				<form>
					<div className="d-flex justify-content-between p-0">
						<p>
							Selecciona los conceptos revisados y listos para la entrega. Una vez verificado no se
							podrá modificar.
						</p>
						{status && (
							<div className={styles.infoContainer}>
								<div className={styles.text}>
									Vehículo verificado por: {validationInfo?.user?.name}
								</div>
								<div className={styles.date}>
									{moment(validationInfo?.createdAt).format("DD/MM/YYYY HH:mm")}
								</div>
							</div>
						)}
					</div>
					{checkboxOptions.map((section) => (
						<div
							className={styles.checkboxContainer}
							key={section.category}
						>
							<span>{section.category}</span>
							<div className={styles.checkboxGrid}>
								{section?.checks.map((option) => (
									<div key={option.id}>
										<input
											type="checkbox"
											name={option.name}
											{...register(option.name)}
											defaultChecked={option.verified}
											disabled={status}
										/>
										<label>{option.name}</label>
									</div>
								))}
							</div>
						</div>
					))}

					{showVerificationModal && (
						<WarningDialog
							open={showVerificationModal}
							titleText="Verificar preparación"
							contentText=" Se va a proceder a verificar que el vehículo está preparado para la entrega al cliente. Esta acción no permite modificaciones. ¿Quieres continuar?"
							cancelButtonText="Cancelar"
							confirmButtonText="Aceptar"
							onCancel={() => setShowVerificationModal(!showVerificationModal)}
							onConfirm={handleSubmit(finishVerification)}
						/>
					)}
				</form>
			)}
		</div>
	);
};

export default Verification;
