import React, { useState, useEffect, useContext } from "react";
import { FiFilter } from "react-icons/fi";

import styles from "./index.module.scss";

import { UserContext } from "@contexts/UserContext";
import Mainlayout from "@layouts/MainLayout";
import VideosTable from "@views/VideoTutorial/components/VideosTable/index";
import CheckVideoModal from "@views/VideoTutorial/components/CheckVideoModal";
import AddVideoModal from "@views/VideoTutorial/components/AddVideoModal";
import DeleteVideoModal from "@views/VideoTutorial/components/DeleteVideoModal";
import UpdateVideoModal from "@views/VideoTutorial/components/UpdateVideoModal";
import SearchFilterInput from "@views/VideoTutorial/components/SearchFilterInput";
import { getVideos, patchVideo } from "@services/youtubeApi.service";
import Select from "@components/Select";
import getRoles from "@services/filters.service";
import GenericModal from "@components/GenericModal";

const VideoTutorial = () => {
	const { rol } = useContext(UserContext);
	const { user } = useContext(UserContext);

	const [videos, setVideos] = useState(null);
	const [videoData, setVideoData] = useState(null);
	const [adminRoles] = useState([1, 25]);
	const [isAdmin, setIsAdmin] = useState();
	const [videoApiData, setVideoApiData] = useState(null);
	const [modalAddVideo, setModalAddVideo] = useState(false);
	const [modalUpdateVideo, setModalUpdateVideo] = useState(false);
	const [modalCheckVideo, setModalCheckVideoHandler] = useState(false);
	const [modalDeleteVideo, setModalDeleteVideo] = useState(false);
	const [searchTerm, setSearchTerm] = useState("");
	const [videosCount, setVideosCount] = useState(null);
	const [roles, setRoles] = useState(null);
	const [videoId, setvideoId] = useState(null);

	const [selectedRoles, setSelectedRoles] = useState([]);

	const [showFilterModal, setShowFilterModal] = useState(false);

	const checkAdmin = () => {
		return rol.some((item) => adminRoles.includes(item));
	};

	useEffect(() => {
		getVideos(`/videos`)
			.then((response) => {
				setVideos(response.data);
			})
			.catch((e) => {
				throw e;
			});
		checkAdmin() ? setIsAdmin(true) : setIsAdmin(false);
		getRoles(`/roles`)
			.then((response) => {
				setRoles(response);
			})
			.catch((e) => {
				throw e;
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const visibilityHandler = (id, posted) => {
		patchVideo(`/videos/${id}`, { posted: !posted })
			.then((res) => {
				getVideos(`/videos`)
					.then((response) => {
						setVideos(response.data);
						setVideosCount(response.count);
					})
					.catch((e) => {
						throw e;
					});
			})
			.catch((e) => {
				throw e;
			});
	};

	const setVideosHandler = (data) => {
		getVideos(`/videos`)
			.then((response) => {
				setVideos(data);
				setVideosCount(response.count);
			})
			.catch((e) => {
				throw e;
			});
	};

	const setModalAddVideoHandler = () => {
		setModalAddVideo(!modalAddVideo);
	};

	const setModalUpdateVideoHandler = () => {
		setModalUpdateVideo(!modalUpdateVideo);
	};

	const setModalDeleteVideoHandler = (id) => {
		setModalDeleteVideo(!modalDeleteVideo);
		setvideoId(id);
	};

	const editSearchTerm = (e) => {
		setSearchTerm(e.target.value);
	};

	const updateVideoHandler = (data) => {
		data.roles.forEach((rol) => {
			rol["value"] = rol["id"];
			rol["label"] = rol["nombre"];
		});
		setVideoData(data);
		setModalUpdateVideoHandler();
	};

	const handleChange = (selectedRole) => {
		const rolesValues = selectedRole.map((currentRole) => parseInt(currentRole.value));
		setSelectedRoles(rolesValues);
	};

	const filters = (
		<div className="m-2">
			<FiFilter
				size={20}
				color="#007bff"
			/>
			Filtros
			<SearchFilterInput
				className="w-25"
				videos={videos}
				editSearchTerm={editSearchTerm}
				searchTerm={searchTerm}
			/>
			{isAdmin && (
				<Select
					isMulti
					className="w-100"
					placeholder="Roles"
					options={roles}
					onChange={handleChange}
				/>
			)}
		</div>
	);

	return (
		<Mainlayout>
			<Mainlayout.Sidebar>{filters}</Mainlayout.Sidebar>
			<Mainlayout.Content contentTitle="AYUDA">
				<main>
					<div className="d-flex mt-2 mb-3 justify-content-between">
						<div className="d-flex">
							{isAdmin && (
								<button
									outline
									block
									className="btn orange-btn"
									onClick={setModalCheckVideoHandler}
								>
									✚ Añadir Video
								</button>
							)}

							<button
								className="ml-2 d-xl-none d-block btn btn-outline-secondary"
								onClick={() => setShowFilterModal(true)}
							>
								<FiFilter size={25} />
							</button>
						</div>

						<div className="align-self-end">
							<h6 className={`text-success ${styles.videoCount}`}>Cantidad total: {videosCount}</h6>
						</div>
					</div>

					<VideosTable
						visibilityHandler={visibilityHandler}
						setModalCheckVideoHandler={setModalCheckVideoHandler}
						updateVideoHandler={updateVideoHandler}
						setModalDeleteVideoHandler={setModalDeleteVideoHandler}
						isAdmin={isAdmin}
						videos={videos}
						videosCount={videosCount}
						setVideosCount={setVideosCount}
						selectedRoles={selectedRoles}
						searchTerm={searchTerm}
					/>

					<UpdateVideoModal
						modalUpdateVideo={modalUpdateVideo}
						modalUpdateVideoHandler={setModalUpdateVideoHandler}
						videoData={videoData}
						rol={rol}
						adminRoles={adminRoles}
						user={user}
						onVideosHandler={setVideosHandler}
					/>
					<AddVideoModal
						modalAddVideo={modalAddVideo}
						setModalAddVideoHandler={setModalAddVideoHandler}
						videoApiData={videoApiData}
						onVideosHandler={setVideosHandler}
						user={user}
						rol={rol}
						adminRoles={adminRoles}
					/>
					<CheckVideoModal
						modalCheckVideo={modalCheckVideo}
						setModalAddVideoHandler={setModalAddVideoHandler}
						setModalCheckVideoHandler={setModalCheckVideoHandler}
						setVideoApiData={setVideoApiData}
					/>
					<DeleteVideoModal
						modalDeleteVideo={modalDeleteVideo}
						modalDeleteVideoHandler={setModalDeleteVideoHandler}
						setVideos={setVideos}
						videoId={videoId}
						rol={rol}
					/>

					<GenericModal
						isOpen={showFilterModal}
						setIsOpen={setShowFilterModal}
						bodyContent={filters}
					/>
				</main>
			</Mainlayout.Content>
		</Mainlayout>
	);
};

export default VideoTutorial;
