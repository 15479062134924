import instance from "@services/base";
import { errorToast } from "@helpers/toastFunctions";

export const googleLogin = async (code) => {
	try {
		const response = await instance.post("/api/auth/google", { code });
		return response;
	} catch (err) {
		throw err;
	}
};

export const getUserData = async () => {
	try {
		const response = await instance.get("/api/auth/me");
		return response;
	} catch (err) {
		errorToast("Error al obtener los datos del usuario");
	}
};
