export const ReservationTypes = Object.freeze({
	WEB: "web",
	CRM: "crm"
});

export const BLOCKED_STOCK_STATUS = {
	RESERVE_VEHICLE_SOLD: 0,
	RESERVE_VEHICLE_WITHDRAW: 4,
	RESERVE_VEHICLE_PENDING: 5
};
