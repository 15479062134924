import { useContext } from "react";
import { useTranslation } from "react-i18next";

import styles from "./index.module.scss";

import { UserContext } from "@contexts/UserContext";
import { useMediaQuery } from "@hooks/useMediaQuery";
import DocumentationType from "@constants/documentationType";
import { environment } from "@config/environment.config";
import instance from "@services/base";
import { API } from "@services/urlConstants";
import { errorToast, successToast, warningToast } from "@helpers/toastFunctions";
import useHttpLoadingState from "@hooks/useHttpLoadingState";

const AdminButton = ({ adminRoles, task, documentId, documentType, changeDocumentState }) => {
	const { rol } = useContext(UserContext);
	const { t } = useTranslation("myDocumentation");

	const [isCreatingTask, handleTaskCreation] = useHttpLoadingState();

	const ADMIN_STATUS_MAP = {
		PAYFOR: {
			status: 1,
			text: t("adminStatus.payForTask"),
			style: styles.red
		},
		PENDING: {
			status: 2,
			text: t("adminStatus.pendingTask"),
			style: styles.yellow
		},
		PAID: {
			status: 3,
			text: t("adminStatus.paidTask"),
			style: styles.green
		},
		CANCEL: {
			status: 4,
			text: t("adminStatus.cancelTask"),
			style: styles.grey
		}
	};
	const isMobile = useMediaQuery("(max-width: 980px)");

	const defaultTask = {
		status: ADMIN_STATUS_MAP.PAYFOR.status
	};

	const activeTask = task || defaultTask;

	const isAdminUser = (roles) => {
		return roles.some((rol) => adminRoles.includes(rol));
	};

	if (documentType !== DocumentationType.COMPRA_DIRECTA || !isAdminUser(rol)) {
		return null;
	}

	const getStatusDetails = (status) => {
		const statusKey = Object.keys(ADMIN_STATUS_MAP).find(
			(key) => ADMIN_STATUS_MAP[key].status === status
		);

		if (statusKey) {
			const { text, style } = ADMIN_STATUS_MAP[statusKey];
			return { text, style };
		}

		return null;
	};

	const statusDetails = getStatusDetails(activeTask.status);

	if (!statusDetails) {
		return null;
	}

	const handleAdminButton = async () => {
		if (activeTask.status === ADMIN_STATUS_MAP.PENDING.status) {
			window.open(`${environment.ADMIN_URL}gestion/${task.id}`, "_blank", "noopener,noreferrer");
			return;
		}
		try {
			const response = await instance.post(API.ADMIN_TASK_CREATE(documentId));
			if (response.status === 200) {
				successToast(t("adminStatus.successCreate"));
				changeDocumentState();
			}
		} catch (error) {
			if (error.response.status === 409) {
				warningToast(t("adminStatus.errorConflict"));
				return;
			}
			if (error.response.status === 404) {
				errorToast(t("adminStatus.errorNotFound"));
				return;
			}
			errorToast(t("adminStatus.errorCreate"));
		}
	};

	const isDisabled =
		activeTask.status === ADMIN_STATUS_MAP.PAID.status ||
		activeTask.status === ADMIN_STATUS_MAP.CANCEL.status;

	return (
		<button
			className={`${statusDetails.style} ${styles.btnFlexicarMini}`}
			onClick={handleTaskCreation(handleAdminButton)}
			disabled={isDisabled || isCreatingTask}
			style={{ marginLeft: "auto", maxWidth: !isMobile && "50%" }}
		>
			{statusDetails.text.toUpperCase()}
		</button>
	);
};

export default AdminButton;
