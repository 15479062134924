import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "@styles/components/forms/vehicle-validation-additional-info-form.module.scss";

import Form from "@components/Form/Form";
import VehicleDocUploadForm from "@components/forms/VehicleDocUploadForm";
import VehicleAdditionalInfoForm from "@components/forms/VehicleAdditionalInfoForm";
import RouteLeavingGuard from "@components/RouteLeavingGuard/RouteLeavingGuard";
import {
	mapVehicleValidationAdditionalInfoFromForm,
	mapVehicleValidationAdditionalInfoToForm
} from "@mappers/forms/vehicleValidationAdditionalInfoFormMapper";
import { putAdditionalInfo } from "@services/Vehicle/vehicle.service";
import { vehicleValidationAdditionalInfoFormSchema } from "@schema/forms/vehicleValidationAdditionalInfoSchema";
import { errorToast, successToast } from "@helpers/toastFunctions";
import useHttpLoadingState from "@hooks/useHttpLoadingState";

const VehicleValidationAdditionalInfoForm = ({ type, vehicle, setVehicle, ActionButtons }) => {
	const { t } = useTranslation("forms/vehicleValidationAdditionalInfoForm");
	const formRef = useRef(null);

	const [isDirty, setIsDirty] = useState(false);
	const [isSubmitting, handleSubmitState] = useHttpLoadingState();

	const onSubmit = async (formData) => {
		try {
			const payload = mapVehicleValidationAdditionalInfoFromForm(formData);
			await putAdditionalInfo(vehicle.id, type, payload);
			setVehicle((prev) => ({ ...prev, ...payload, comment: { text: payload.comment } }));
			formRef.current.reset(formData);
			successToast(t("saveOk"));
		} catch (error) {
			errorToast(t("saveError"));
		}
	};

	return (
		<Form
			ref={formRef}
			onSubmit={handleSubmitState(onSubmit)}
			className={styles.vehicle_validation_additional_info_form}
			defaultValues={mapVehicleValidationAdditionalInfoToForm(vehicle)}
			schema={vehicleValidationAdditionalInfoFormSchema}
			onFormStateChange={(formState) => setIsDirty(formState.isDirty)}
			i18nErrorsFile="forms/errors"
		>
			<RouteLeavingGuard when={isDirty} />

			<ActionButtons
				isDirty={isDirty}
				isDoingRequest={isSubmitting}
			/>

			<VehicleDocUploadForm
				type={type}
				vehicle={vehicle}
				setVehicle={setVehicle}
			/>

			<VehicleAdditionalInfoForm />
		</Form>
	);
};

export default VehicleValidationAdditionalInfoForm;
