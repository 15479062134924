import { useContext, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import {
  MdEmail,
  MdLockOpen,
  MdSend,
  MdSettingsPhone,
} from "react-icons/md";
import { RiSave3Fill, RiArrowGoBackFill } from "react-icons/ri";

import Mainlayout from "@layouts/MainLayout";
import styles from "./index.module.scss";
import ModalEditPerson from "@views/EditEmployee/ModalEditPerson";
import {
  getCompanies,
  getEmployeeInfo,
  getEmployeeType,
  getEmployeeLocales,
  getEmployeeBrands,
  getEmployeeCompanies,
  updatePersonCall,
  updateEmployeeCall,
  updateLocalsForEmployee,
  updateCommercialBrandsForEmployee,
  updateCompaniesForEmployee,
  getBrands,
} from "@services/Employees/EditEmployeeService";
import { UserContext } from "@contexts/UserContext";
import { errorToast, successToast } from "@helpers/toastFunctions";

import { useTranslation } from "react-i18next";

import Select from "@components/Select";
import Locale from "@constants/Locale";
import { PHONE_REGEX_ES, PHONE_REGEX_PT } from "@constants/regex"; 

const EditEmployee = () => {

  const { t, i18n, ready} = useTranslation("editEmployee");

  const { id } = useParams();
  const { user: employeeId } = useContext(UserContext);

  const history = useHistory();

  const [employee, setEmployee] = useState({
    id: 0,
    personId: 0,
    typeId: 0,
    email: "",
    resetPassword: false,
    isActive: false,
    tel1: "",
    tel2: "",
  });
  const [person, setPerson] = useState({
    id: 0,
    name: "",
    surname1: "",
    surname2: "",
    dni: "",
    birthDate: "",
  });

  const [localSelectOptions, setLocalSelectOptions] = useState([]);
  const [localesSelected, setLocalesSelected] = useState([]);
  const [employeeTypeSelectOptions, setEmployeeTypeSelectOptions] = useState(
    []
  );
  const [employeeTypeSelected, setEmployeeTypeSelected] = useState({});
  const [brandSelectOptions, setBrandSelectOptions] = useState([]);
  const [brandsSelected, setBrandsSelected] = useState([]);
  const [companySelectOptions, setCompanySelectOptions] = useState([]);
  const [companiesSelected, setCompaniesSelected] = useState([]);

  const [showEditPersonModal, setShowEditPersonModal] = useState(false);

  const toSelectOptions = (values) =>
    values.map((x) => ({
      value: x.id,
      label: x.nombre,
    }));

  useEffect(() => {
		(async () => {
			if (ready)
				try {
					const localsOptions = toSelectOptions(await getEmployeeLocales(employeeId));

					const sortedLocalsOptions = localsOptions
						.filter((local) => local.label)
						.sort((a, b) => a.label.localeCompare(b.label));

					setLocalSelectOptions([
						{ value: "all", label: "Seleccionar Todos" },
						...sortedLocalsOptions
					]);

					// Fetch employee types
					const employeeTypesOptions = toSelectOptions(await getEmployeeType(employeeId));
					setEmployeeTypeSelectOptions(employeeTypesOptions);

					// Fetch marcas comerciales
					const brandsOptions = toSelectOptions(await getBrands());
					setBrandSelectOptions(brandsOptions);

					// Fetch empresas
					const companiesOptions = toSelectOptions(await getCompanies(employeeId));
					setCompanySelectOptions(companiesOptions);

					// Fetch employee
					const info = await getEmployeeInfo(id);

					// Set employee current locales
					setLocalesSelected(toSelectOptions(await getEmployeeLocales(id)));
					// Set employee current type
					setEmployeeTypeSelected(employeeTypesOptions.filter((x) => x.value === info.typeId));
					// Set employee current brands
					setBrandsSelected(toSelectOptions(await getEmployeeBrands(id)));
					// Set employee current companies
					setCompaniesSelected(toSelectOptions(await getEmployeeCompanies(id)));

					setEmployee({
						id,
						personId: info.personId,
						typeId: info.typeId,
						email: info.email,
						resetPassword: info.resetPassword,
						isActive: info.isActive,
						tel1: info.telefono1,
						tel2: info.telefono2
					});

					setPerson({
						id: info.personId,
						name: info.nombre,
						surname1: info.apellido1,
						surname2: info.apellido2,
						dni: info.dni,
						birthDate: new Date(info.fecha_nacimiento)
					});
				} catch (e) {
					errorToast(t("errors.errorLoadEmployee"));
				}
		})();
	}, [ready, t, id, employeeId]);

  const updatePerson = async () => {
    // Update person data
    try {
      await updatePersonCall(person.id, {
        ...person,
        birthDate: person.birthDate.toLocaleDateString("es-ES"),
      });
      successToast(t("success.updateEmployee"));
    } catch (e) {
      errorToast(t("errors.errorUpdatePerson"));
    }
  };

  const updateEmployee = async (e) => {
    e.preventDefault();
    // Update employee data
		const phoneRegex = i18n.language === Locale.ES ? PHONE_REGEX_ES : PHONE_REGEX_PT;
		if (
			!phoneRegex.test(employee.tel1) ||
			(employee.tel2 !== "" && !phoneRegex.test(employee.tel2))
		) {
			errorToast(t("errors.noValidPhone"));
			return;
		}

    try {
      await updateEmployeeCall(employee.id, employee);
      await updateLocalsForEmployee(employee.id, {
        localesId: localesSelected.map((x) => x.value),
      });
      await updateCommercialBrandsForEmployee(employee.id, {
        commercialBrandsId: brandsSelected.map((x) => x.value),
      });
      await updateCompaniesForEmployee(employee.id, {
        companiesId: companiesSelected.map((x) => x.value),
      });
      successToast(t("success.updateEmployee"));
    } catch (e) {
      errorToast(t("errors.errorUpdateEmployee"));
    }
  };

  const selectAllLocalsOptions = (values) => {
    setLocalesSelected(values)
    if (values.some((option) => option.value === "all")) {
      setLocalesSelected(localSelectOptions.slice(1));
    }
  };
  
	const allowOnlyNumbers = (event) => {
		// Allow Ctrl + C, Ctrl + V, Ctrl + A, and Ctrl + Z
		if (event.ctrlKey && (event.key === 'c' || event.key === 'v' || event.key === 'a' || event.key === 'z')) {
		  return true;
		}

		const allowedKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"];

		if (!/[0-9]/.test(event.key) && !allowedKeys.includes(event.key)) {
			event.preventDefault();
		}
	};

  return (
    <Mainlayout>
      <Mainlayout.Content full contentTitle={t("editEmployee")}>
        <div
          className={`col-12 col-lg-10 d-flex flex-column align-self-center ${styles.card} `}
        >
          <a
            className="btn orange-btn ml-3 mb-4 maxContent"
            target="_blank"
            rel="noopener noreferrer"
            href={`/main/roles-usuario/${id}`}
          >
            <MdLockOpen size={24} /> {t("seeRoles")}
          </a>

          <Form onSubmit={updateEmployee}>
            <FormGroup className="d-flex flex-row flex-wrap">
              <FormGroup className="col-12 col-lg-6">
                <label>{t("selectLocal")}</label>

                <Select
                  isMulti
                  placeholder="Seleccionar..."
                  options={localSelectOptions}
                  value={localesSelected}
                  onChange={selectAllLocalsOptions}
                />
              </FormGroup>

              <FormGroup className="col-12 col-lg-6">
                <Label>{t("selectEmployeeType")}</Label>
                <Select
                  placeholder={t("select")}
                  options={employeeTypeSelectOptions}
                  value={employeeTypeSelected}
                  onChange={(option) => {
                    setEmployeeTypeSelected(option);
                    setEmployee((prevEmployee) => ({
                      ...prevEmployee,
                      typeId: option.value,
                    }));
                  }}
                />
              </FormGroup>

              <FormGroup className="col-12 col-lg-6">
                <Label>{t("selectCommercialBrands")}</Label>
                <Select
                  isMulti
                  placeholder={t("select")}
                  options={brandSelectOptions}
                  value={brandsSelected}
                  onChange={(options) => setBrandsSelected(options)}
                />
              </FormGroup>

              <FormGroup className="col-12 col-lg-6">
                <Label>{t("selectCompanies")}</Label>
                <Select
                  isMulti
                  placeholder={t("select")}
                  options={companySelectOptions}
                  value={companiesSelected}
                  onChange={(options) => setCompaniesSelected(options)}
                />
              </FormGroup>
              <div className="d-flex flex-wrap mb-2 mt-1">
              </div>
            </FormGroup>

            <blockquote>{t("dataAccess")}</blockquote>

            <FormGroup className="d-flex flex-row flex-wrap">
              <FormGroup className="col-12 col-lg-6">
                <div className={styles.icon_label}>
                  <MdEmail size={24} />
                  <Label>{t("email")}*</Label>
                </div>
                <Input
                  required
                  type="email"
                  placeholder="example@example.com"
                  value={employee.email}
                  onChange={(e) => {
                    e.persist();
                    setEmployee((prevEmployee) => ({
                      ...prevEmployee,
                      email: e.target.value,
                    }));
                  }}
                />
              </FormGroup>

              <FormGroup className="col-12 col-lg-6">
                <div className={styles.icon_label}>
                  <MdSettingsPhone size={24} />
                  <Label>{t("phone1")}*</Label>
                </div>
                <Input
                  required
                  type="tel"
                  value={employee.tel1}
				          onKeyDown={allowOnlyNumbers}
                  onChange={(e) => {
                    e.persist();
                    setEmployee((prevEmployee) => ({
                      ...prevEmployee,
                      tel1: e.target.value,
                    }));
                  }}
                />
              </FormGroup>

              <FormGroup className="col-12 col-lg-6">
                <div className={styles.icon_label}>
                  <MdSettingsPhone size={24} />
                  <Label>{t("phone2")}</Label>
                </div>
                <Input
                  type="tel"
                  value={employee.tel2}
				          onKeyDown={allowOnlyNumbers}
                  onChange={(e) => {
                    e.persist();
                    setEmployee((prevEmployee) => ({
                      ...prevEmployee,
                      tel2: e.target.value,
                    }));
                  }}
                />
              </FormGroup>
              <div className={`col-12 ${styles.switchWrapper}`}>
                <Label for="state-employee-switch">{t("desactivateEmployee")}</Label>
                <label className={styles.switch}>
                  <input
                    type="checkbox"
                    id="state-employee-switch"
                    checked={employee.isActive ? true : false}
                    onChange={() =>
                      setEmployee((prevEmployee) => ({
                        ...prevEmployee,
                        isActive: prevEmployee.isActive === 1 ? 0 : 1,
                      }))
                    }
                  />
                  <span className={styles.slider}></span>
                </label>
                <Label for="state-employee-switch">{t("activateEmployee")}</Label>
              </div>
            </FormGroup>

            <blockquote>{t("personalData")}</blockquote>

            <div className={`${styles.info_panel} mb-4`}>
              <span>
                {t("editInfo")}
              </span>
            </div>

            <FormGroup className="col-12 d-flex flex-row flex-wrap flex-wrap justify-content-around align-items-center mt-4 mt-lg-2">
              <label>{`${person.name} ${person.surname1} ${person.surname2}`}</label>
              <Button
                outline
                className="col-12 col-lg-5 mt-2 orange-btn"
                onClick={() => setShowEditPersonModal(true)}
              >
                {t("editPersonalData")}
              </Button>
            </FormGroup>

            <div className="d-flex justify-content-end pt-3">
              <Button outline className="orange-btn" type="submit">
                <span>{t("update")}</span>
                <MdSend className="ml-2 mb-1" size={24} />
              </Button>
            </div>
          </Form>
        </div>
        <Button
          color="primary"
          className={styles.back_icon}
          onClick={() => history.goBack()}
        >
          <RiArrowGoBackFill size={30} role="button" />
        </Button>
        <Button
          color="success"
          className={styles.save_icon}
          onClick={updateEmployee}
        >
          <RiSave3Fill size={30} role="button" />
        </Button>

        <ModalEditPerson
          show={showEditPersonModal}
          setShow={setShowEditPersonModal}
          person={person}
          setPerson={setPerson}
          updatePerson={updatePerson}
        />
      </Mainlayout.Content>
    </Mainlayout>
  );
};

export default EditEmployee;
