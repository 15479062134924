import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FiPaperclip } from "react-icons/fi";

import styles from "@styles/components/forms/vehicle-doc-upload-form.module.scss";

import Select from "@components/Select";
import UploadNoCallComponent from "@components/UploadNoCallComponent";
import AdditionalInfoTable from "@components/AdditionalInfoTable";
import ModalWarning from "@components/ModalWarning";
import { vehicleValidationDocumentType } from "@constants/selectOptions";
import AdditionalDocumentType from "@constants/AdditionalDocumentType";
import { errorToast, successToast } from "@helpers/toastFunctions";
import { deleteDocument, getDocument, postDocument } from "@services/Vehicle/vehicle.service";

const allowedFormats = [".pdf", ".jpg", ".jpeg", ".doc", ".docx"];
const maxLengthTitle = 35;

const VehicleDocUploadForm = ({ type, vehicle, setVehicle }) => {
	const { t } = useTranslation("forms/vehicleDocUploadForm");

	const [showAddFileModal, setShowAddFileModal] = useState(false);
	const [warningAlert, setWarningAlert] = useState(false);
	const [showSpinner, setShowSpinner] = useState(false);
	const [documentToDelete, setDocumentToDelete] = useState(null);
	const [viewingDocument, setViewingDocument] = useState(false);
	const [docType, setDocType] = useState(null);

	const handleDocumentDelete = (documentId) => {
		setDocumentToDelete(documentId);
		setWarningAlert(true);
	};

	const saveDocument = async (file) => {
		setShowSpinner(true);

		const formData = new FormData();
		formData.append("document", file.object);
		formData.append("fileTitle", file.name);
		formData.append("typeDocumentId", docType.value);

		try {
			const { data } = await postDocument(vehicle.id, type, formData);
			setVehicle((prev) => ({ ...prev, documents: [...prev.documents, data] }));
			successToast(t("saveDocOk"));
		} catch (error) {
			errorToast(t("saveDocError"));
		} finally {
			setShowSpinner(false);
		}
	};

	const handleViewDocument = async (documentId) => {
		setViewingDocument(documentId);

		try {
			const documentUrl = await getDocument(vehicle.id, type, documentId);
			if (documentUrl) window.open(documentUrl, "_blank","noopener,noreferrer");
		} catch (error) {
			errorToast(t("viewDocError"));
		} finally {
			setViewingDocument(null);
		}
	};

	const deleteDocumentInfo = async () => {
		try {
			await deleteDocument(vehicle.id, type, documentToDelete);
			setVehicle((prev) => ({
				...prev,
				documents: prev.documents.filter((doc) => doc.id !== documentToDelete)
			}));

			successToast(t("deleteDocOk"));
			setDocumentToDelete(null);
			setWarningAlert(false);
		} catch (error) {
			errorToast(t("deleteDocError"));
		}
	};

	const cancelDelete = () => {
		setWarningAlert(false);
		setDocumentToDelete(false);
	};

	return (
		<div className={styles.vehicle_doc_upload_form}>
			<h4 className="bold">{t("additionalFields")}</h4>
			<div className={styles.vehicle_doc_upload_form__document__type}>
				<label>
					{t("docType")}
					<Select
						placeholder={t("docType")}
						loadOptions={() => vehicleValidationDocumentType(t)}
						value={docType}
						onChange={(opt) => setDocType(opt)}
					/>
				</label>

				{docType && (
					<div
						className="pointer w-auto"
						onClick={() => setShowAddFileModal(true)}
					>
						<FiPaperclip size={26} />
						<span style={{ color: "#D34600", marginLeft: "10px" }}>{t("uploadData")}</span>
					</div>
				)}
			</div>

			{vehicle.documents.length > 0 && (
				<AdditionalInfoTable
					documents={vehicle.documents}
					AdditionalDocumentType={AdditionalDocumentType}
					viewingDocument={viewingDocument}
					handleViewDocument={handleViewDocument}
					handleDocumentDelete={handleDocumentDelete}
					spinner={showSpinner}
				/>
			)}

			<UploadNoCallComponent
				onFile={saveDocument}
				show={showAddFileModal}
				setShow={setShowAddFileModal}
				allowedFormats={allowedFormats}
				maxLengthTitle={maxLengthTitle}
			/>

			<ModalWarning
				okButton={deleteDocumentInfo}
				cancelButton={cancelDelete}
				open={warningAlert}
			/>
		</div>
	);
};

export default VehicleDocUploadForm;
