import { BOOKING_PAYMENT_METHODS } from "./bookings";

export const yesOrNotSelect = [
	{ label: "Sí", value: true },
	{ label: "No", value: false }
];

export const insouranceOptions = [{ label: "Mutua", value: "Mutua" }];

export const transportSellTypeOptions = [
	{ label: "Presencial", value: "Presencial" },
	{ label: "Online", value: "Online" }
];

export const transportDeliveryTypeOptions = [
	{ label: "Tienda", value: "Tienda" },
	{ label: "Domicilio", value: "Domicilio" }
];

export const ivaTypeOptions = [
	{ label: "No deducible", value: true },
	{ label: "Deducible", value: false }
];

export const termOptions = [
	{ label: "12 meses", value: 12 },
	{ label: "24 meses", value: 24 },
	{ label: "36 meses", value: 36 },
	{ label: "48 meses", value: 48 },
	{ label: "60 meses", value: 60 },
	{ label: "72 meses", value: 72 },
	{ label: "84 meses", value: 84 },
	{ label: "96 meses", value: 96 },
	{ label: "108 meses", value: 108 },
	{ label: "120 meses", value: 120 }
];

export const payOptions = [
	{label: "Pasarela", value: BOOKING_PAYMENT_METHODS.PASARELA},
	{label: "Presencial", value: BOOKING_PAYMENT_METHODS.PRESENCIAL},
	{label: "Transferencia", value: BOOKING_PAYMENT_METHODS.TRANSFERENCIA},
];
export const ecoStickerOptions = [
	{ label: "0", value: 1 },
	{ label: "E", value: 2 },
	{ label: "C", value: 3 },
	{ label: "B", value: 4 }
];

export const vehicleValidationWarrantyOptions = [
	{ label: "Sí", value: 3 },
	{ label: "No", value: 0 }
];

export const vehicleValidationOriginOptions = (t) => [
	{ label: t("originOptions.rentWithoutDriver"), value: 1 },
	{ label: t("originOptions.privateIndividual"), value: 2 },
	{ label: t("originOptions.publicService"), value: 3 },
	{ label: t("originOptions.drivingSchool"), value: 4 },
	{ label: t("originOptions.fleet"), value: 5 },
	{ label: t("originOptions.independent"), value: 6 },
	{ label: t("originOptions.company"), value: 7 }
];

export const vehicleValidationITVResultOptions = (t) => [
	{ label: t("itvResultOptions.unfavorable"), value: 3 },
	{ label: t("itvResultOptions.favorableWithMinorDefects"), value: 2 },
	{ label: t("itvResultOptions.favorable"), value: 1 },
	{ label: t("itvResultOptions.negative"), value: 4 }
];

export const vehicleValidationDocumentType = (t) => [
	{ label: t("carfax"), value: 1 },
	{ label: t("manual"), value: 2 },
	{ label: t("technicalSheet"), value: 3 },
	{ label: t("expertiseDocuments"), value: 4 },
	{ label: t("others"), value: 5 }
];

export const vehicleValidationPhotoTypeOptions = (t) => [
	{ label: t("all"), value: null },
	{ label: t("withPhoto"), value: true },
	{ label: t("withoutPhoto"), value: false },
];