import { createContext, useCallback, useEffect, useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";

import { getUserData, googleLogin } from "@services/Auth/AuthService";
import { getCookie, removeCookie, setCookie } from "@helpers/getCookie";

export const UserContext = createContext();

export function UserContextProvider({ children }) {
	const [employee, setEmployee] = useState({});
	const [isLoading, setIsLoading] = useState(true);
	const [isAuthenticated, setIsAuthenticated] = useState(Boolean(getCookie("JWT")));
	const [error, setError] = useState(null);

	const logInSocial = useGoogleLogin({
		onSuccess: async (codeResponse) => {
			try {
				const { data } = await googleLogin(codeResponse.code);

				setCookie("JWT", data.data.jwt, {
					expires: new Date(Date.now() + 24 * 60 * 60 * 1000)
				});

				setIsAuthenticated(true);
			} catch (error) {
				setError(error);
			}
		},
		onError: (error) => {
			setError(error);
			setIsLoading(false);
		},
		flow: "auth-code"
	});

	const logOut = useCallback(() => {
		removeCookie("JWT");
		setIsAuthenticated(false);
		setEmployee({});
	}, []);

	const loadUser = useCallback(async () => {
		try {
			const user = await getUserData();
			setEmployee(user.data);
		} catch (error) {
			setIsAuthenticated(false);
		} finally {
			setIsLoading(false);
		}
	}, []);

	useEffect(() => {
		if (isAuthenticated) loadUser();
		else setIsLoading(false);
	}, [isAuthenticated, loadUser]);

	const value = {
		user: employee.id,
		userType: employee.id_tipo,
		rol: employee.rolesId,
		local: employee.localesId,
		permission: employee.permissionsId,
		name: employee.name,
		phone: employee.telefono1,
		email: employee.email,
		isLoading,
		isAuthenticated,
		logOut,
		logInSocial,
		error
	};

	return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}
