import { useCallback, useEffect, useRef, useState } from "react";
import * as Yup from "yup";

import Form from "@components/Form/Form";
import FormInput from "@components/Form/Input/FormInput";
import GenericModal from "@components/GenericModal";
import useHttpLoadingState from "@hooks/useHttpLoadingState";
import instance from "@services/base";
import { API } from "@services/urlConstants";
import { errorToast, successToast } from "@helpers/toastFunctions";
import UserComment from "@components/UserComment/UserComment";

const ModalReserveLead = ({ isOpen, setIsOpen, leadId, onReserveOtherUserLead }) => {
	const formRef = useRef();

	const [isSubmitting, handleSubmitting] = useHttpLoadingState();
	const [comments, setComments] = useState([]);

	const getReserveLeadComments = useCallback(async () => {
		try {
			const response = await instance.post(API.GET_CONVERSATIONS_LEEDS, { lead_id: leadId });
			const reserveComments = response.data.data.filter((comment) =>
				comment.texto.startsWith("Reserva 72h:")
			);
			setComments(reserveComments);
		} catch (err) {
			errorToast("Error al cargar los comentarios");
		}
	}, [leadId]);
	
	const reserveLead = async (data) => {
		try {
			await instance.post(API.ACQUIRE_CUSTOMER_CATCHER_LEAD(leadId), {
				conversation: `Reserva 72h: ${data.comment}`
			});
			successToast("Lead reservado durante 72 horas");
			onReserveOtherUserLead();
			setIsOpen(false);
		} catch (err) {
			errorToast("Error al reservar el Lead");
		}
	};

	useEffect(() => {
		getReserveLeadComments();
	}, [getReserveLeadComments]);

	return (
		<GenericModal
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			headContent="Motivo de reserva de Lead"
			bodyContent={
				<div>
					<Form
						ref={formRef}
						schema={Yup.object({
							comment: Yup.string().min(1, "Campo obligatorio")
						})}
						onSubmit={handleSubmitting(reserveLead)}
					>
						<FormInput
							name="comment"
							placeholder="Añadir comentario"
						/>
					</Form>

					<div
						className="flex flex-column pt-4 px-3"
						style={{ gap: "1rem" }}
					>
						{comments.map((comment) => (
							<UserComment
								key={`comment-${comment.id}`}
								comment={comment}
							/>
						))}
					</div>
				</div>
			}
			footerContent={
				<div
					className="flex p-0"
					style={{ gap: "1rem" }}
				>
					<button
						className="btn btn-flexicar-inverse"
						onClick={setIsOpen}
						disabled={isSubmitting}
					>
						Cerrar
					</button>
					<button
						className="btn btn-flexicar-orange"
						onClick={() => formRef.current.triggerSubmit()}
						disabled={isSubmitting}
					>
						Guardar
					</button>
				</div>
			}
		/>
	);
};

export default ModalReserveLead;
