import PaneHeader from "@components/PaneHeader";
import FormRadioButtonGroup from "@components/Form/RadioButtonGroup/FormRadioButtonGroup";
import { yesOrNotSelect } from "@constants/selectOptions";

const BookingPaymentMethod = ({ children }) => {
	return (
		<>
			<div>
				<PaneHeader title="Forma de pago" />
			</div>
			<div>¿Coche como forma de pago?</div>

			<FormRadioButtonGroup
				name="carPayment"
				options={yesOrNotSelect}
			/>
			<div>¿Desea financiar?</div>
			<FormRadioButtonGroup
				name="financingWants"
				options={yesOrNotSelect}
			/>
			<div>¿Su banco ya le ofrece financiación?</div>
			<FormRadioButtonGroup
				name="financingBank"
				options={yesOrNotSelect}
			/>
			{children}	
		</>
	);
};

export default BookingPaymentMethod;
