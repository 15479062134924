import { getCookie } from "@helpers/getCookie";
import instance from "@services/BaseService";

export const getVehicles = async (route, offset) => {
	try {
		const response = await instance.get(`/api/v1${route}`, {
			headers: { Authorization: `Bearer ${getCookie("JWT")}` },
			params: {
				/* limit: 20, offset: offset */
			}
		});
		return response.data;
	} catch (e) {
		throw e;
	}
};
export const makes = async (mode, type, offset) => {
	try {
		const response = await instance.get(`/api/v1/crm-stock-api/makes?mode=${mode}`, {
			headers: { Authorization: `Bearer ${getCookie("JWT")}` }
		});
		return response.data;
	} catch (e) {
		throw e;
	}
};
export const models = async (mode, makeId, offset) => {
	try {
		const response = await instance.get(
			`/api/v1/crm-stock-api/models?mode=${mode}&makeId=${makeId}`,
			{
				headers: { Authorization: `Bearer ${getCookie("JWT")}` },
				params: {
					/* limit: 20, offset: offset */
				}
			}
		);
		return response.data;
	} catch (e) {
		throw e;
	}
};
export const years = async (mode, data, offset) => {
	try {
		const response = await instance.get(
			`/api/v1/crm-stock-api/years?mode=${mode}&makeId=${data.makeId}&modelId=${data.modelId}`,
			{
				headers: { Authorization: `Bearer ${getCookie("JWT")}` },
				params: {
					/* limit: 20, offset: offset */
				}
			}
		);
		return response.data;
	} catch (e) {
		throw e;
	}
};
export const fuels = async (mode, data, offset) => {
	const modeParam = mode ? `&mode=${mode}` : "";
	const makeIdParam = data?.makeId ? `&makeId=${data.makeId}` : "";
	const modelIdParam = data?.modelId ? `&modelId=${data.modelId}` : "";
	const yearParam = data?.year ? `&year=${data.year}` : "";

	try {
		const response = await instance.get(
			`/api/v1/crm-stock-api/fuels?${modeParam}${makeIdParam}${modelIdParam}${yearParam}`,
			{
				headers: { Authorization: `Bearer ${getCookie("JWT")}` },
				params: {
					/* limit: 20, offset: offset */
				}
			}
		);
		return response.data;
	} catch (e) {
		throw e;
	}
};
export const transmision = async (mode, data, offset) => {
	try {
		const response = await instance.get(
			`/api/v1/crm-stock-api/transmissions?mode=${mode}&makeId=${data.makeId}&modelId=${data.modelId}&year=${data.year}&fuelId=${data.fuelId}`,
			{
				headers: { Authorization: `Bearer ${getCookie("JWT")}` },
				params: {
					/* limit: 20, offset: offset */
				}
			}
		);
		return response.data;
	} catch (e) {
		throw e;
	}
};
export const body = async (mode, data, offset) => {
	try {
		const response = await instance.get(
			`/api/v1/crm-stock-api/body?mode=${mode}&makeId=${data.makeId}&modelId=${data.modelId}&year=${data.year}&fuelId=${data.fuelId}&transmissionId=${data.transmissionId}`,
			{
				headers: { Authorization: `Bearer ${getCookie("JWT")}` },
				params: {
					/* limit: 20, offset: offset */
				}
			}
		);
		return response.data;
	} catch (e) {
		throw e;
	}
};
export const version = async (mode, data, offset) => {
	try {
		const response = await instance.get(
			`/api/v1/crm-stock-api/versions?mode=${mode}&makeId=${data.makeId}&modelId=${data.modelId}&year=${data.year}&fuelId=${data.fuelId}&transmissionId=${data.transmissionId}&bodyId=${data.bodyId}`,
			{
				headers: { Authorization: `Bearer ${getCookie("JWT")}` },
				params: {
					/* limit: 20, offset: offset */
				}
			}
		);
		return response.data;
	} catch (e) {
		throw e;
	}
};
export const createDraft = async (id) => {
	try {
		const response = await instance.post(
			`/api/v1/crm-stock-api/contracts/${id}/vehicle-draft`,
			id,
			{
				headers: { Authorization: `Bearer ${getCookie("JWT")}` },
				params: {
					/* limit: 20, offset: offset */
				}
			}
		);
		return response.data;
	} catch (e) {
		throw e;
	}
};

export const getDrafts = async (dataCall) => {
	try {
		const plate = dataCall?.plate ? `&plate=${dataCall.plate}` : "";
		const locals = ""
			.concat(dataCall?.locals?.map((x) => `&localIds[]=${x.value}`))
			.split(",")
			.join("");

		const hasImages = dataCall?.hasImages !== null ? `&hasImages=${dataCall.hasImages}` : "";
		const buyAndSell = dataCall?.buyAndSell ? "&buyAndSell=true" : "";

		const makeIds = dataCall?.makeIds
			? dataCall.makeIds.map((x) => `&makeIds[]=${x}`).join("")
			: "";
		const modelIds = dataCall?.modelIds
			? dataCall.modelIds.map((x) => `&modelIds[]=${x}`).join("")
			: "";
		const fuelIds = dataCall?.fuelIds
			? dataCall.fuelIds.map((x) => `&fuelIds[]=${x}`).join("")
			: "";

		const response = await instance.get(
			`/api/v1/crm-stock-api/draft?page=${dataCall.page}&size=${dataCall.size}&order=${dataCall.order}&orderDir=${dataCall.orderDir}${plate}${locals}${makeIds}${modelIds}${fuelIds}${hasImages}${buyAndSell}`,
			{
				headers: { Authorization: `Bearer ${getCookie("JWT")}` },
				params: {
					/* limit: 20, offset: offset */
				}
			}
		);
		return response.data;
	} catch (e) {
		throw e;
	}
};

export const getDraft = async (id, type) => {
	try {
		const response = await instance.get(`/api/v1/crm-stock-api/${type}/${id}`, {
			headers: { Authorization: `Bearer ${getCookie("JWT")}` },
			params: {}
		});
		return response.data;
	} catch (e) {
		throw e;
	}
};
export const putSales = async (id, dataSales, type) => {
	try {
		const response = await instance.put(`/api/v1/crm-stock-api/${type}/${id}/sales`, dataSales, {
			headers: { Authorization: `Bearer ${getCookie("JWT")}` },
			params: {}
		});
		return response.data;
	} catch (e) {
		throw e;
	}
};
export const getVersionData = async (version) => {
	try {
		const response = await instance.get(
			`/api/v1/crm-stock-api/versions/${version.version}?mode=${version.mode}`,
			{
				headers: { Authorization: `Bearer ${getCookie("JWT")}` },
				params: {}
			}
		);
		return response.data;
	} catch (e) {
		throw e;
	}
};
export const getColors = async () => {
	try {
		const response = await instance.get(`/api/v1/crm-stock-api/colors`, {
			headers: { Authorization: `Bearer ${getCookie("JWT")}` },
			params: {}
		});
		return response.data;
	} catch (e) {
		throw e;
	}
};
export const putVehicle = async (id, dataVehicle, type) => {
	try {
		const response = await instance.put(
			`/api/v1/crm-stock-api/${type}/${id}/vehicle`,
			dataVehicle,
			{
				headers: { Authorization: `Bearer ${getCookie("JWT")}` },
				params: {}
			}
		);
		return response.data;
	} catch (e) {
		throw e;
	}
};
export const createVehicle = async (id) => {
	try {
		const response = await instance.post(`/api/v1/crm-stock-api/draft/${id}/publish`, id, {
			headers: { Authorization: `Bearer ${getCookie("JWT")}` },
			params: {
				/* limit: 20, offset: offset */
			}
		});
		return response.data;
	} catch (e) {
		throw e;
	}
};
export const postImage = async (id, formData, type) => {
	try {
		const response = await instance.post(`/api/v1/crm-stock-api/${type}/${id}/images`, formData, {
			headers: { Authorization: `Bearer ${getCookie("JWT")}` },
			params: {
				/* limit: 20, offset: offset */
			}
		});
		return response.data;
	} catch (e) {
		throw e;
	}
};
export const putImages = async (id, order, type) => {
	try {
		const response = await instance.put(
			`/api/v1/crm-stock-api/${type}/${id}/images`,
			{ imageIds: order },
			{
				headers: { Authorization: `Bearer ${getCookie("JWT")}` },
				params: {}
			}
		);
		return response.data;
	} catch (e) {
		throw e;
	}
};
export const putImageCategory = async (vehicle_id, id, labelId, type) => {
	try {
		const response = await instance.put(
			`/api/v1/crm-stock-api/${type}/${vehicle_id}/images/${id}`,
			{ labelId: Number(labelId) },
			{
				headers: { Authorization: `Bearer ${getCookie("JWT")}` },
				params: {}
			}
		);
		return response.data;
	} catch (e) {
		throw e;
	}
};
export const deleteVehicleImage = async (vehicle_id, id, type) => {
	try {
		const response = await instance.delete(
			`/api/v1/crm-stock-api/${type}/${vehicle_id}/images/${id}`,
			{
				headers: { Authorization: `Bearer ${getCookie("JWT")}` },
				params: {}
			}
		);
		return response.data;
	} catch (e) {
		throw e;
	}
};
export const putExtras = async (id, extras, type) => {
	try {
		const response = await instance.put(
			`/api/v1/crm-stock-api/${type}/${id}/extras`,
			{ extraIds: extras },
			{
				headers: { Authorization: `Bearer ${getCookie("JWT")}` },
				params: {}
			}
		);
		return response.data;
	} catch (e) {
		throw e;
	}
};
export const getStock = async (id) => {
	try {
		const response = await instance.get(`/api/v1/crm-stock-api/stock/${id}`, {
			headers: { Authorization: `Bearer ${getCookie("JWT")}` },
			params: {}
		});
		return response.data;
	} catch (e) {
		throw e;
	}
};
export const deleteVehiculo = async (id) => {
	try {
		const response = await instance.delete(`/api/v1/crm-stock-api/draft/${id}`, {
			headers: { Authorization: `Bearer ${getCookie("JWT")}` },
			params: {}
		});
		return response.data;
	} catch (e) {
		throw e;
	}
};
export const putLocal = async ({ id, type, idDealer }) => {
	try {
		const response = await instance.put(
			`/api/v1/crm-stock-api/${type}/${id}/dealer`,
			{ idDealer: idDealer },
			{
				headers: { Authorization: `Bearer ${getCookie("JWT")}` },
				params: {}
			}
		);
		return response.data;
	} catch (e) {
		throw e;
	}
};
export const putStatus = async (id, status, reasonComment) => {
	try {
		const response = await instance.put(
			`/api/v1/crm-stock-api/stock/${id}/status`,
			{ status, reasonComment },
			{
				headers: { Authorization: `Bearer ${getCookie("JWT")}` },
				params: {}
			}
		);
		return response.data;
	} catch (e) {
		throw e;
	}
};
export const putAdditionalInfo = async (id, type, additionalIinfo) => {
	const response = await instance.put(
		`/api/v1/crm-stock-api/${type}/${id}/additional-info`,
		additionalIinfo,
		{
			headers: { Authorization: `Bearer ${getCookie("JWT")}` },
			params: {}
		}
	);
	return response;
};
export const postDocument = async (id, type, formData) => {
	const response = await instance.post(
		`/api/v1/crm-stock-api/${type}/${id}/documentation`,
		formData,
		{
			headers: { Authorization: `Bearer ${getCookie("JWT")}` },
			params: {}
		}
	);
	return response;
};
export const getDocument = async (id, type, documentId) => {
	const response = await instance.get(
		`/api/v1/crm-stock-api/${type}/${id}/document/${documentId}`,
		{
			headers: { Authorization: `Bearer ${getCookie("JWT")}` },
			params: {}
		}
	);
	return response.data;
};
export const deleteDocument = async (id, type, documentId) => {
	const response = await instance.delete(
		`/api/v1/crm-stock-api/${type}/${id}/document/${documentId}`,
		{
			headers: { Authorization: `Bearer ${getCookie("JWT")}` },
			params: {}
		}
	);
	return response;
};
export const postImagesRequest = async (id) => {
	const response = await instance.post(`/api/v1/crm-stock-api/vehicles/${id}/images/external`, id, {
		headers: { Authorization: `Bearer ${getCookie("JWT")}` },
		params: {}
	});
	return response;
};
export const postImages360 = async (id, url) => {
	const response = await instance.post(
		`/api/v1/crm-stock-api/vehicles/${id}/images360`,
		{ url },
		{
			headers: { Authorization: `Bearer ${getCookie("JWT")}` },
			params: {}
		}
	);
	return response;
};
export const deleteVehicleImage360 = async (vehicle_id, id) => {
	const response = await instance.delete(
		`/api/v1/crm-stock-api/vehicles/${vehicle_id}/images360/${id}`,
		{
			headers: { Authorization: `Bearer ${getCookie("JWT")}` },
			params: {}
		}
	);
	return response;
};
