import { successToast, errorToast } from "@helpers/toastFunctions";
import React, { useCallback, useEffect, useState } from "react";
import { FaFilter } from "react-icons/fa";
import { Button, Col, Container, CustomInput, Row } from "reactstrap";
import {
  getDealers,
  patchDealer,
  postDealer,
} from "@services/Dealers/dealers.service";
import AddDealerModal from "@views/Dealers/Components/AddDealerModal";
import AddressModal from "@views/Dealers/Components/AddressModal";
import DealersTable from "@views/Dealers/Components/DealersTable";
import DeleteDealerModal from "@views/Dealers/Components/DeleteDealerModal";
import OptionalDealerModal from "@views/Dealers/Components/OptionalDealerModal";
import SchedulesDealerModal from "@views/Dealers/Components/SchedulesDealerModal";
import SearchFilterInput from "@views/Dealers/Components/searchFilterInput";

import styles from "./index.module.scss";

import { useTranslation } from "react-i18next";

const LIMIT = 20;
const INITAL_DEALER = {
  id: undefined,
  nombre: undefined,
  tpv: undefined,
  local_type: undefined,
  email: undefined,
  telefono: undefined,
  movil: undefined,
  direccion: undefined,
  coordinate_longitude: undefined,
  coordinate_latitude: undefined,
  schedule: undefined,
  slug: undefined,
  administrationPhone: undefined,
  administrationEmail: undefined,
  encargadoLocal: undefined,
  society: undefined
};

const Dealers = () => {
  const { t } = useTranslation("dealers");

  const [dealers, setDealers] = useState(null);
  const [dealersFiltered, setDealersFiltered] = useState(null);
  const [actualPage, setActualPage] = useState(1);
  const [localType, setLocalType] = useState({
    concesionario: false,
    caseta: false,
  });
  const [dealersCount, setDealersCount] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [modalDeleteDealer, setModalDeleteDealer] = useState(false);
  const [modalAddDealer, setModalAddDealer] = useState(false);
  const [modalOptionalDealer, setModalOptionalDealer] = useState(false);
  const [modalSchedules, setModalSchedules] = useState(false);
  const [dealerReady, setDealerReady] = useState(false);
  const [modalAddress, setModalAddress] = useState(false);
  const [dealerId, setDealerId] = useState(null);

  const [newDealer, setNewDealer] = useState(INITAL_DEALER);

  const cancelHandler = useCallback(() => {
    setModalOptionalDealer();
    setNewDealer(INITAL_DEALER);
  }, []);

  const getAllDealers = useCallback(async () => {
		const response = await getDealers(`/dealers`);
		setDealers(response.data);
		setDealersFiltered(response.data);
		setDealersCount(response.count);
	}, []);

  const createDealer = useCallback(
		async (newDealer) => {
			try {
				await postDealer("/dealers", newDealer);
				await getAllDealers();

				successToast(t("success.localAdded"));
				cancelHandler();
			} catch (e) {
				errorToast(t("errors.localAddedError"));
			}
		},
		[getAllDealers, cancelHandler, t]
	);

  const updateDealer = useCallback(
		async (id, dealerData) => {
			try {
				await patchDealer(`/dealers/${id}`, dealerData);
				await getAllDealers();

				successToast(t("success.localUpdated"));
				cancelHandler();
			} catch (e) {
				errorToast(t("errors.localUpdatedError"));
			}
		},
		[getAllDealers, cancelHandler, t]
	);

  const setModalDeleteDealerHandler = (id) => {
    setModalDeleteDealer(!modalDeleteDealer);
    setDealerId(id);
  };

  const setModalAddDealerHandler = () => {
    setModalAddDealer(!modalAddDealer);
  };

  const setModalAddressHandler = () => {
    setModalAddress(!modalAddress);
  };

  const setModalOptionalDealerHandler = () => {
    setModalOptionalDealer(!modalOptionalDealer);
  };

  const editSearchTerm = (e) => {
    setSearchTerm(e.target.value);
  };

  const localTypeHandler = (e) => {
    if (Number(e.target.id) === 1) {
      if (localType.concesionario) {
        setLocalType({ concesionario: false, caseta: false });
        setDealersFiltered(dealers);
      } else {
        setLocalType({ concesionario: true, caseta: false });
        setDealersFiltered(
          dealers.filter((dealer) => {
            return dealer.tipo === 1;
          })
        );
      }
    }

    if (Number(e.target.id) === 2) {
      if (localType.caseta) {
        setLocalType({ concesionario: false, caseta: false });
        setDealersFiltered(dealers);
      } else {
        setLocalType({ concesionario: false, caseta: true });
        setDealersFiltered(
          dealers.filter((dealer) => {
            return dealer.tipo === 2;
          })
        );
      }
    }
  };

  const nextPage = () => {
    if (actualPage < Math.ceil(dealersCount / LIMIT))
      setActualPage(actualPage + 1);
  };

  const previousPage = () => {
    if (actualPage > 1) setActualPage(actualPage - 1);
  };

  useEffect(() => {
    getAllDealers();
  }, [getAllDealers]);

  useEffect(() => {
		if (dealerReady) {
			delete newDealer.encargado;
			newDealer.id
				? updateDealer(newDealer.id, {
						...newDealer,
						id: undefined,
						localtypes: undefined,
						encargado_local: undefined
				  })
				: createDealer(newDealer);

        setDealerReady(false);
		}
	}, [dealerReady, newDealer, createDealer, updateDealer]);

  return (
    <Container fluid>
      <Row className="m-1">
        <Col className={`col-2 mt-3 mb-4 ${styles.filtersContent}`}>
          <Row className="ml-2">
            <FaFilter size={20} color="#007bff" /> {t("filters")}
          </Row>
          <Row className="ml-1">
            <SearchFilterInput
              dealers={dealers}
              editSearchTerm={editSearchTerm}
              searchTerm={searchTerm}
            />
          </Row>
          <Row className="ml-2 justify-content-start">
            <CustomInput
              className="mx-2"
              type="switch"
              id="1"
              name="concesionariosFilter"
              label={t("dealers")}
              onClick={localTypeHandler}
              checked={localType.concesionario}
            />
            <CustomInput
              className="mx-2"
              type="switch"
              id="2"
              name="casetasFilter"
              label={t("booths")}
              onClick={localTypeHandler}
              checked={localType.caseta}
            />
          </Row>
        </Col>
        <Col className="col-10">
          <Row>
            <Col className="col-12">
              <div className={`clearfix mt-3 mb-3 ${styles.container2}`}>
                <span className="tittle ml-4">{t("dealersList")}</span>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-between">
            <Col className="col-3">
              <Button
                outline
                block
                className="mt-2 mb-3 orange-btn"
                onClick={setModalAddDealer}
              >
                ✚ {t("addDealer")}
              </Button>
            </Col>

            <Col className="col-3 mt-3">
              <h6 className="text-success">
                {localType === 2
                  ? `${t("totalBooths")}: ${dealersCount}`
                  : localType === 1
                  ? `${t("totalDealers")}: ${dealersCount}`
                  : `${t("totalQuantity")}: ${dealersCount}`}
              </h6>
            </Col>
          </Row>
          <Row>
          {modalAddDealer && (
							<AddDealerModal
								newDealer={newDealer}
								modalAddDealer={modalAddDealer}
								setModalAddDealer={setModalAddDealerHandler}
								setModalAddress={setModalAddressHandler}
								setNewDealerHandler={setNewDealer}
							></AddDealerModal>
						)}
						{modalAddress && (
							<AddressModal
								modalAddress={modalAddress}
								setModalAddress={setModalAddressHandler}
								setModalAddDealer={setModalAddDealerHandler}
								newDealer={newDealer}
								setNewDealerHandler={setNewDealer}
								setModalSchedules={setModalSchedules}
							></AddressModal>
						)}
						{modalSchedules && (
							<SchedulesDealerModal
								setModalAddress={setModalAddressHandler}
								newDealer={newDealer}
								setNewDealerHandler={setNewDealer}
								modalSchedules={modalSchedules}
								setModalSchedules={setModalSchedules}
								setModalOptionalDealer={setModalOptionalDealerHandler}
								setDealersHandler={setDealers}
								setDealersCount={setDealersCount}
							></SchedulesDealerModal>
						)}
						{modalOptionalDealer && (
							<OptionalDealerModal
								setModalSchedules={setModalSchedules}
								newDealer={newDealer}
								setNewDealerHandler={setNewDealer}
								modalOptionalDealer={modalOptionalDealer}
								setModalOptionalDealer={setModalOptionalDealerHandler}
								setDealersHandler={setDealers}
								setDealersCount={setDealersCount}
								setDealerReady={setDealerReady}
								dealerReady={dealerReady}
								cancelHandler={cancelHandler}
							></OptionalDealerModal>
						)}
						{modalDeleteDealer && (
							<DeleteDealerModal
								modalDeleteDealer={modalDeleteDealer}
								setModalDeleteDealerHandler={setModalDeleteDealerHandler}
								dealerId={dealerId}
								getAllDealers={getAllDealers}
							></DeleteDealerModal>
						)}
          </Row>
          <Row>
            <Col className="col-12">
              <DealersTable
                className="table-wrapper"
                dealers={dealers}
                dealersFiltered={dealersFiltered}
                searchTerm={searchTerm}
                actualPage={actualPage}
                limit={LIMIT}
                dealersCount={dealersCount}
                setModalDeleteDealerHandler={setModalDeleteDealerHandler}
                setModalAddDealer={setModalAddDealerHandler}
                setNewDealer={setNewDealer}
                setDealersCount={setDealersCount}
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <nav className="">
              <ul className="pagination">
                <li className="page-item">
                  <button
                    className="btn page-link"
                    onClick={previousPage}
                    title="Previous"
                  >
                    <span aria-hidden="true">&laquo;</span>
                  </button>
                </li>
                <li className="page-item">
                  <h6 className="page-link">
                    {searchTerm === "" ? actualPage : 1}/
                    {searchTerm === "" ? Math.ceil(dealersCount / LIMIT) : 1}
                  </h6>
                </li>
                <li className="page-item">
                  <button
                    className="btn page-link"
                    onClick={nextPage}
                    title="Next"
                  >
                    <span aria-hidden="true">&raquo;</span>
                  </button>
                </li>
              </ul>
            </nav>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Dealers;
