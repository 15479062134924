import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Decimal } from "decimal.js";

import styles from "@styles/views/SellContract/sell-resume.module.scss";

import PaneHeader from "@components/PaneHeader";
import FormattedNumberInput from "@components/FormattedNumberInput";
import FormFormattedNumberInput from "@components/Form/FormattedNumberInput/FormFormattedNumberInput";

const BookingResume = () => {
	const { watch } = useFormContext();

	const pvp = watch("sellInfo.pvp");

	const ownershipChangeAmount = watch("sellInfo.ownershipChangeAmount");

	const wantsTransport = watch("sellInfo.transport.wants", false)?.value;
	const transportAmount = watch("sellInfo.transport.delivery.amount");

	const warranty = watch("sellInfo.warranty");
	const warrantyAmount = watch("sellInfo.warrantyAmount");

	const wantsInsurance = watch("sellInfo.insurance.wants", false)?.value;
	const insuranceAmount = watch("sellInfo.insurance.amount");
	const wantsInsuranceFinancing = watch("sellInfo.insurance.financing", false)?.value;

	const comercialCourtesy = watch("agentDiscount.total");
	const financingAmount = watch("payAmounts.financingAmount");
	const dealershipTPVAmount = watch("payAmounts.dealershipTPVAmount");
	const virtualTPVAmount = watch("payAmounts.virtualTPVAmount");
	const cashAmount = watch("payAmounts.cashAmount");
	const transferenceAmount = watch("payAmounts.transferenceAmount");
	const carPayAmount = watch("payAmounts.carPayAmount");

	const [totalAmount, setTotalAmount] = useState(0);
	const [payTotalAmount, setPayTotalAmount] = useState(0);

	useEffect(() => {
		const values = [];

		if (pvp) values.push(pvp);
		if (ownershipChangeAmount) values.push(ownershipChangeAmount);
		if (wantsTransport) values.push(transportAmount);
		if (wantsInsurance && wantsInsuranceFinancing) values.push(insuranceAmount);
		if (warranty?.price && warrantyAmount) values.push(warrantyAmount);

		const sumReduce = values.reduce((acc, num) => acc.plus(new Decimal(num)), new Decimal(0));

		setTotalAmount(
			comercialCourtesy > 0 ? sumReduce.toNumber() - comercialCourtesy : sumReduce.toNumber()
		);
	}, [
		pvp,
		ownershipChangeAmount,
		wantsTransport,
		transportAmount,
		wantsInsurance,
		insuranceAmount,
		wantsInsuranceFinancing,
		comercialCourtesy,
		warranty,
		warrantyAmount,
	]);

	useEffect(() => {
		const newPayAmount =
			financingAmount +
			dealershipTPVAmount +
			virtualTPVAmount +
			cashAmount +
			transferenceAmount +
			carPayAmount;
		setPayTotalAmount(newPayAmount);
	}, [
		financingAmount,
		dealershipTPVAmount,
		virtualTPVAmount,
		cashAmount,
		transferenceAmount,
		carPayAmount,
		comercialCourtesy
	]);

	return (
		<div>
			<PaneHeader title="Resumen de la venta" />

			<div className={styles.container}>
				<div>
					<PaneHeader
						title="Productos contratados"
						color="secondary"
					/>

					<table className={styles.sellResume}>
						<thead>
							<tr>
								<th>Producto contratado</th>
								<th>Importe</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>PVP venta</td>
								<td>
									<FormattedNumberInput
										value={pvp}
										displayType="text"
									/>
								</td>
							</tr>
							<tr>
								<td>Cambio de titularidad</td>
								<td>
									<FormattedNumberInput
										value={ownershipChangeAmount}
										displayType="text"
									/>
								</td>
							</tr>

							{wantsTransport && (
								<tr>
									<td>Transporte</td>
									<td>
										<FormattedNumberInput
											value={transportAmount}
											displayType="text"
										/>
									</td>
								</tr>
							)}

							{warranty?.price > 0 && (
								<tr>
									<td>{warranty.label}</td>
									<td>
										<FormattedNumberInput
											value={warrantyAmount}
											displayType="text"
										/>
									</td>
								</tr>
							)}
							{wantsInsurance && (
								<tr>
									<td>Seguro Auto {!wantsInsuranceFinancing && "*"}</td>
									<td>
										<FormattedNumberInput
											value={wantsInsuranceFinancing ? insuranceAmount : 0}
											displayType="text"
										/>
									</td>
								</tr>
							)}
							{comercialCourtesy > 0 && (
								<tr>
									<td>Cortesia Comercial</td>
									<td>
										<FormattedNumberInput
											value={comercialCourtesy}
											displayType="text"
											prefix="-"
											allowNegative={false}
											className={styles.courtesy}
										/>
									</td>
								</tr>
							)}
							<tr>
								<td>Total</td>
								<td>
									<FormattedNumberInput
										value={totalAmount}
										displayType="text"
									/>
								</td>
							</tr>
						</tbody>
					</table>
					{wantsInsurance && !wantsInsuranceFinancing && (
						<p className={styles.mutuaInfo}>
							* El pago del Seguro Auto no financiado se gestiona directamente con la Mutua. No se
							debe incluir este importe en el sumatorio de formas de pago.{" "}
						</p>
					)}
				</div>
				<div>
					<PaneHeader
						title="Formas de pago"
						color="secondary"
					/>

					<div className={styles.payMethods}>
						<div className={styles.payAmounts}>
							<FormFormattedNumberInput
								name="payAmounts.financingAmount"
								label="Financiado"
							/>
							<FormFormattedNumberInput
								name="payAmounts.dealershipTPVAmount"
								label="TPV Tienda"
							/>
							<FormFormattedNumberInput
								name="payAmounts.virtualTPVAmount"
								label="TPV Virtual"
							/>
							<FormFormattedNumberInput
								name="payAmounts.cashAmount"
								label="Efectivo"
							/>
							<FormFormattedNumberInput
								name="payAmounts.transferenceAmount"
								label="Transferencia"
							/>
							<FormFormattedNumberInput
								name="payAmounts.carPayAmount"
								label="Coche pago"
							/>
						</div>

						<div className={styles.payTotalContainer}>
							<label>Total</label>
							<FormattedNumberInput
								value={payTotalAmount}
								disabled
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default BookingResume;
