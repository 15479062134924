import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "@styles/components/forms/vehicle-validation-data-form.module.scss";

import Form from "@components/Form/Form";
import VehicleBasicDataForm from "@components/forms/VehicleBasicDataForm";
import VehicleCharacteristicsForm from "@components/forms/VehicleCharacteristicsForm";
import VehicleStateForm from "@components/forms/VehicleStateForm";
import RouteLeavingGuard from "@components/RouteLeavingGuard/RouteLeavingGuard";
import useEffectAfterFirstRender from "@hooks/useEffectAfterFirstRender";
import { getVersionData, putVehicle } from "@services/Vehicle/vehicle.service";
import { errorToast, successToast } from "@helpers/toastFunctions";
import {
	mapVehicleValidationDataToForm,
	mapVehicleValidationDataFromForm
} from "@mappers/forms/vehicleValidationDataFormMapper";
import { vehicleValidationDataFormSchema } from "@schema/forms/vehicleValidationDataFormSchema";
import { VEHICLE_MODE } from "@constants/vehicleValidation";
import useHttpLoadingState from "@hooks/useHttpLoadingState";

const specsFields = [
	"cc",
	"power",
	"emissions",
	"cubinVolume",
	"consumptionMix",
	"consumptionRoad",
	"consumptionUrban",
	"seats",
	"doors",
	"gearCount",
	"ecosticker"
];

const VehicleValidationDataForm = ({
	type,
	vehicle,
	getVehicle,
	version,
	setVersion,
	ActionButtons
}) => {
	const { t } = useTranslation("forms/vehicleValidationDataForm");
	const formRef = useRef(null);

	const [isDirty, setIsDirty] = useState(false);
	const [isSubmitting, handleSubmitState] = useHttpLoadingState();

	const [watchedMode, setWatchedMode] = useState(vehicle.mode);
	const [watchedVersion, setWatchedVersion] = useState(vehicle.version);

	const getVersions = async (version) => {
		try {
			const response = await getVersionData({
				version: version,
				mode: VEHICLE_MODE.JATO
			});
			return response;
		} catch (err) {
			errorToast(err.response?.data?.message || t("getVersionsError"));
		}
	};

	const saveData = async (formData) => {
		const payload = mapVehicleValidationDataFromForm({
			...formData,
			versionId: version.id ?? vehicle.version.id
		});

		try {
			await putVehicle(vehicle.id, payload, type);
			formRef.current.reset(formData);
			await getVehicle();
			successToast(t("saveOk"));
		} catch (err) {
			errorToast(err?.response?.data?.message || t("saveError"));
		}
	};

	useEffect(() => {
		if (formRef.current) {
			const subscription = formRef.current.watch((value, { name }) => {
				if (name === "mode") setWatchedMode(value.mode ? VEHICLE_MODE.JATO : VEHICLE_MODE.CLASSIC);
				if (name === "version") setWatchedVersion(value.version);
			});

			return () => subscription.unsubscribe();
		}
	}, []);

	useEffectAfterFirstRender(() => {
		// Reset characteristics
		if (watchedMode === VEHICLE_MODE.JATO && watchedVersion === null)
			specsFields.forEach((field) => formRef.current.setValue(`specs.${field}`, null));
	}, [watchedMode, watchedVersion]);

	useEffectAfterFirstRender(async () => {
		// Update with the new characteristics on version change
		if (watchedVersion?.value) {
			const versionData = await getVersions(watchedVersion.value);
			setVersion(versionData);

			specsFields.forEach((field) =>
				formRef.current.setValue(`specs.${field}`, versionData.specs[field])
			);
		}
	}, [watchedMode, watchedVersion]);

	return (
		<Form
			ref={formRef}
			onSubmit={handleSubmitState(saveData)}
			className={styles.vehicle_validation_data_form}
			defaultValues={mapVehicleValidationDataToForm(vehicle)}
			schema={vehicleValidationDataFormSchema}
			onFormStateChange={(formState) => setIsDirty(formState.isDirty)}
			i18nErrorsFile="forms/errors"
		>
			<RouteLeavingGuard when={isDirty} />

			<ActionButtons
				isDirty={isDirty}
				isDoingRequest={isSubmitting}
			/>

			<div className={styles.vehicle_validation_data_form__wrapper}>
				{/* Datos básicos del vehículo */}
				<VehicleBasicDataForm
					vehicle={vehicle}
					type={type}
				/>

				{/* Caracterí1icas */}
				<VehicleCharacteristicsForm vehicle={vehicle} />

				{/* Estado del vehículo */}
				<VehicleStateForm />
			</div>
		</Form>
	);
};

export default VehicleValidationDataForm;
