import { MdAccountCircle } from "react-icons/md";

import { formatSpanishFullDate } from "@helpers/formatDate";

const UserComment = ({ comment }) => {
	return (
		<div
			className="flex align-items-center p-0"
			style={{ gap: "1rem" }}
		>
			<MdAccountCircle size={40} />
			<div className="flex flex-column p-0">
				<div>
					<span>{comment.nombre}</span>
					<span className="ml-3">{formatSpanishFullDate(comment.fecha)}</span>
				</div>
				<span>
					<b>{comment.texto}</b>
				</span>
			</div>
		</div>
	);
};

export default UserComment;
