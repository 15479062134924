export const contractTypeTranslator = (t, contractType) => {
    switch (contractType) {
        case 1:
            return t("sellContract");
        case 2:
            return t("booking");
        case 3:
            return t("directPurchase");
        case 4:
            return t("salesManagement");
        default:
            return "";
    };
};