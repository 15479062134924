import * as Yup from "yup";

const bookingContractBookingSchema = Yup.object({
	concept: Yup.string().required("Campo obligatorio").max(50, "Máximo 50 caracteres"),
	price: Yup.number()
	    .min(0, "El precio debe ser igual o mayor que 0")
		.integer("No se admiten decimales")
		.required("Campo obligatorio"),
	observations: Yup.string().nullable().max(300, "Máximo 300 caracteres")
});


export { bookingContractBookingSchema };
