import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback
} from "react";
import { connect } from "react-redux";
import { Button, Spinner } from "reactstrap";
import styles from "./index.module.scss";
import { post } from "@services/axiosCalls";
import { API } from "@services/urlConstants";
import instance from "@services/base";
import { dispatcher } from "@redux/actions/dispatchers";
import Pagination from "@views/LeadsVentaList/components/Pagination";
import PaginationFooter from "@components/PaginationFooter";
import moment from "moment";
import { errorToast, successToast } from "@helpers/toastFunctions";
import DatePicker from "@components/DatePicker";
import { formatDateWithoutHour } from "@helpers/formatDate";
import { useDebouncedEffect } from "@helpers/useDebouncedEffect";
import {
  HiDocumentText,
  HiStar,
  HiOutlineStar,
  HiFlag,
  HiUser,
} from "react-icons/hi";
import Loading from "@views/Loading/Loading";
import { FiFilter } from "react-icons/fi";
import { UserContext } from "@contexts/UserContext";
import ModalChangeState from "@views/ModalChangeState/ModalChangeState";
import ModalChangeAgent from "@views/ModalChangeAgent/ModalChangeAgent";
import RolType, { IS_SUPER_ADMIN } from "@constants/roles";
import Mainlayout from "@layouts/MainLayout";
import ModalFilters from "@components/ModalFilters";
import Locale from "@constants/Locale";
import { useTranslation } from "react-i18next";
import Select from "@components/Select";
import { getFilterContact, getFilterDealershipsLocals, getFilterGVOBrands, getFilterGVOModels, getFilterOrigin, getFilterSellAgent, getFilterSellAgent2, getFilterSellLeadState } from "@services/Filtros/filtros";
import LEAD_STATUS from "@constants/LeadStatus";

import MultiSelectChipsList from "@components/MultiSelectChipsList";
import useAbortController from "@hooks/useAbortController";

const LeadsVentaList = ({ storedFilters, dispatchLeadsFilters }) => {
  document.title = "Flexicar | Leads de Venta";
  const { user, userType, rol, local } = useContext(UserContext);
  const urlParams = new URLSearchParams(window.location.search);
  const section = urlParams.get("section");
  const { i18n } = useTranslation();
  const { t } = useTranslation('leadsVenta');
  const { createAbortSignal } = useAbortController();

  // const [reduxChecked, setReduxChecked] = useState(false);

  const [leads, setLeads] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(25);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const [changeType, setChangeType] = useState(null);
  const [changeData, setChangeData] = useState([]);
  const [changeState, setChangeState] = useState([]);
  const [changeManager, setChangeManager] = useState([]);
  const [changeAll, setChangeAll] = useState(true);

  const [filterLocales, setFilterLocales] = useState([]);
  const [filterEstados, setFilterEstados] = useState([]);
  const [filterFormaContacto, setFilterFormaContacto] = useState([]);
  const [filterProcedencia, setFilterProcedencia] = useState([]);
  const [filterFinancia, setFilterFinancia] = useState([]);
  const [filterMarca, setFilterMarca] = useState([]);
  const [filterModelo, setFilterModelo] = useState([]);
  const [filterFreeSearch, setfilterFreeSearch] = useState(null);
  const [freeSearchValue, setFreeSearchValue] = useState("");
  const [filterGestor, setFilterGestor] = useState([]);
  const [filterDateStart, setFilterDateStart] = useState(
    moment().subtract(4, "months").valueOf()
  );
  const [filterDateEnd, setFilterDateEnd] = useState();

  const [filterList, setFilterList] = useState([]);

  const [hasFilterChanged, setHasFilterChanged] = useState(false);
  const [initialFetch, setInitialFetch] = useState(false);
  const [hasLimitPageChanged, setHasLimitPageChanged] = useState(false);
  const [hasPermissionToRequestLeads, setHasPermissionToRequestLeads] = useState(true);

  const portugueseAccessProtection = i18n.language === Locale.ES;



  const defaultLeadStatusFilter = [
    { label: "Formulario Nuevo", value: LEAD_STATUS.formulario_nuevo },
    { label: "Formulario Nuevo (IA)", value: LEAD_STATUS.formulario_nuevo_IA }
  ] 

  const filtersMemo = useMemo(() => {
    return {
      filterLocales,
      filterEstados,
      filterFormaContacto,
      filterProcedencia,
      filterMarca,
      filterFinancia,
      filterModelo,
      filterGestor,
      filterDateStart: filterDateStart || "",
      filterDateEnd: filterDateEnd
        ? moment(filterDateEnd)
          .set({ hour: 23, minute: 59, second: 59 })
          .valueOf()
        : "",
    };
  }, [
    filterDateEnd,
    filterDateStart,
    filterEstados,
    filterFormaContacto,
    filterGestor,
    filterLocales,
    filterMarca,
    filterFinancia,
    filterModelo,
    filterProcedencia,
  ]);

  const fetchPayloadMemo = useMemo(() => {
    return {
      filterFreeSearch,
      limit,
      local,
      page,
      section,
      user,
    };
  }, [filterFreeSearch, limit, local, page, section, user]);

  const [leadSelectedModal, setLeadSelectedModal] = useState(null);
  const [clientSelectedModal, setClientSelectedModal] = useState(null);
  const [modalState, setModalState] = useState(false);
  const [modalAgent, setModalAgent] = useState(false);
  const [modalFilter, setModalFilter] = useState(false);

  const hasAuthorization = (rol.includes(RolType.GENERAL_ADMIN) ||
  rol.includes(RolType.SALES_CALL_CENTER_SUPERVISOR));

  const requestLeadsAccess = IS_SUPER_ADMIN(userType) ||
  rol.includes(RolType.SALES_CALL_CENTER_AGENT);
  
  const launchModalChangeState = (leadSelected, clientSelected) => {
    setLeadSelectedModal(leadSelected);
    setClientSelectedModal(clientSelected);
    setModalState(!modalState);
  };

  const launchModalChangeAgent = (leadSelected, clientSelected) => {
    setLeadSelectedModal(leadSelected);
    setClientSelectedModal(clientSelected);
    setModalAgent(!modalAgent);
  };

  const launchModalFilter = () => setModalFilter(!modalFilter);

  const loadLeads = useCallback(
		async ({ filtersMemo, fetchPayloadMemo }) => {
			const { filterFreeSearch, limit, local, page, section, user } = fetchPayloadMemo;

			try {
				setLoading(true);
        const list = await post(
					API.GET_SELL_LEADS,
					{
						page,
						limit,
						filters: {
							...filtersMemo,
							filterDateStart: filtersMemo.filterDateStart
								? moment(filtersMemo.filterDateStart)
								: "",
							filterDateEnd: filtersMemo.filterDateEnd ? moment(filtersMemo.filterDateEnd) : ""
						},
						filterFreeSearch: filterFreeSearch ? filterFreeSearch : "",
						user,
						section,
						local
					},
					{ signal: createAbortSignal("sell-leads") }
				);

				setLeads(list);
			} catch (error) {
        if (error.message === "canceled") return;
        errorToast(<span>{t("errors.errorLoadingLeads")}</span>)
			} finally {
				setLoading(false);
			}
		},
		[createAbortSignal, t]
	);

  const loadLeadsCount = useCallback(
		async ({ filtersMemo, fetchPayloadMemo }) => {
			setLoading(true);
			const { filterFreeSearch, limit, local, page, section, user } = fetchPayloadMemo;
			try {
				const list_count = await post(
					API.GET_SELL_LEADS_COUNT,
					{
						page,
						limit,
						filters: {
							...filtersMemo,
							filterDateStart: filtersMemo.filterDateStart
								? moment(filtersMemo.filterDateStart)
								: "",
							filterDateEnd: filtersMemo.filterDateEnd ? moment(filtersMemo.filterDateEnd) : ""
						},
						filterFreeSearch: filterFreeSearch ? filterFreeSearch : "",
						user,
						section,
						local
					},
					{ signal: createAbortSignal("sell-leads-count") }
				);
				setCount(list_count.count);
			} catch (error) {
        if (error.message === "canceled") return;
				errorToast(<span>{t("errors.errorLoadingLeadsCounter")}</span>);
			} finally {
        setLoading(false)
      }
		},
		// eslint-disable-next-line
		[]
	);
  const getLocalNameByID = useCallback(async (id) => {
    const local_name = await post(API.GET_LOCAL_NAME_BY_ID, {
      id,
    });
    const response = local_name.name;
    setFilterLocales([{ value: id, label: response[0].nombre }]);
  }, []);

  useEffect(() => {
    const _filterList = [
      filterLocales,
      filterEstados,
      filterFormaContacto,
      filterProcedencia,
      filterMarca,
      filterFinancia,
      filterModelo,
      filterGestor
    ].filter(x => x);

    const filterListFlat = _filterList.flatMap(list => list.map(item => item.label.toString()));

    const filterDateList = [
      filterDateStart,
      filterDateEnd
    ].filter(x => x).map(x => formatDateWithoutHour(x));

    setFilterList([...filterListFlat, ...filterDateList]);
  }, [storedFilters]);


  const changePagination = (actualPage, totalPage) => {
    setLimit(totalPage);
    setPage(actualPage);
    setHasLimitPageChanged(true);
  };

  const changeDestStatus = async (idx, lead, status) => {
    let leadsCopy = JSON.parse(JSON.stringify(leads));
    if (status === null) {
      const response = await post(API.CREATE_DESTACADO, {
        user,
        lead,
      });
      if (response.error) {
        return errorToast(<span>{t("errors.errorCreatingFeatured")}</span>);
      } else {
        leadsCopy[idx].destacado_st = 1;
        setLeads(leadsCopy);
      }
    } else if (status === 1 || status === 0) {
      status = status === 0 ? 1 : 0;
      const response = await post(API.UPDATE_DESTACADO, {
        user,
        lead,
        status,
      });
      if (response.error) {
        return errorToast(<span>{t("errors.errorUpdatingFeatured")}</span>);
      } else {
        leadsCopy[idx].destacado_st = status;
        setLeads(leadsCopy);
      }
    }
  };

  const openChangeSt = (type) => {
    setChangeState([]);
    setChangeManager([]);
    setChangeType(type);
  };

  const launchChangeMultiple = async (
    manager_id,
    manager_name,
    state_id,
    state_name
  ) => {
    if (changeData.length < 1) {
      return errorToast(<span>{t("errors.errorSelectLead")}</span>);
    }
    if (!manager_id && !state_id) {
      return errorToast(<span>{t("errors.errorSelectOption")}</span>);
    }
    if (manager_id && manager_name) {
      const launchChangeManager = await post(API.CHANGE_LEAD_VENTA_MANAGER, {
        manager_id,
        manager_name,
        leads: changeData,
      });
      if (launchChangeManager.error) {
        return errorToast(<span>{t("errors.errorSavingAgent")}</span>);
      } else {
        return window.location.reload();
      }
    } else if (state_id && state_name) {
      const launchChangeStatus = await post(API.CHANGE_LEAD_VENTA_STATUS, {
        state_id,
        state_name,
        leads: changeData,
      });
      if (launchChangeStatus.error) {
        return errorToast(<span>{t("errors.errorSavingState")}</span>);
      } else {
        return window.location.reload();
      }
    }
  };

  const changeMultipleMod = (state, id) => {
    if (state) {
      setChangeData([...changeData, id]);
    } else {
      setChangeData(changeData.filter((value) => value !== id));
    }
  };

  const changeMultipleMod_All = (action) => {
    if (action) {
      let list = [];
      leads.forEach((l) => {
        list.push(l.id);
      });
      setChangeData(list);
    } else {
      setChangeData([]);
    }
    setChangeAll(!changeAll);
  };
  const changeFreeSearch = (freeSearchValue) => {
    if (freeSearchValue?.length > 2) {
      setfilterFreeSearch(freeSearchValue);
      setHasFilterChanged(true);
    }
    if (freeSearchValue === "" && filterFreeSearch !== null) {
      setfilterFreeSearch(freeSearchValue);
      setHasFilterChanged(true);
    }
  };
  useDebouncedEffect(() => changeFreeSearch(freeSearchValue), 700, [
    freeSearchValue,
  ]);

  useEffect(() => {
    if (section === "propios" || section === "mitienda") {
      setFilterEstados(defaultLeadStatusFilter);
    } else {
      setFilterEstados([]);
    }

    if (storedFilters) {
      setFilterLocales(storedFilters.filterLocales);
      setFilterEstados(storedFilters.filterEstados);
      setfilterFreeSearch(storedFilters.filterFreeSearch);
      setFilterFormaContacto(storedFilters.filterFormaContacto);
      setFilterProcedencia(storedFilters.filterProcedencia);
      setFilterMarca(storedFilters.filterMarca);
      setFilterFinancia(storedFilters.filterFinancia);
      setFilterModelo(storedFilters.filterModelo);
      setFilterGestor(storedFilters.filterGestor);
      setFilterDateStart(storedFilters.filterDateStart);
      setFilterDateEnd(storedFilters.filterDateEnd);
      setHasFilterChanged(true);
    } else {
      setInitialFetch(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    section === "propios" || section === "mitienda"
      ? setFilterEstados(defaultLeadStatusFilter)
      : setFilterEstados([]);

    setHasFilterChanged(true);
  }, [section]);

  useEffect(() => {
    if (initialFetch && filterEstados.length > 0) {
      setInitialFetch(false);
      return;
    }
    if (hasFilterChanged || initialFetch || hasLimitPageChanged) {
      loadLeads({ filtersMemo, fetchPayloadMemo });
      loadLeadsCount({ filtersMemo, fetchPayloadMemo });
      dispatchLeadsFilters(filtersMemo);

      setHasFilterChanged(false);
      setInitialFetch(false);
      setHasLimitPageChanged(false);
    }
  }, [
    filterEstados,
    initialFetch,
    dispatchLeadsFilters,
    filtersMemo,
    fetchPayloadMemo,
    hasFilterChanged,
    hasLimitPageChanged,
    loadLeads,
    loadLeadsCount,
  ]);

  useEffect(() => {
    if (local && local.length === 1) {
      getLocalNameByID(local[0]);
    }
  }, [getLocalNameByID, local]);


  const checkAssignPermission = useCallback(async () => {
		if (requestLeadsAccess) {
			try {
				const response = await instance.get(API.SELL_LEADS_ASSIGNABILITY);
				setHasPermissionToRequestLeads(response.data.canRequestLeads);
			} catch (err) {
				errorToast(err?.response?.data?.message || "Error al revisar la asignabilidad de leads");
			}
		}
	}, [requestLeadsAccess]);

  useEffect(() => {
    checkAssignPermission();
  }, [checkAssignPermission]);

  const handleRequestLeadsClick = async () => {
    setHasPermissionToRequestLeads(false);
    try {
      const response = await instance.put(API.SELL_LEADS_ASSIGN);
      if (response.status === 200) {
        if (response.data.length === 0) {
          errorToast(<span>{t("errors.errorRequestNoLeads")}</span>);
        } else {
          successToast(<span>{t("requestLeadsSuccess")}</span>);
          loadLeads({ filtersMemo, fetchPayloadMemo });
          loadLeadsCount({ filtersMemo, fetchPayloadMemo });
          dispatchLeadsFilters(filtersMemo);
        }
      }
    } catch (e) {
      errorToast(<span>{t("errors.errorLeadsRequest")}</span>);
    } finally {
      checkAssignPermission();
    }
  };

  const handleDeletedDropdownSelected = (element) => {

    const updateFilter = (filter, setFilterList) => {
      if (!filter) return;
      const index = filter?.findIndex((item) => item.label.toString() === element);
      if (index && index !== -1) {
        const newFilter = [...filter];
        newFilter.splice(index, 1);
        setFilterList(newFilter);
        setHasFilterChanged(true);
      }
    };
  
    updateFilter(filterLocales, setFilterLocales);
    updateFilter(filterEstados, setFilterEstados);
    updateFilter(filterFormaContacto, setFilterFormaContacto);
    updateFilter(filterProcedencia, setFilterProcedencia);
    updateFilter(filterMarca, setFilterMarca);
    updateFilter(filterFinancia, setFilterFinancia);
    updateFilter(filterModelo, setFilterModelo);
    updateFilter(filterGestor, setFilterGestor);
  
    if (formatDateWithoutHour(filterDateStart) === element) {
      setFilterDateStart();
      setHasFilterChanged(true);
    }
    if (formatDateWithoutHour(filterDateEnd) === element) {
      setFilterDateEnd();
      setHasFilterChanged(true);
    }
  };

  const filters = (
		<>
			<Button
				outline
				color="danger"
				block
				onClick={() => {
					setFilterLocales([]);
					setFilterEstados(
						section === "poca_tasacion"
							? [
									{ label: "Poca Tasacion", value: 5 },
									{ label: "Coche interesante no comprado", value: 53 }
							  ]
							: []
					);
					setFilterFormaContacto([]);
					setFilterProcedencia([]);
					setFilterMarca([]);
					setFilterFinancia([]);
					setFilterModelo([]);
					setfilterFreeSearch("");
					setFilterGestor([]);
					setFilterDateStart(
						new Date(new Date().setHours(0, 0, 0)).setMonth(new Date().getMonth() - 4)
					);
					setFilterDateEnd();

					setHasFilterChanged(true);
				}}
			>
				{t("deleteFilters")}
			</Button>
			<br />
			<Select
				isMulti
				placeholder={t("actualState")}
				loadOptions={getFilterSellLeadState}
				value={filterEstados}
				onChange={(value) => {
					setFilterEstados(value);
					setHasFilterChanged(true);
				}}
			/>
			<br />
			<Select
				isMulti
				placeholder={t("contactForm")}
				loadOptions={getFilterContact}
				value={filterFormaContacto}
				onChange={(value) => {
					setFilterFormaContacto(value);
					setHasFilterChanged(true);
				}}
			/>
			<br />
			<Select
				isMulti
				placeholder={t("origin")}
				loadOptions={getFilterOrigin}
				value={filterProcedencia}
				onChange={(value) => {
					setFilterProcedencia(value);
					setHasFilterChanged(true);
				}}
			/>
			<br />
			<Select
				isMulti
				placeholder={t("locals")}
				loadOptions={getFilterDealershipsLocals}
				value={filterLocales}
				onChange={(value) => {
					setFilterLocales(value);
					setHasFilterChanged(true);
				}}
			/>
			<br />
			<div
				className="flex flex-row flex-xl-column"
				style={{ gap: "10px" }}
			>
				<DatePicker
					date={filterDateStart}
					setDate={(e) => {
						setFilterDateStart(e);
						setHasFilterChanged(true);
					}}
					titleHolder="Leads creados desde"
				/>
				<DatePicker
					date={filterDateEnd}
					setDate={(e) => {
						setFilterDateEnd(e);
						setHasFilterChanged(true);
					}}
					titleHolder={t("leadsCreatedUntil")}
				/>
			</div>
			<br />
      { portugueseAccessProtection && (
			<Select
				placeholder={t("financing")}
				options={[
					{ label: t("noData"), value: null},
					{ label: t("yes"), value: 1 },
					{ label: t("no"), value: 0 }
				]}
				value={filterFinancia}
				onChange={(value) => {
						setFilterFinancia([value]);
						setHasFilterChanged(true);
					}
				}
			/>
      )}
			<br />
			<Select
				isMulti
				placeholder={t("brand")}
				loadOptions={getFilterGVOBrands}
				value={filterMarca}
				onChange={(value) => {
					setFilterMarca(value);
					setHasFilterChanged(true);
				}}
			/>
			<br />
			<Select
				isMulti
				reloadOptions
				placeholder={t("model")}
				loadOptions={() => getFilterGVOModels(filterMarca.map((x) => x.value))}
				value={filterModelo}
				onChange={(value) => {
					setFilterModelo(value);
					setHasFilterChanged(true);
				}}
				isDisabled={filterMarca.length === 0}
			/>
			<br />{" "}
			<Select
				isMulti
				placeholder={t("agent")}
				loadOptions={() => getFilterSellAgent([userType, user, userType])}
				value={filterGestor}
				onChange={(value) => {
					setFilterGestor(value);
					setHasFilterChanged(true);
				}}
			/>
			<input
				type="text"
				onChange={(e) => setFreeSearchValue(e.target.value)}
				className="form-control ds-input mt-4 mb-3"
				value={freeSearchValue}
				placeholder={t("search")}
			/>
		</>
	);

  return (
    <>
      <Mainlayout>
        <Mainlayout.Sidebar>{filters}</Mainlayout.Sidebar>
        <Mainlayout.Content
          contentTitle={
            section === "todos"
              ? t("leadsVenta")
              : section === "destacados"
                ? "LEADS VENTA DESTACADOS"
                : section === "mitienda"
                  ? "LEADS VENTA DE MI TIENDA"
                  : section === "propios"
                    ? "LEADS VENTA PROPIOS"
                    : "LEADS VENTA"
          }
        >
          <div className="d-flex flex-wrap mb-2 mt-1">
            <MultiSelectChipsList
              dropdownSelected={filterList}
              deleteDropdownSelected={handleDeletedDropdownSelected}
            />
          </div>
          <div className="d-flex flex-column flex-lg-row w-100 justify-content-between align-items-center">

            <div className="d-flex flex-column flex-md-row w-100">
              <div className="d-flex flex-row align-items-center justify-content-end">
                {userType <= 2 && (
                  <div className="d-flex flex-column flex-md-row">
                    <Button
                      outline
                      className={`ml-0 mt-2 mb-3 mt-md-0 mb-md-0 ${changeType === 1 ? "orange-btn-active" : "orange-btn"
                        }`}
                      onClick={() => {
                        openChangeSt(changeType === 1 ? null : 1);
                      }}
                    >
                      {changeType === 1 ? "⚊" : "✚"} {t('changeState')}
                    </Button>

                    {hasAuthorization && portugueseAccessProtection && (
                      <Button
                        outline
                        className={`ml-0 mb-2 mb-md-0 ml-md-3 ${changeType === 2 ? "orange-btn-active" : "orange-btn"
                          }`}
                        onClick={() => {
                          openChangeSt(changeType === 2 ? null : 2);
                        }}
                      >
                        {changeType === 2 ? "⚊" : "✚"} {t('changeAgent')}
                      </Button>
                    )}
                  </div>
                )}
                <Button
                  outline
                  className="ml-3 mt-2 mt-md-0 d-xl-none d-block"
                  style={{ height: "max-content" }}
                  onClick={launchModalFilter}
                >
                  <FiFilter size={25} />
                </Button>
              </div>
              {requestLeadsAccess &&
                <Button
                  outline
                  className="mt-2 mt-md-0 mb-2 mb-md-0 ml-0 ml-md-3 orange-btn"
                  onClick={() => handleRequestLeadsClick()}
                  disabled={!hasPermissionToRequestLeads}
                >
                  {t('requestLeads')}
                </Button>
              }
            </div>

            <div className={styles.paginationSells}>
              <Pagination
                changePagination={changePagination}
                showNumb={limit}
              />
              <span className="ml-0 ml-md-4 text-success">
                Leads totales:{" "}
                <span className="text-primary">
                  {loading ? (
                    <Spinner size="sm" type="grow" className="flexicarColor" />
                  ) : section !== "destacados" ? (
                    count
                  ) : (
                    leads.length
                  )}
                </span>
              </span>
            </div>
          </div>

          <div
            className="w-100 d-flex ml-0 mt-4 mb-4 ml-lg-3 flex-column flex-md-row"
            style={{ gap: "15px" }}
          >
            <div className={styles.select_agent_state_lead}>
              {changeType === 1 && (
                <Select
                  placeholder="Estado del lead"
                  loadOptions={getFilterSellLeadState}
                  value={changeState}
                  onChange={(value) => setChangeState([value])}
                />
              )}
              {changeType === 2 && (
                <Select
                  placeholder="Agente del lead"
                  loadOptions={getFilterSellAgent2}
                  value={changeManager}
                  onChange={(value) => setChangeManager([value])}
                />
              )}
            </div>
            {changeType !== null && (
              <>
                <Button
                  outline
                  className="orange-btn"
                  onClick={() => {
                    launchChangeMultiple(
                      changeManager[0]?.value,
                      changeManager[0]?.label,
                      changeState[0]?.value,
                      changeState[0]?.label
                    );
                  }}
                >
                  Guardar
                </Button>
                <Button
                  outline
                  className="orange-btn"
                  onClick={() => changeMultipleMod_All(changeAll)}
                >
                  {changeAll ? "Seleccionar todos" : "Deseleccionar todos"}
                </Button>
              </>)}
          </div>

          <div className={styles.table_container}>
            <table className="table">
              <thead>
                <tr>
                  <th
                    className={`${changeType != null ? "d-block" : "d-none"}`}
                    scope="col-md-1"
                  >
                    ✔
                  </th>
                  <th scope="col-md-1"></th>
                  <th scope="col-md-1">{t('modif')}</th>
                  <th scope="col-md-2">{t('register')}</th>
                  <th scope="col-md-1">{t('state')}</th>
                  <th scope="col-md-1">{t('origin')}</th>
                  <th scope="col-md-1">{t('client')}</th>
                  <th scope="col-md-1">{t('phone')}</th>
                  <th scope="col-md-1">{t('vehicle')}</th>
                  <th scope="col-md-1">{t('local')}</th>
                  {portugueseAccessProtection && 
                  <th scope="col-md-1">{t('financing')}</th> 
                  }
                  <th scope="col-md-1">{t('actions')}</th>
                </tr>
              </thead>
              {leads.length > 0 ? (
                <tbody className={loading && "inactive"}>
                  {leads?.map((l, idx) => (
                    <tr key={l.id} style={{ fontSize: "15px" }}>
                      <td
                        className={`${changeType != null ? "d-inherit" : "d-none"
                          }`}
                      >
                        <input
                          onChange={(e) =>
                            changeMultipleMod(e.target.checked, l.id)
                          }
                          className={styles.checkboxL}
                          type="checkbox"
                          checked={changeData.includes(l.id)}
                        />
                      </td>
                      <td>
                        {l.destacado_st ? (
                          <HiStar
                            className="pointer"
                            onClick={() =>
                              changeDestStatus(idx, l.id, l.destacado_st)
                            }
                            size={25}
                            color="#007bff"
                            title="Quitar destacado"
                          />
                        ) : (
                          <HiOutlineStar
                            className="pointer"
                            onClick={() =>
                              changeDestStatus(idx, l.id, l.destacado_st)
                            }
                            size={25}
                            color="#007bff"
                            title="Destacar"
                          />
                        )}
                      </td>
                      <td data-label="Modificación">
                        <div
                          className="d-flex flex-lg-column align-items-center"
                          data-toggle="tooltip"
                          data-placement="top"
                          title={moment(l.ultima_accion).format("YYYY")}
                        >
                          <span>
                            {moment(l.ultima_accion).format("DD/MM")} <br />
                          </span>
                          <span className="ml-1 ml-md-0">
                            {moment(l.ultima_accion).format("HH:mm")}
                          </span>
                        </div>
                      </td>
                      <td data-label="Alta">
                        <div
                          className="d-flex flex-lg-column align-items-center"
                          data-toggle="tooltip"
                          data-placement="top"
                          title={moment(l.creacion).format("YYYY")}
                        >
                          <span>
                            {moment(l.creacion).format("DD/MM")} <br />
                          </span>
                          <span className="ml-1 ml-md-0">
                            {moment(l.creacion).format("HH:mm")}
                          </span>
                        </div>
                      </td>
                      <td data-label="Estado">
                        <span>{l.estado}</span>
                      </td>
                      <td data-label="Procedencia">
                        <span>{l.procedencia}</span>
                      </td>
                      <td data-label="Cliente">
                        <span>{l.nombre}</span>
                      </td>
                      <td data-label="Teléfono">
                        {l.telefono && (
                          <span>
                            <tcxspan
                              tcxhref={l.telefono}
                              title={`Call ${l.telefono} via 3CX`}
                            >
                              {l.telefono}
                            </tcxspan>
                          </span>
                        )}
                      </td>
                      <td data-label="Vehículo">
                        <span>{l.vehiculo}</span>
                      </td>
                      <td data-label="Local">
                        <span>{l.sede}</span>
                      </td>
                       {
                      portugueseAccessProtection &&
                     <td data-label="Financiación">
                        {
                          {
                            [null]: <span>-</span>,
                            "1": <span>{t("yes")}</span>,
                            "0": <span>{t("no")}</span>,
                          }[l.wantsFinancing]
                        }
                      </td>
                       }
                      <td>
                        <div>
                          <a
                            href={`lead-venta/${l.id}/${l.id_cliente_lead}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <HiDocumentText
                              size={25}
                              color="#007bff"
                              title="Ver lead"
                            />
                            <span>Ver Lead</span>
                          </a>
                          <button
                            onClick={() =>
                              launchModalChangeState(l.id, l.id_cliente_lead)
                            }
                          >
                            <HiFlag
                              size={25}
                              color="#007bff"
                              title="Cambiar Estado"
                              style={{ cursor: "pointer" }}
                            />
                            <span>Cambiar estado</span>
                          </button>
                          {portugueseAccessProtection && hasAuthorization && (
                            <button
                              onClick={() =>
                                launchModalChangeAgent(l.id, l.id_cliente_lead)
                              }
                            >
                              <HiUser
                                size={25}
                                color="#007bff"
                                title="Cambiar Agente"
                                style={{ cursor: "pointer" }}
                              />
                              <span>Cambiar agente</span>
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : leads.length === 0 ? (
                <h6>No existen leads disponibles</h6>
              ) : (
                <Loading />
              )}
            </table>
            {leads.length > 0 && (
              <PaginationFooter
                paginationMin={page}
                total={count + 1}
                showNumb={limit}
                elements={leads}
                changePagination={changePagination}
              />
            )}
          </div>
        </Mainlayout.Content>
      </Mainlayout>

      {modalState && (
        <ModalChangeState
          id_lead={leadSelectedModal}
          // vehicle_id={lead?.vehicle_id}
          id_cliente={clientSelectedModal}
          user_id={user}
          setModal={launchModalChangeState}
          status={modalState}
        />
      )}

      {modalAgent && (
        <ModalChangeAgent
          id_lead={leadSelectedModal}
          status={modalAgent}
          setModal={launchModalChangeAgent}
        />
      )}

      {modalFilter && (
        <ModalFilters
          footerButtonText="Ver Leads"
          state={modalFilter}
          dispose={launchModalFilter}
        >
          {filters}
        </ModalFilters>
      )}
    </>
  );
};

const mapStateToProps = (store) => ({
  storedFilters: store.filters.leads,
});

const mapDispatch = dispatcher(["leadsFilters"]);

export default connect(
  mapStateToProps,
  mapDispatch
)(LeadsVentaList);
