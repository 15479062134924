import instance from "@services/base";
import { API } from "@services/urlConstants";

const get = async (route) => {
  try {
    const response = await instance.get(route);
    return response.data;
  } catch (e) {
    throw e;
  }
};

const post = async (route, data, config = {}) => {
  try {
    const response = await instance.post(route, data, config);
    return response.data;
  } catch (e) {
    throw e;
  }
};

const put = async (route, data) => {
  const response = await instance.put(route, data);
  return response.data;
};

const deleteReq = async (route) => {
  const response = await instance.delete(route);
  return response.data;
};

const postFile = async (route, file) => {
  try {
    const response = await instance.post(route, file);
    return response.data;
  } catch (e) {
    throw e;
  }
};
const postWithStatus = async (route, data) => {
  try {
    const response = await instance.post(route, data);
    return response;
  } catch (e) {
    throw e;
  }
};

const getSignUrl = async (id) => {
	try {
		return await instance.get(API.SIGNED_URL(id));
	} catch (e) {
		throw e;
	}
};

export { get, post, put, postFile, getSignUrl, deleteReq,postWithStatus };
