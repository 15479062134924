import { post } from "@services/axiosCalls";

import { formatSpanishFullDate } from "@helpers/formatDate";
import { getWarrantiesOptions } from "@services/Warranties/warranties";
import { formatNumberWithDecimals } from "@helpers/formatNumberWithDecimals";

import { environment } from "@config/environment.config";

const getDocgenBody = async (id_contrato, tipo, robusto) => {
    const data = await post("/api/ejs_data", { id_contrato });

    const data_template = data[0].datos;

    const vehicleId = data[0].id_vehiculo;

    const warrantiesOptions = await getWarrantiesOptions(vehicleId);

    const fecha = data[0].fecha_modificacion ?? data[0].fecha;

    const pvpWithDiscount = data_template.pvp - data_template.agentDiscount.total;

    const totalPvp = (pvpWithDiscount + data_template.administrativeCosts + data_template.importe_transporte + data_template.warranty.price) 
    - data_template.reservationAmount - data_template.pago_coche_forma_pago - data_template.entryAmount;

    const isBuyAndSell = data_template.buy_and_sell === 1;

    const idTemplate = isBuyAndSell ? Number(environment.BUY_AND_SELL_TEMPLATE_PT) : Number(environment.SELL_CONTRACT_TEMPLATE_PT);

    const isPremiumWarranty = data_template.warranty.basic === 0;

    const variables = {
        fecha: formatSpanishFullDate(fecha),
        empresa_cp: data_template.empresa_cp || "",
        empresa_nombre: data_template.empresa_nombre,
        empresa_CIF: data_template.empresa_CIF,
        empresa_direccion: data_template.empresa_direccion,
        empresa_provincia: data_template.empresa_provincia,
        nombre: data_template.nombre,
        apellido1: data_template.apellido1,
        apellido2: data_template.apellido2,
        DNI: data_template.DNI,
        direccion: data_template.direccion,
        provincia: data_template.provincia,
        poblacion: data_template.poblacion,
        CP: data_template.CP,
        email: data_template.email,
        telefono: data_template.telefono,
        creationAgent: `${data_template.creationAgent.firstName} ${data_template.creationAgent.lastName1} ${data_template.creationAgent.lastName2}`,
        marca: data_template.marca,
        modelo: data_template.modelo,
        version: data_template.version,
        provincia_local: data_template.provincia_local,
        color: data_template.color,
        fuel: data_template.fuel,
        transmission: data_template.transmission,
        matricula: data_template.matricula,
        plateDate: data_template.plateDate,
        kilometros: formatNumberWithDecimals(data_template.kilometros, 0),
        warrantyType: !isBuyAndSell ? warrantiesOptions.find(x => data_template.warranty.typeId === x.value).label : "",
        paymentType: data_template.paymentType.name,
        carAsPaymentVehicleBrand: data_template.carAsPaymentVehicle?.brand ? data_template.carAsPaymentVehicle.brand : "",
        carAsPaymentVehicleModel: data_template.carAsPaymentVehicle?.model ? data_template.carAsPaymentVehicle.model : "",
        carAsPaymentVehicleColor: data_template.carAsPaymentVehicle?.color ? data_template.carAsPaymentVehicle.color : "",
        carAsPaymentVehiclePlate: data_template.carAsPaymentVehicle?.plate ? data_template.carAsPaymentVehicle.plate : "",
        carAsPaymentVehiclePlateDate: data_template.carAsPaymentVehicle?.plateDate ? data_template.carAsPaymentVehicle.plateDate : "",
        carAsPaymentVehicleKms: data_template.carAsPaymentVehicle?.kms ? formatNumberWithDecimals(data_template.carAsPaymentVehicle.kms, 0) : "",
        conformidades: data_template.conformidades,
        pvp: formatNumberWithDecimals(data_template.pvp, 2),
        entryAmount: data_template.entryAmount > 0 ? `-${formatNumberWithDecimals(data_template.entryAmount, 2)}` : "0,00",
        importe_garantia: formatNumberWithDecimals(data_template.warranty.price, 2),
        importe_transporte: formatNumberWithDecimals(data_template.importe_transporte, 2),
        administrativeCosts: formatNumberWithDecimals(data_template.administrativeCosts, 2),
        agentDiscount: data_template.agentDiscount.total > 0 ? `-${formatNumberWithDecimals(data_template.agentDiscount.total, 2)}` : "0,00",
        pvpWithDiscount: formatNumberWithDecimals(pvpWithDiscount, 2),
        reservationAmount: data_template.reservationAmount > 0 ? `-${formatNumberWithDecimals(data_template.reservationAmount, 2)}` : "0,00",
        pago_coche_forma_pago: data_template.pago_coche_forma_pago > 0 ? `-${formatNumberWithDecimals(data_template.pago_coche_forma_pago, 2)}` : "0,00",
        total_pvp: formatNumberWithDecimals((totalPvp), 2),
        inProvince: data_template.provincia_local === "Porto" ? "No" : "Em",
        communicationEmail: data_template.communicationEmail ? "Autoriza" : "Não Autoriza",
        communicationSMS: data_template.communicationSMS ? "Autoriza" : "Não Autoriza",
        surveysEmail: data_template.surveysEmail ? "Autoriza" : "Não Autoriza",
        surveysSMS: data_template.surveysSMS ? "Autoriza" : "Não Autoriza",
        observaciones: data_template.observaciones ?? ""
    };

    const conditionals = {
        noDiscount: data_template.agentDiscount.total === 0,
        activeDiscount: data_template.agentDiscount.total > 0,
        activePremiumWarranty: isPremiumWarranty,
        activeBasicWarranty: !isPremiumWarranty,
        observationsExists: data_template.observaciones !== null && data_template.observaciones.length > 0
    };

    const signData = {
        docgen: {
            variables: variables,
            conditionals: conditionals,
            signatureFields: [
                "[FIRMA_1]"
            ]
        },
        robusto: robusto,
        tipo: tipo,
        id_contrato: id_contrato,
        idTemplate: idTemplate
    };

    const pdfData = {
        idTemplate: idTemplate,
        variables: variables,
        conditionals: conditionals
    };

    let bodyData;

    if (tipo && robusto) {
        bodyData = signData;
    } else {
        bodyData = pdfData;
    };

    return bodyData;
};


export default getDocgenBody;