import * as Yup from "yup";

import { bookingContractClientSchema } from "@schema/BookingContract/clientSchema";
import { bookingContractVehicleSchema } from "@schema/BookingContract/vehicleSchema";
import { bookingContractBookingSchema } from "@schema/BookingContract/bookingSchema";
import { bookingContractSellInfoSchema } from "@schema/BookingContract/sellDataSchema";
import { sellContractAgentDiscountSchema } from "@schema/SellContract/agentDiscountSchema";
import RolType from "@constants/roles";

const bookingContractSchema = (rol, mode) =>
	Yup.object({
		client: bookingContractClientSchema,
		vehicle: bookingContractVehicleSchema,
		booking: bookingContractBookingSchema,
		sellInfo: bookingContractSellInfoSchema,
		agentDiscount: sellContractAgentDiscountSchema,
		observations: Yup.string().nullable().default("").max(300, "Máximo 300 caracteres"),
		locution: Yup.boolean().when([], {
			is: () => mode === "create" && (rol.includes(RolType.SALES_CALL_CENTER_AGENT) || rol.includes(RolType.SALES_CALL_CENTER_SUPERVISOR)),
			then: (schema) => schema.oneOf([true], "Debe confirmar").required("Campo obligatorio"),
			otherwise: (schema) => schema.notRequired(),
		}),
	});

export { bookingContractSchema };
