import * as Yup from "yup";

export const bookingContractVehicleSchema = Yup.object({
	plate: Yup.string().plate(),
	km: Yup.number()
		.moreThan(0, "Los Km deben ser mayor que 0")
		.integer("No se admiten decimales")
		.max(999999, "Km debe ser igual o inferior a 999.999"),
	bastidor: Yup.string().bastidor(),
	dealership: Yup.object({
		label: Yup.string(),
		value: Yup.number()
	}).required("Campo obligatorio"),
	dealershipProvince: Yup.object({
		label: Yup.string(),
		value: Yup.number()
	}).required("Campo obligatorio"),
	companie: Yup.object({
		label: Yup.string(),
		value: Yup.number()
	}).required("Campo obligatorio"),
	iva: Yup.object({
		label: Yup.string(),
		value: Yup.boolean()
	}).required("Campo obligatorio"),
	dateLastITV: Yup.string().nullable().isISODateString({ excludeEmptyString: true }),
	dateExpirationITV: Yup.string().nullable().isISODateString({ excludeEmptyString: true }),
	conformidades: Yup.string().nullable().max(300, "Máximo 300 caracteres")
});
