import { useRef, useState } from "react";
import Form from "@components/Form/Form";
import BookingPaymentForm from "@views/Booking/BookingPaymentForm";
import BookingPaymentMethod from "@views/Booking/BookingPaymentMethod";
import CreateBookingContract from "@views/Booking/CreateBookingContract";
import useStep from "@hooks/useStep";
import styles from "@styles/views/BookingContract/booking-contract.module.scss";
import {
	bookingFormFirstStepSchema,
	bookingFormSecondStepSchema
} from "@schema/BookingFormFirstData";

const MAX_STEPS = 3;

const StepsCreateBookingContract = ({ data, onCreate }) => {
	const formRef = useRef(null);

	const [currentStep, { goToNextStep, goToPrevStep, canGoToNextStep, canGoToPrevStep }] =
		useStep(MAX_STEPS);

	const [initialBookingData, setInitialBookingData] = useState(data);

	const firstStepDefaultValues = {
		paymentMethod: null
	};
	const secondStepDefaultValues = {
		carPayment: null,
		financingWants: null,
		financingBank: null
	};

	const PreviousStep = () =>
		canGoToPrevStep && (
			<button
				type="button"
				className={`btn btn-flexicar-orange`}
				onClick={goToPrevStep}
			>
				Atrás
			</button>
		);

	const NextStep = () => {
		const onClick = () => {
			formRef.current.triggerSubmit();
		};

		return (
			canGoToNextStep &&
			currentStep !== MAX_STEPS && (
				<button
					type="submit"
					className={`btn btn-flexicar-orange`}
					onClick={onClick}
				>
					Siguiente
				</button>
			)
		);
	};

	const handleStepSubmit = (formData) => {
		setInitialBookingData({ ...initialBookingData, ...formData });
		goToNextStep();
	};

	return (
		<div>
			<h5>
				Paso {currentStep} de {MAX_STEPS}
			</h5>
			{currentStep === 1 && (
				<Form
					ref={formRef}
					defaultValues={firstStepDefaultValues}
					schema={bookingFormFirstStepSchema}
					onSubmit={handleStepSubmit}
				>
					<BookingPaymentForm>
						<div className={styles.buttonContainer}>
							<NextStep />
						</div>
					</BookingPaymentForm>
				</Form>
			)}
			{currentStep === 2 && (
				<Form
					ref={formRef}
					defaultValues={secondStepDefaultValues}
					schema={bookingFormSecondStepSchema}
					onSubmit={handleStepSubmit}
				>
					<BookingPaymentMethod>
						<div className={styles.buttonContainer}>
							<PreviousStep />
							<NextStep />
						</div>
					</BookingPaymentMethod>
				</Form>
			)}
			{currentStep === 3 && (
				<CreateBookingContract
					data={initialBookingData}
					onCreate={onCreate}
				/>
			)}
		</div>
	);
};

export default StepsCreateBookingContract;
