import { useCallback, useContext, useEffect, useRef, useState } from "react";
import * as Yup from "yup";

import GenericModal from "@components/GenericModal";
import Form from "@components/Form/Form";
import FormInput from "@components/Form/Input/FormInput";
import instance from "@services/base";
import { API } from "@services/urlConstants";
import { checkWithin72Hours } from "@utils/checkWhitin72Hours";
import { UserContext } from "@contexts/UserContext";

const CatcherSearchPlateModal = ({
	isOpen,
	setIsOpen,
	clientId,
	setPlateState,
	setState,
	onSelectLead,
	setShowCatcherWarningModal
}) => {
	const formRef = useRef();
	const { user } = useContext(UserContext);

	const [leads, setLeads] = useState([]);

	const getPurchaseLeadsByClient = useCallback(
		async (currentPage) => {
			const { data } = await instance.get(
				API.GET_PURCHASE_LEADS_BY_CLIENT(clientId, { page: currentPage })
			);
			const newLeads = data.results;
			if (newLeads.length === 0) return;

			setLeads((prevLeads) => [...prevLeads, ...newLeads]);
			if (Math.ceil(data.total / data.size) !== currentPage)
				await getPurchaseLeadsByClient(currentPage + 1);
		},
		[clientId]
	);

	useEffect(() => {
		getPurchaseLeadsByClient(1);
	}, [getPurchaseLeadsByClient]);

	const searchPlate = async ({ plate }) => {
		const lead = leads.find((lead) => lead.plate === plate);

		if (!lead) {
			setIsOpen(false);
			setState(prev => ({ ...prev, edit_mode: false, add_mode: true }));
			setPlateState(plate);
			return;
		}

		const hasReservation = lead.status.reservationCollectorDatetime;
		const isRecentReservation = checkWithin72Hours(lead.status.reservationCollectorDatetime);
		const isDifferentEmployee = lead.status.idEmpleado !== user;

		hasReservation && isRecentReservation && isDifferentEmployee
			? setShowCatcherWarningModal(true)
			: onSelectLead(lead.id);
	};

	return (
		<GenericModal
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			headContent="Añadir la matrícula"
			bodyContent={
				<Form
					ref={formRef}
					onSubmit={searchPlate}
					schema={Yup.object({ plate: Yup.string().required("Campo obligatorio").plate() })}
				>
					<FormInput name="plate" />
				</Form>
			}
			footerContent={
				<button
					className="btn btn-flexicar-orange"
					onClick={() => formRef.current.triggerSubmit()}
				>
					Ir Lead
				</button>
			}
		/>
	);
};

export default CatcherSearchPlateModal;
