import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import styles from "./index.module.scss";
import { getEncargados } from "@services/Dealers/dealers.service";
import Select from "@components/Select";

const OptionalDealerModal = (props) => {
  const {
    newDealer,
    setNewDealerHandler,
    modalOptionalDealer,
    setModalOptionalDealer,
    setModalSchedules,
    setDealerReady,
    cancelHandler,
  } = props;

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [encargados, setEncargados] = useState([]);

  useEffect(() => {
    getEncargados(`/society/encargados`)
      .then((response) => {
        setEncargados(response);
      })
      .catch((e) => {
        throw e;
      });
  }, []);

  const onSubmit = (data) => {
    setNewDealerHandler({
      ...newDealer,
      movil: data.movil !== "" ? data.movil : null,
      slug: data.slug,
      administrationPhone:
        data.administrationPhone !== "" ? data.administrationPhone : null,
      administrationEmail:
        data.administrationEmail !== "" ? data.administrationEmail : null,
      encargadoLocal: data.encargado.value,
    });
    setDealerReady(true);
  };

  const handleBack = () => {
    setModalSchedules(true);
    setModalOptionalDealer();
  };

  return (
    <div>
      <Modal isOpen={modalOptionalDealer}>
        <ModalHeader className={styles.modal_header} toggle={cancelHandler}>
          {"Datos opcionales. Paso 4 de 4"}
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Container>
              <Row>
                <Col className="my-1 col-6">
                  <input
                    type="tel"
                    className={`form-control ${errors.movil && "is-invalid"}`}
                    placeholder="Móvil concesionario"
                    {...register("movil", {
                      maxLength: 9,
                      minLength: 9,
                      pattern: /^[0-9]*$/,
                    })}
                  />

                  {errors.movil?.type === "pattern" && (
                    <span className={`${styles.error}`}>
                      Teléfono no válido
                    </span>
                  )}
                  {errors.movil?.type === "maxLength" && (
                    <span className={`${styles.error}`}>
                      Teléfono no válido
                    </span>
                  )}
                  {errors.movil?.type === "minLength" && (
                    <span className={`${styles.error}`}>
                      Teléfono no válido
                    </span>
                  )}
                </Col>
                <Col className="my-1 col-6">
                  <input
                    className={`form-control ${errors.slug && "is-invalid"} `}
                    placeholder="slug"
                    {...register("slug")}
                  />
                </Col>
              </Row>
              <Row>
                <h6 className="my-1 col-12">Administración:</h6>
                <Col className="my-1 col-12">
                  <input
                    className={`form-control ${
                      errors.administrationEmail && "is-invalid"
                    }`}
                    placeholder="Email de administración del concesionario"
                    {...register("administrationEmail", {
                      pattern:
                        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i,
                    })}
                  />
                </Col>
                {errors.administrationEmail?.type === "pattern" && (
                  <span className={`${styles.error}`}>Email no válido</span>
                )}
              </Row>
              <Row>
                <Col className="my-1 col-7">
                  <input
                    type="tel"
                    className={`form-control ${
                      errors.administrationPhone && "is-invalid"
                    }`}
                    placeholder="Teléfono administración"
                    {...register("administrationPhone", {
                      maxLength: 9,
                      minLength: 9,
                      pattern: /^[0-9]*$/,
                    })}
                  />

                  {errors.administrationPhone?.type === "pattern" && (
                    <span className={`${styles.error}`}>
                      Teléfono no válido
                    </span>
                  )}
                  {errors.administrationPhone?.type === "maxLength" && (
                    <span className={`${styles.error}`}>
                      Teléfono no válido
                    </span>
                  )}
                  {errors.administrationPhone?.type === "minLength" && (
                    <span className={`${styles.error}`}>
                      Teléfono no válido
                    </span>
                  )}
                </Col>
              </Row>

              <Row>
                <Col
                  className={`my-1 col-12 ${errors.encargado && "is-invalid"}`}
                >
                  <Controller
                    name="encargado"
                    control={control}
                    rules={{ required: true }}
                    defaultValue={newDealer?.encargado}
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="Seleccione Encargado"
                        options={encargados}
                      />
                    )}
                  />
                  {errors.encargado?.type === "required" && (
                    <span className={`${styles.error}`}>
                      Seleccione encargado
                    </span>
                  )}
                </Col>
              </Row>
            </Container>
          </form>
        </ModalBody>
        <ModalFooter>
          <Row className="justify-content-center">
            <Button className="mx-5" color="secondary" onClick={handleBack}>
              Atras
            </Button>
            <Button
              className={`${styles.btn_custom} mx-5`}
              onClick={handleSubmit(onSubmit)}
            >
              Añadir
            </Button>
          </Row>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default OptionalDealerModal;
