import { useState, useEffect, useCallback, useReducer, useMemo } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Spinner } from "reactstrap";

import Mainlayout from "@layouts/MainLayout";
import Pagination from "@views/VehicleList/components/Pagination";
import TableStock from "@views/VehicleList/components/TableStock";
import {
	VEHICLE_VALIDATION_ACTIONS,
	vehicleValidationFiltersInitialState,
	vehicleValidationFiltersReducer
} from "@views/VehicleList/actions/vehicleValidationReducer";
import Paginator from "@components/Paginator";
import Select from "@components/Select";
import DoubleFilterChipsList from "@components/DoubleFilterChipsList";
import { dispatcher } from "@redux/actions/dispatchers";
import { useDebouncedValue } from "@hooks/useDebouncedValue";
import { errorToast } from "@helpers/toastFunctions";
import { fuels, getDrafts } from "@services/Vehicle/vehicle.service";
import { getFilterGVOBrands, getFilterGVOModels } from "@services/Filtros/filtros";
import { getActiveLocals } from "@services/Employees/getActiveLocals";
import { vehicleValidationPhotoTypeOptions } from "@constants/selectOptions";
import { parseToRSOptions } from "@utils/mapOptions";

const Stock = ({ storedFilters, dispatchVehicleValidationFilters }) => {
	const { t, ready } = useTranslation("vehicleValidationList");
	const history = useHistory();

	const photoTypeOptions = useMemo(() => vehicleValidationPhotoTypeOptions(t), [t]);

	const [cars, setCars] = useState({ results: null, count: 0 });

	const [filterState, filterDispatch] = useReducer(
		vehicleValidationFiltersReducer,
		storedFilters || vehicleValidationFiltersInitialState
	);
	const plateFilterDebounce = useDebouncedValue(filterState.plate, 500);

	const getUserActiveLocals = async () => {
		try {
			const responseLocalSelectOptions = await getActiveLocals();
			return responseLocalSelectOptions.map((x) => ({
				value: x.id,
				label: x.name
			}));
		} catch (e) {
			errorToast();
			return [];
		}
	};

	const searchCars = useCallback(async () => {
		if (!filterState.isUpdating) {
			const dataCall = {
				page: filterState.page,
				size: filterState.pageSize,
				locals: filterState.locales,
				makeIds: filterState.makes.map((x) => x.value),
				modelIds: filterState.models.map((x) => x.value),
				fuelIds: filterState.fuels.map((x) => x.value),
				hasImages: filterState.hasImages,
				order: "date",
				orderDir: "DESC",
				buyAndSell: filterState.buyAndSell
			};

			if (plateFilterDebounce === "" || plateFilterDebounce.length >= 6) {
				if (plateFilterDebounce !== "") dataCall.plate = plateFilterDebounce;

				try {
					const response = await getDrafts(dataCall);
					const { results, total } = response;
					setCars({ results, count: total });
				} catch (e) {
					errorToast();
				}
			}
		} else if (filterState.isUpdating && plateFilterDebounce === "") {
			filterDispatch({ type: VEHICLE_VALIDATION_ACTIONS.COMPLETE_UPDATE });
		}
	}, [
		filterState.isUpdating,
		filterState.page,
		filterState.pageSize,
		plateFilterDebounce,
		filterState.locales,
		filterState.makes,
		filterState.fuels,
		filterState.models,
		filterState.hasImages,
		filterState.buyAndSell
	]);

	const handleChange = (event) =>
		event.target.type === "checkbox"
			? filterDispatch({
					type: VEHICLE_VALIDATION_ACTIONS.SET_BUY_AND_SELL,
					payload: event.target.checked
			  })
			: filterDispatch({ type: VEHICLE_VALIDATION_ACTIONS.SET_PLATE, payload: event.target.value });

	const loadVehiclePage = (id) => {
		dispatchVehicleValidationFilters(filterState);
		history.push(`/main/validacion-vehiculo/${id}?tab=datos`);
	};

	const filtersListSelected = [
		{
			name: VEHICLE_VALIDATION_ACTIONS.SET_MAKES,
			option: "makes",
			filters: filterState.makes.map((x) => x.label)
		},
		{
			name: VEHICLE_VALIDATION_ACTIONS.SET_MODELS,
			option: "models",
			filters: filterState.models.map((x) => x.label)
		},
		{
			name: VEHICLE_VALIDATION_ACTIONS.SET_FUELS,
			option: "fuels",
			filters: filterState.fuels.map((x) => x.label)
		},
		{
			name: VEHICLE_VALIDATION_ACTIONS.SET_LOCALES,
			option: "locales",
			filters: filterState.locales.map((x) => x.label)
		}
	];

	const removeFilterListSelectedItem = (item, index, name, option) => {
		filterDispatch({
			type: name,
			payload: [...filterState[option]].filter((x) => x.label !== item)
		});
	};

	useEffect(() => {
		document.title = "Flexicar | Validación de vehiculo";
		searchCars();
	}, [searchCars]);

	useEffect(() => {
		if (ready) {
			filterDispatch({
				type: VEHICLE_VALIDATION_ACTIONS.SET_HAS_IMAGES,
				payload: storedFilters ? storedFilters.hasImages : photoTypeOptions[0].value
			});
		}
	}, [ready, storedFilters, photoTypeOptions]);

	return (
		<Mainlayout>
			<Mainlayout.Content
				contentTitle={t("vehiclesValidation")}
				full
			>
				<div className="d-flex flex-wrap">
					<div className="col-12 col-md-2 mb-2 mb-md-0">
						<label className="m-0">{t("makes")}</label>
						<Select
							isMulti
							placeholder={t("select")}
							loadOptions={getFilterGVOBrands}
							value={filterState.makes}
							onChange={(values) =>
								filterDispatch({
									type: VEHICLE_VALIDATION_ACTIONS.SET_MAKES,
									payload: values
								})
							}
						/>
					</div>
					<div className="col-12 col-md-2 mb-2 mb-md-0">
						<label className="m-0">{t("models")}</label>
						<Select
							isMulti
							placeholder={t("select")}
							reloadOptions
							loadOptions={() => getFilterGVOModels([filterState.makes.map((x) => x.value)])}
							value={filterState.models}
							onChange={(values) =>
								filterDispatch({
									type: VEHICLE_VALIDATION_ACTIONS.SET_MODELS,
									payload: values
								})
							}
							isDisabled={filterState.makes.length === 0}
						/>
					</div>
					<div className="col-12 col-md-2 mb-2 mb-md-0">
						<label className="m-0">{t("fuel")}</label>
						<Select
							isMulti
							placeholder={t("select")}
							loadOptions={async () => parseToRSOptions(await fuels())}
							value={filterState.fuels}
							onChange={(values) =>
								filterDispatch({
									type: VEHICLE_VALIDATION_ACTIONS.SET_FUELS,
									payload: values
								})
							}
						/>
					</div>
					<div className="col-12 col-md-2 mb-2 mb-md-0">
						<label className="m-0">{t("plate")}</label>
						<input
							style={{ height: "42px" }}
							autoComplete="off"
							placeholder={t("search")}
							className="form-control ds-input"
							type="text"
							value={filterState.plate}
							onChange={handleChange}
						/>
					</div>
					<div className="col-12 col-md-2 mb-2 mb-md-0">
						<label className="m-0">{t("local")}</label>
						<Select
							isMulti
							placeholder={t("select")}
							loadOptions={getUserActiveLocals}
							value={filterState.locales}
							onChange={(values) =>
								filterDispatch({
									type: VEHICLE_VALIDATION_ACTIONS.SET_LOCALES,
									payload: values
								})
							}
						/>
					</div>
					<div className="col-12 col-md-2 mb-2 mb-md-0">
						<label className="m-0">{t("photo")}</label>
						<Select
							placeholder={t("select")}
							options={photoTypeOptions}
							value={photoTypeOptions.find((x) => x.value === filterState.hasImages)}
							onChange={(opt) =>
								filterDispatch({
									type: VEHICLE_VALIDATION_ACTIONS.SET_HAS_IMAGES,
									payload: opt.value
								})
							}
						/>
					</div>
				</div>

				<div className="d-flex flex-wrap mt-4 ml-2">
					<DoubleFilterChipsList
						dropdownSelected={filtersListSelected}
						deleteDropdownSelected={removeFilterListSelectedItem}
					/>
				</div>

				<div className="d-flex flex-column flex-md-row mt-4 mb-4 justify-content-md-between align-items-center">
					<div className="col-md-6">
						<input
							type="checkbox"
							checked={filterState.buyAndSell}
							onChange={handleChange}
						/>
						<label className="mb-0 ml-2">{t("professionalsBuySale")}</label>
					</div>
					<div className="col-md-6 d-flex flex-column flex-md-row mt-4 mt-md-0 justify-content-md-end">
						<Pagination
							changePagination={(_, newSize) =>
								filterDispatch({
									type: VEHICLE_VALIDATION_ACTIONS.SET_PAGE_SIZE,
									payload: newSize
								})
							}
							showNumb={filterState.pageSize}
						/>
						<div className="ml-md-3">
							<span className="text-success mr-1">{t("vehiclesValidation")}: </span>
							<span className="text-primary">{cars.count}</span>
						</div>
					</div>
				</div>

				{cars.results?.length > 0 ? (
					<>
						<TableStock
							cars={cars.results}
							loadVehiclePage={loadVehiclePage}
							filterDispatch={filterDispatch}
						/>

						<div className="flex justify-content-center pt-2 pb-4">
							<Paginator
								pageSize={filterState.pageSize}
								totalCount={cars.count}
								currentPage={filterState.page}
								onPageChange={(page) =>
									filterDispatch({ type: VEHICLE_VALIDATION_ACTIONS.SET_PAGE, payload: page })
								}
							/>
						</div>
					</>
				) : (
					<div className="d-flex justify-content-center pt-5 w-100">
						{cars.results === null && (
							<>
								<Spinner
									type="grow"
									className="flexicarColor"
								/>
								<p className="flexicarColor"> {t("loading")}</p>
							</>
						)}

						{cars.results?.length === 0 && (
							<p className="flexicarColor">{t("noResults")}</p>
						)}
					</div>
				)}
			</Mainlayout.Content>
		</Mainlayout>
	);
};

const mapStateToProps = (store) => ({
	storedFilters: store.filters.vehicleValidation
});

const mapDispatch = dispatcher(["vehicleValidationFilters"]);

export default connect(mapStateToProps, mapDispatch)(Stock);
