import { useFormContext } from "react-hook-form";
import { useEffect, useCallback } from "react";
import styles from "@styles/views/BookingContract/booking-data.module.scss";

import PaneHeader from "@components/PaneHeader";
import FormInput from "@components/Form/Input/FormInput";
import FormTextArea from "@components/Form/Textarea/FormTextarea";
import FormSelect from "@components/Form/Select/FormSelect";
import { WarningPanel } from "@components/InfoBox";
import { payOptions } from "@constants/selectOptions";
import FormFormattedNumberInput from "@components/Form/FormattedNumberInput/FormFormattedNumberInput";

const BookingData = ({ isSellContract, mode }) => {
	const { watch, setValue } = useFormContext();

	const booking = {
		price: watch("booking.price"),
		agent: watch("booking.agent"),
		date: watch("booking.date"),
		payOptions: watch("booking.payOptions")
	};
	
	const isPasarelaEditMode = mode === "edit" && booking.payOptions?.value === "pasarela";

	const isInitiallyPasarela = isPasarelaEditMode && booking.price !== 0;

	const filteredPayOptions = mode === "edit" && !isInitiallyPasarela
		? payOptions.filter(option => option.value !== "pasarela")
		: payOptions;

	const isPaymentFieldsDisabled = isSellContract || isInitiallyPasarela;
	

	const priceSelectorChange = useCallback(
		(option) => {
			const amounts = {
				pasarela: [booking.price, 0, 0],
				presencial: [0, booking.price, 0],
				contado: [0, 0, booking.price]
			};
			
			if (amounts[option.value]) {
				setValue("payAmounts.virtualTPVAmount", amounts[option.value][0]);
				setValue("payAmounts.dealershipTPVAmount", amounts[option.value][1]);
				setValue("payAmounts.transferenceAmount", amounts[option.value][2]);
			}
		},
		[booking.price, setValue]
	);

	useEffect(() => {
		if (booking.payOptions && !isSellContract) {
			priceSelectorChange(booking.payOptions);
		}
	}, [isSellContract, booking.payOptions, priceSelectorChange]);

	return (
		<div>
			<PaneHeader title="Datos de la Reserva" />
			<div className={styles.infoContainer}>
				<div>
					<span>Agente:</span> <span>{booking.agent}</span>
				</div>
				<div>
					<span>Fecha:</span> <span>{booking.date}</span>
				</div>

				<FormSelect
					name="booking.payOptions"
					placeholder="Forma de pago"
					options={filteredPayOptions}
					isDisabled={isPaymentFieldsDisabled}
					additionalOnChangeCb={priceSelectorChange}
				/>
			</div>
			<div className={styles.container}>
				<FormFormattedNumberInput
					name="booking.price"
					label="Reserva (en €)"
					decimalScale={0}
					disabled={isPaymentFieldsDisabled || isPasarelaEditMode}
				/>
				<FormInput
					name="booking.concept"
					label="Concepto"
					maxLength={50}
					disabled={isPaymentFieldsDisabled}
				/>
				<FormTextArea
					name="booking.observations"
					label="Observaciones"
					maxLength={300}
					disabled={isPaymentFieldsDisabled}
				/>
			</div>

			{!isSellContract && (
				<WarningPanel
					className="mt-2"
					text="Los datos rellenados a continuación se trasladarán como borrador al contrato de venta para facilitar el proceso"
				/>
			)}
		</div>
	);
};

export default BookingData;
