import React, { useState, useEffect, useCallback } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { post } from "@services/axiosCalls";
import { API } from "@services/urlConstants";
import moment from "moment";
import { errorToast } from "@helpers/toastFunctions";
import styles from "./index.module.scss";
import { MdAccountCircle, MdChat } from "react-icons/md";
import { useTranslation } from "react-i18next";
import Form from "@components/Form/Form";
import FormInput from "@components/Form/Input/FormInput";
import * as Yup from "yup";

const ModalConversacionLead = ({ lead_id, user }) => {
  const { t, ready } = useTranslation("modalConversacionesLead");

  const [conversaciones, setConversaciones] = useState([]);
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const newConversation = async (formData) => {
    try {
      await post(API.NEW_CONVERSATION_LEED, {
        lead_id,
        user,
        conversacion: formData.message,
      });
      await getConversations();
      setModal(false);
    } catch {
      errorToast(<span>{t("errors.errorMessage")}</span>);
    }
  };

  const getConversations =  useCallback(async () => {
    if (lead_id && modal) {
      try {
        const response = await post(API.GET_CONVERSATIONS_LEEDS, { lead_id });
        setConversaciones(response.data);
      } catch {
        errorToast(<span>{t("errors.errorLoading")}</span>);
      }
    }
  }, [t, lead_id, modal]);

  useEffect(() => {
    ready && getConversations();
  }, [ready, getConversations]);

  return (
    <>
      <div>
        <MdChat color="white" role="button" size={30} onClick={toggle} />

        <Modal isOpen={modal} style={{ maxWidth: "700px" }} toggle={toggle}>
          <ModalHeader className={`${styles.modal_header}`}>
            Conversaciones con clientes
          </ModalHeader>
          <ModalBody>
            <div>
              {conversaciones.map((elm, idx) => (
                <div key={`conversaciones-${elm.nombre}-${idx}`} className="row">
                  <div className="col-md-12 col-sm-12">
                    <p className={styles.chat}>{elm.texto}</p>
                  </div>
                  <div
                    className="col-md-12 col-sm-12"
                    style={{ marginBottom: "10px" }}
                  >
                    <MdAccountCircle
                      className="md-36 m-2"
                      size={40}
                      role="button"
                    />

                    <small>
                      {" "}
                      {elm.nombre}{" "}
                      {moment(elm.fecha).format("DD-MM-YYYY | HH.mm")}
                    </small>
                  </div>
                </div>
              ))}
            </div>
          </ModalBody>
					<ModalFooter style={{ width: "100%" }}>
						<Form
							className="row form-inline"
							onSubmit={newConversation}
							schema={Yup.object({ message: Yup.string().matches(/^(?!\s*$).+/, 'No se puede enviar un mensaje vacío').min(1, "Campo obligatorio") })}
						>
							<div className="col-md-9 col-sm-9">
								<FormInput
									name="message"
									placeholder="Conversación con cliente"
								/>
							</div>
							<div className="col-md-3 col-sm-3">
								<Button className={`${styles.btn_custom} ml-4`}>Guardar</Button>
							</div>
						</Form>
					</ModalFooter>
        </Modal>
      </div>
    </>
  );
};

export default ModalConversacionLead;
