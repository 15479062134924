import SellData from "@views/SellContract/SellData/SellData";
import { useEffect, useRef, useState, useContext } from "react";
import isEqual from "lodash.isequal";

import { UserContext } from "@contexts/UserContext";
import RolType from "@constants/roles";
import Form from "@components/Form/Form";
import { bookingContractSchema } from "@schema/BookingContract";
import styles from "@styles/views/SellContract/index.module.scss";
import ClientData from "@views/SellContract/ClientData/ClientData";
import VehicleData from "@views/SellContract/VehicleData/VehicleData";
import BookingData from "@views/Booking/BookingData/BookingData";
import { getStock } from "@services/Vehicle/vehicle.service";
import BookingResume from "@views/Booking/BookingResume/BookingResume";
import Observations from "@views/SellContract/Observations/Observations";
import Locution from "@views/Booking/BookingLocution/BookingLocution";
import useHttpLoadingState from "@hooks/useHttpLoadingState";
import GenericModal from "@components/GenericModal";
import { SELL_CONTRACT_PRICE_TYPES } from "@constants/sellContratDefaults";
import { formatNumberWithDecimals } from "@helpers/formatNumberWithDecimals";

const INITIAL_PRICES = {
	buyAndSell: false,
	priceDealerSelling: 0,
	priceCash: 0,
	priceFinanced: 0,
	priceOffer: 0
};

const BookingContract = ({
	mode,
	defaultValues,
	onSubmit,
	dealershipsOptions,
	gestoriasOptions,
	provincesOptions,
	warrantyOptions,
	flexicarVehicleId,
	priceTypeEdition,
	pvpEdition,
	data
}) => {
	const formRef = useRef(null);
	const { rol } = useContext(UserContext);

	const locutionNeeded =
		rol.includes(RolType.SALES_CALL_CENTER_AGENT) ||
		rol.includes(RolType.SALES_CALL_CENTER_SUPERVISOR);

	const [prices, setPrices] = useState(INITIAL_PRICES);
	const [newPvp, setNewPvp] = useState(0);
	const [hasPricesChnaged, setHasPricesChanged] = useState(false);

	const [isSubmitting, handleSubmitting] = useHttpLoadingState();

	useEffect(() => {
		(async () => {
			const stockPrices = await getStock(flexicarVehicleId);

			setPrices({
				buyAndSell: stockPrices.buyAndSell,
				priceDealerSelling: stockPrices.priceDealerSelling,
				priceCash: stockPrices.priceCash,
				priceFinanced: stockPrices.priceFinanced,
				priceOffer: stockPrices.priceOffer
			});
		})();
	}, [flexicarVehicleId]);

	useEffect(() => {
		if (priceTypeEdition && !isEqual(prices, INITIAL_PRICES)) {
			const pvp = {
				[SELL_CONTRACT_PRICE_TYPES.PVP_CONTADO]: prices.priceCash,
				[SELL_CONTRACT_PRICE_TYPES.PVP_FINANCIADO]: prices.priceOffer || prices.priceFinanced,
				[SELL_CONTRACT_PRICE_TYPES.PVD]: prices.priceDealerSelling
			}[priceTypeEdition];

			if (pvp !== pvpEdition) {
				setNewPvp(pvp);
				setHasPricesChanged(true);
			}
		}
	}, [priceTypeEdition, prices, pvpEdition]);

	return (
		<>
			<Form
				ref={formRef}
				onSubmit={handleSubmitting(onSubmit)}
				defaultValues={defaultValues}
				schema={bookingContractSchema(rol, mode)}
			>
				<ClientData provincesOptions={provincesOptions} />
				<VehicleData dealershipsOptions={dealershipsOptions} />
				<BookingData mode={mode} />
				<SellData
					gestoriasOptions={gestoriasOptions}
					dealershipsOptions={dealershipsOptions}
					provincesOptions={provincesOptions}
					warrantyOptions={warrantyOptions}
					prices={prices}
				/>
				<BookingResume />
				<Observations />
				{mode === "create" && locutionNeeded && <Locution data={data} />}
				<button
					className={`btn btn-flexicar-orange ${styles.createContractButton}`}
					type="submit"
					disabled={isSubmitting}
				>
					{
						{
							create: "Crear Reserva",
							edit: "Guardar"
						}[mode]
					}
				</button>
			</Form>

			{mode === "edit" && (
				<GenericModal
					isOpen={hasPricesChnaged}
					setIsOpen={setHasPricesChanged}
					headContent="Aviso de cambios en el precio del contrato"
					bodyContent={
						<div className={styles.changePriceModal}>
							<span>
								Atención, el contrato ha sufrido un cambio de precio, antes el valor era{" "}
								{formatNumberWithDecimals(pvpEdition)} y ahora el valor es{" "}
								{formatNumberWithDecimals(newPvp)}.
							</span>
							<span>
								Por favor, actualiza los datos necesarios y dé a “Guardar“ para actualizar el
								contrato.
							</span>
						</div>
					}
					footerContent={
						<button
							className="btn-flexicar-orange"
							onClick={() => setHasPricesChanged(false)}
						>
							Aceptar
						</button>
					}
				/>
			)}
		</>
	);
};

export default BookingContract;
