import PaneHeader from "@components/PaneHeader";
import FormRadioButtonGroup from "@components/Form/RadioButtonGroup/FormRadioButtonGroup";
import { BOOKING_PAYMENT_METHODS } from "@constants/bookings";


const BookingPaymentForm = ({ children }) => {
	return (
		<>
			<div>
				<PaneHeader title="Forma de pago" />
			</div>
			<div>Selecciona Forma de pago</div>

			<FormRadioButtonGroup
				name="paymentMethod"
				options={[
					{ value: BOOKING_PAYMENT_METHODS.PASARELA, label: "Pasarela" },
					{ value: BOOKING_PAYMENT_METHODS.PRESENCIAL, label: "Presencial" },
					{ value: BOOKING_PAYMENT_METHODS.TRANSFERENCIA, label: "Transferencia" }
				]}
			/>
			{children}
		</>
	);
};

export default BookingPaymentForm;
