import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";

import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { getAddress, getSocieties } from "@services/Dealers/dealers.service";
import Select from "@components/Select"

import styles from "./index.module.scss";

const AddDealerModal = (props) => {
  const {
    modalAddDealer,
    setModalAddDealer,
    setModalAddress,
    setNewDealerHandler,
    newDealer,
  } = props;

  const [needTPV, setNeedTPV] = useState(null);
  const [societies, setSocieties] = useState([]);
  const [societyTPV, setSocietyTPV] = useState(null);

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ defaultValues: { tpv: societyTPV } });

  useEffect(() => {
    reset({
      nombre: newDealer.nombre,
      tpv: newDealer.tpv,
      local_type: newDealer.local_type,
      email: newDealer.email,
      telefono: newDealer.telefono,
      locality: newDealer.locality,
      direccion: newDealer.direccion,
      coordinate_longitude: newDealer.coordinate_longitude,
      coordinate_latitude: newDealer.coordinate_latitude,
    });
    setNeedTPV(newDealer.local_type === 1 ? true : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newDealer, modalAddDealer]);

  useEffect(() => {
    getSocieties(`/society`)
      .then((response) => {
        setSocieties(response);
      })
      .catch((e) => {
        throw e;
      });
  }, []);

  const localTypeHandler = (e) => {
    if (e.target.value === "1") {
      setNeedTPV(true);
    } else {
      setNeedTPV(false);
    }
  };

  const onSubmit = (data) => {
    let societies = [];
    data.society.forEach((item) => societies.push(item));

    if (data.direccion !== newDealer.direccion) {
      getAddress(data.direccion.replace(" ", "+"))
        .then((res) => {
          setNewDealerHandler({
            ...newDealer,
            nombre: data.nombre,
            society: societies,
            tpv: societies[0].tpv,
            local_type: data.local_type,
            email: data.email,
            telefono: data.telefono,
            locality: data.locality,
            direccion: res.data.results[0].formatted_address,
            coordinate_longitude: Number(
              res.data.results[0].geometry.location.lng
            ),
            coordinate_latitude: Number(
              res.data.results[0].geometry.location.lat
            ),
          });

          setModalAddDealer();
          setModalAddress();
        })
        .catch((e) => {
          throw e;
        });
    } else {
      setNewDealerHandler({
        ...newDealer,
        nombre: data.nombre,
        society: societies,
        tpv: societies[0].tpv,
        local_type: data.local_type,
        email: data.email,
        telefono: data.telefono,
        locality: data.locality,
        direccion: newDealer.direccion,
        coordinate_longitude: Number(newDealer.coordinate_longitude),
        coordinate_latitude: Number(newDealer.coordinate_latitude),
      });

      setModalAddDealer();
      setModalAddress();
    }
  };

  const tpvHandler = (data) => {
    data && setSocietyTPV(data[0]?.tpv);
  };

  const cancelHandler = () => {
    setModalAddDealer();
    setNewDealerHandler({
      id: undefined,
      nombre: undefined,
      society: undefined,
      tpv: undefined,
      local_type: undefined,
      email: undefined,
      telefono: undefined,
      movil: undefined,
      locality: undefined,
      direccion: undefined,
      coordinate_longitude: undefined,
      coordinate_latitude: undefined,
      schedule: undefined,
      slug: undefined,
      administrationPhone: undefined,
      administrationEmail: undefined,
      encargadoLocal: undefined,
    });
  };

  return (
    <div>
      <Modal isOpen={modalAddDealer} toggle={cancelHandler}>
        <ModalHeader className={styles.modal_header} toggle={cancelHandler}>
          {"Datos obligatorios. Paso 1 de 4"}
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Container>
              <Row>
                <Col className="my-1 col-12">
                  <input
                    readOnly={newDealer.nombre}
                    className={`form-control ${errors.nombre && "is-invalid"} `}
                    placeholder="Nombre"
                    {...register("nombre", { required: true })}
                  />
                  {errors.nombre?.type === "required" && (
                    <span className={`${styles.error}`}>
                      Introduzca el nombre
                    </span>
                  )}
                </Col>
              </Row>

              <Row>
                <Col
                  className={`my-1 col-12 ${errors.society && "is-invalid"}`}
                >
                  <Controller
                    name="society"
                    control={control}
                    rules={{ required: true }}
                    defaultValue={newDealer?.society}
                    render={({ field }) => (
                      <Select
                        {...field}
                        placeholder="Seleccione Sociedad"
                        isMulti
                        options={societies}
                        onBlur={tpvHandler(field.value)}
                      />
                    )}
                  />
                  {errors.society?.type === "required" && (
                    <span className={`${styles.error}`}>
                      Seleccione sociedad
                    </span>
                  )}
                </Col>
                <Col className="my-1 col-6">
                  <select
                    onClick={localTypeHandler}
                    className={`form-control form-select ${
                      errors.local_type && "is-invalid"
                    }`}
                    {...register("local_type", { required: true })}
                  >
                    <option value="">Tipo local:</option>
                    {newDealer.local_type === 1 ? (
                      <option value="1" selected>
                        Concesionario
                      </option>
                    ) : (
                      <option value="1">Concesionario</option>
                    )}
                    {newDealer.local_type === 2 ? (
                      <option value="2" selected>
                        Caseta
                      </option>
                    ) : (
                      <option value="2">Caseta</option>
                    )}
                  </select>
                  {errors.local_type?.type === "required" && (
                    <span className={`${styles.error}`}>
                      Seleccione tipo de local
                    </span>
                  )}
                </Col>
                {(newDealer.local_type === 1 || needTPV || newDealer.tpv) && (
                  <Col className="my-1 col-6">
                    <input
                      value={societyTPV ? societyTPV : newDealer?.tpv}
                      type="number"
                      className={`form-control ${errors.tpv && "is-invalid"}`}
                      placeholder="TPV"
                      {...register("tpv", { required: true })}
                    />
                    {errors.tpv?.type === "required" && (
                      <span className={`${styles.error}`}>Introduzca tpv</span>
                    )}
                  </Col>
                )}
              </Row>
              <Row>
                <h6 className="my-1 col-12">Contacto:</h6>
                <Col className="my-1 col-12">
                  <input
                    className={`form-control ${errors.email && "is-invalid"}`}
                    placeholder="Email"
                    {...register("email", {
                      required: true,
                      pattern:
                        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i,
                    })}
                  />
                </Col>
                {errors.email?.type === "required" && (
                  <span className={`ml-4 ${styles.error}`}>
                    Introduzca email
                  </span>
                )}
                {errors.email?.type === "pattern" && (
                  <span className={`${styles.error}`}>Email no válido</span>
                )}
              </Row>
              <Row>
                <Col className="my-1 col-6">
                  <input
                    type="tel"
                    className={`form-control ${
                      errors.telefono && "is-invalid"
                    }`}
                    placeholder="Teléfono"
                    {...register("telefono", {
                      required: true,
                      maxLength: 9,
                      minLength: 9,
                      pattern: /^[0-9]*$/,
                    })}
                  />
                  {errors.telefono?.type === "required" && (
                    <span className={`${styles.error}`}>
                      Introduzca teléfono fijo
                    </span>
                  )}

                  {errors.telefono?.type === "pattern" && (
                    <span className={`${styles.error}`}>
                      Teléfono no válido
                    </span>
                  )}
                  {errors.telefono?.type === "maxLength" && (
                    <span className={`${styles.error}`}>
                      Teléfono no válido
                    </span>
                  )}
                  {errors.telefono?.type === "minLength" && (
                    <span className={`${styles.error}`}>
                      Teléfono no válido
                    </span>
                  )}
                </Col>
              </Row>

              <Row>
                <Col className="my-1 col-12">
                  <h6>Localidad:</h6>
                  <input
                    className={`form-control ${
                      errors.locality && "is-invalid"
                    }`}
                    placeholder="Localidad"
                    {...register("locality", {
                      required: true,
                      maxLength: 50,
                      minLength: 0,
                    })}
                  />
                  {errors.locality?.type === "required" && (
                    <span className={`${styles.error}`}>
                      Introduzca la localidad
                    </span>
                  )}
                  {errors.locality?.type === "maxLength" && (
                    <span className={`${styles.error}`}>
                      Número de caracteres no permitido
                    </span>
                  )}
                </Col>
                <Col className="my-1 col-12">
                  <h6>Dirección:</h6>
                  <input
                    className={`form-control ${
                      errors.direccion && "is-invalid"
                    }`}
                    placeholder="Calle, número, provincia, etc..."
                    {...register("direccion", {
                      required: true,
                      maxLength: 255,
                      minLength: 0,
                    })}
                  />
                  {errors.direccion?.type === "required" && (
                    <span className={`${styles.error}`}>
                      Introduzca la dirección
                    </span>
                  )}
                  {errors.direccion?.type === "maxLength" && (
                    <span className={`${styles.error}`}>
                      Número de caracteres no permitido
                    </span>
                  )}
                </Col>
              </Row>
            </Container>
          </form>
        </ModalBody>
        <ModalFooter>
          <Row className="justify-content-center">
            <Button className="mx-5" color="secondary" onClick={cancelHandler}>
              Cancelar
            </Button>
            <Button
              className={`${styles.btn_custom} mx-5`}
              onClick={handleSubmit(onSubmit)}
            >
              Siguiente
            </Button>
          </Row>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AddDealerModal;
