import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import PaginationFooter from "@components/PaginationFooter";
import { MdEdit, MdGroupAdd } from "react-icons/md";
import styles from "./index.module.scss";
import vehicleImgAux from "@constants/vehicleImgAux";
import { checkVehicleValidationRol } from "@constants/roles";

const TableStock = ({
	rol,
	loadVehiclePage,
	changePagination,
	launchModal,
	launchPromoState,
	showNumb,
	paginationMin,
	cars,
	totalCars,
	changeOrder,
	order,
	loadVehicleEdition,
	setState
}) => {
	const [orderDir, setOrderDir] = useState(null);
	const [orderValue, setOrderValue] = useState(null);

	const setOrder = (v) => {
		let d = "desc";
		if (v === orderValue) {
			d = orderDir === "asc" ? "desc" : "asc";
		}
		changeOrder(v, d);
		setOrderValue(v);
		setOrderDir(d);
	};

	const renderArrow = (v) => {
		if (orderValue === v) {
			return orderDir === "asc" ? "🔼" : "🔽";
		}
	};

	useEffect(() => {
		if (!order.value || !order.dir) {
			setOrderDir(null);
			setOrderValue(null);
		} else {
			setOrderDir(order.dir);
			setOrderValue(order.value);
		}
	}, [order]);

	const renderCarStateCircle = (car) => {
		if (car.color_verde === "verde_activo") {
			return (
				<>
					<div className={`circulo ${car.color_verde}`} />
					<span>Disponible</span>
				</>
			);
		}
		if (car.color_amarillo === "amarillo_activo") {
			return (
				<>
					<div className={`circulo ${car.color_amarillo}`} />
					<span>Reservado</span>
				</>
			);
		}
		if (car.color_rojo === "rojo_activo") {
			return (
				<>
					<div className={`circulo ${car.color_rojo}`} />
					<span>Vendido</span>
				</>
			);
		}

		return <></>;
	};

	return (
		<div className={styles.tableContainer}>
			<Table>
				<thead>
					<tr>
						<th></th>
						<th>Imagen</th>
						<th>Local</th>
						<th>Vehículo</th>
						<th>Matrícula</th>
						<th
							onClick={() => setOrder("v.pvp_contado")}
							className="pointer"
						>
							<div
								style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
							>
								<span>Contado</span>
								{renderArrow("v.pvp_contado")}
							</div>
						</th>
						<th
							onClick={() => setOrder("v.pvp_financiado")}
							className="pointer"
						>
							<div
								style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
							>
								Financiado {renderArrow("v.pvp_financiado")}
							</div>
						</th>
						<th
							onClick={() => setOrder("v.combustible")}
							className="pointer"
						>
							<div
								style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
							>
								Comb. {renderArrow("v.combustible")}
							</div>
						</th>
						<th
							onClick={() => setOrder("v.anio")}
							className="pointer"
						>
							<div
								style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
							>
								Año {renderArrow("v.anio")}
							</div>
						</th>
						<th
							onClick={() => setOrder("v.kilometros")}
							className="pointer"
						>
							<div
								style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
							>
								Km. {renderArrow("v.kilometros")}
							</div>
						</th>
						<th>Acciones</th>
					</tr>
				</thead>
				<tbody>
					{cars.map((car, idx) => {
						return (
							<tr
								key={"vehicle" + idx}
								style={
									idx % 2 ? { background: "#ffffff" } : { background: "rgba(242,242,242,0.5)" }
								}
							>
								<td>
									<span
										className="pointer"
										onClick={() => {
											loadVehiclePage(car.vehicle_id);
										}}
									>
										<div
											style={{
												width: "20px",
												height: "60px",
												display: "flex",
												flexDirection: "column",
												justifyContent: "space-around"
											}}
										>
											<div className={`circulo ${car.color_verde}`} />
											<div className={`circulo ${car.color_amarillo}`} />
											<div className={`circulo ${car.color_rojo}`} />
										</div>
									</span>
								</td>
								<td>
									<span
										className="pointer"
										onClick={() => loadVehiclePage(car.vehicle_id)}
									>
										<img
											alt={"carimg-" + idx}
											src={car.url}
											height="80"
											width="120"
											className={styles.carImg}
											onError={({ currentTarget }) => {
												currentTarget.onerror = null;
												currentTarget.src = vehicleImgAux.commingSoon;
											}}
										/>
										<div className={styles.car_state_container}>{renderCarStateCircle(car)}</div>
									</span>
								</td>
								<td data-label="Local">
									<span
										className="pointer"
										onClick={() => loadVehiclePage(car.vehicle_id)}
										style={{
											color: "inherit",
											textDecoration: "none"
										}}
									>
										{car.nombre}
									</span>
								</td>
								<td data-label="Vehículo">
									<span
										className="pointer"
										onClick={() => loadVehiclePage(car.vehicle_id)}
										style={{
											color: "inherit",
											textDecoration: "none"
										}}
									>
										{car.vehiculo}
									</span>
								</td>
								<td data-label="Matrícula">
									<span
										className="pointer"
										onClick={() => loadVehiclePage(car.vehicle_id)}
										style={{
											color: "inherit",
											textDecoration: "none"
										}}
									>
										{car.matricula}
									</span>
								</td>
								<td data-label="PVP al contado">
									<span
										className="pointer"
										onClick={() => loadVehiclePage(car.vehicle_id)}
										style={{
											color: "inherit",
											textDecoration: "none"
										}}
									>
										{Math.trunc(car.pvp_contado).toLocaleString("en").replace(/,/, ".")} €
									</span>
								</td>
								<td data-label="PVP financiado">
									<span
										className="pointer"
										onClick={() => loadVehiclePage(car.vehicle_id)}
										style={{
											color: "inherit",
											textDecoration: "none"
										}}
									>
										{Math.trunc(car.pvp_financiado).toLocaleString("en").replace(/,/, ".")} €
									</span>
								</td>
								<td data-label="Combustible">
									<span
										className="pointer"
										onClick={() => loadVehiclePage(car.vehicle_id)}
										style={{
											color: "inherit",
											textDecoration: "none"
										}}
									>
										{car.combustible}
									</span>
								</td>
								<td data-label="Año">
									<span
										className="pointer"
										onClick={() => loadVehiclePage(car.vehicle_id)}
										style={{
											color: "inherit",
											textDecoration: "none"
										}}
									>
										{car.aniomes}
									</span>
								</td>
								<td data-label="Km.">
									<span
										className="pointer"
										onClick={() => loadVehiclePage(car.vehicle_id)}
										style={{
											color: "inherit",
											textDecoration: "none"
										}}
									>
										{car.kilometros.toLocaleString("en").replace(/,/, ".")}
									</span>
								</td>
								<td>
									<div className={styles.icons}>
										<button
											title="Crear Lead"
											onClick={() => {
												setState((prev) => ({
													...prev,
													checkLead: {
														...prev.checkLead,
														vehicleId: car.vehicle_id
													}
												}));
												launchModal("modal_check", car.vehicle_id, true);
											}}
										>
											<MdGroupAdd
												color="#007bff"
												size={36}
												className="px-1 mx-1"
												title="Crear Lead"
												data-toggle="tooltip"
												data-placement="top"
												role="button"
											/>
											<span>Crear Lead</span>
										</button>
										{rol.indexOf(1) !== -1 || rol.indexOf(7) !== -1 ? (
											<div className={styles.icon}>
												<div
													id={"outlet-" + car.vehicle_id}
													onClick={() =>
														launchPromoState(car.outlet === 1 ? 0 : 1, car.vehicle_id, idx)
													}
													className={`circulo pointer ${
														car.outlet === 1 ? "verde_activo" : "verde_inactivo"
													} d-none d-lg-block`}
												></div>
											</div>
										) : (
											<span> </span>
										)}
										{car.vehicle_id_flexicar && checkVehicleValidationRol(rol) && (
											<button
												title="Editar Vehículo"
												onClick={() => loadVehicleEdition(car.vehicle_id_flexicar, car.vehicle_id)}
											>
												<MdEdit
													size={36}
													color="#007bff"
													className="px-1 mx-1"
													data-toggle="tooltip"
													data-placement="top"
													title="Editar Vehículo"
													role="button"
												/>
												<span>Editar Vehículo</span>
											</button>
										)}
									</div>
								</td>
							</tr>
						);
					})}
				</tbody>
			</Table>
			<PaginationFooter
				paginationMin={paginationMin}
				total={totalCars}
				showNumb={showNumb}
				elements={cars}
				changePagination={changePagination}
			/>
		</div>
	);
};

export default TableStock;
