import { errorToast } from "@helpers/toastFunctions";
import instance from "@services/base";
import { API } from "@services/urlConstants";

const getFilter = async (query, payload = null, localeES) => {
	try {
		const response = await instance.post(API.FILTROS, {
			dataCall: {
				data_query: query,
				data_call: payload
			}
		});
		if (query === "locales" || query === "locales_citar") {
			return response.data
				.map((x) => ({
					label: x.nombre,
					value: x.id
				}))
				.filter((local) => local.label !== "Esplugas");
		}
		if(query === "empresa_user" && localeES){
			return response.data.map((x) => ({
				label: x.nombre,
				value: x.id
			}))?.filter(soc => ![26, 27].includes(soc.value));	
		}
		return response.data.map((x) => ({
			label: x.nombre,
			value: x.id
		}));
	} catch (err) {
		errorToast(`Error al conseguir los datos del select ${query}`);
	}
};

export const getFilterGestionType = () => [
	{
		label: "Compra directa",
		value: 1
	},
	{
		label: "Gestión de venta",
		value: 0
	}
];
export const getFilterCompanies = async (payload) => await getFilter("empresas", payload);

export const getFilterBanks = async () => await getFilter("banco");

export const getFilterProvinces = async () => await getFilter("provincias");

export const getFilterDealershipProvince = async (payload) =>
	await getFilter("provincias", payload);

export const getFilterUserCompanies = async (payload, localeES) => await getFilter("empresa_user", payload, localeES);

export const getFilterCompanyDealerships = async (payload) =>
	await getFilter("locales_empresa", payload);

export const getFilterGestorias = async () => await getFilter("gestorias");

export const getFilterPaymentMethods = async () => await getFilter("forma_pago");

export const getFilterDealershipsLocals = async () => await getFilter("sedes_locales");

export const getFilterFlexicarDealerships = async () => await getFilter("concesionarios_flexicar");

export const getFilterDealerships = async (payload) => await getFilter("locales", payload);

export const getFilterState = async () => await getFilter("estado");

export const getFilterAppointmentDealerships = async () => await getFilter("locales_citar");

export const getFilterAgents = async () => await getFilter("agentes");

export const getFilterAgent = async () => await getFilter("agente");

export const getFilterAllDealersihps = async () => await getFilter("locales_todos");

export const getFilterAgentsLeadCompra = async (payload) =>
	await getFilter("agente_lead_compra", payload);

export const getOriginLeadCompra = async () => await getFilter("origen_lead_compra");

export const getFilterCommercialBrands = async (payload) =>
	await getFilter("marcas_comerciales", payload);

export const getFilterActualState = async () => await getFilter("estado_actual");

export const getFilterContact = async () => await getFilter("contacto");

export const getFilterOrigin = async () => await getFilter("procedencia");

export const getFilterVehicleBrand = async () => await getFilter("marca_vehiculo");

export const getFilterProvincesLiteral = async () => await getFilter("provincias_literal");

export const getFilterYear = async () => await getFilter("anno");

export const getFilterKmsFrom = async () => await getFilter("km_desde");

export const getFilterKmsUntil = async () => await getFilter("km_hasta");

export const getFilterGestor = async (payload) => await getFilter("gestor", payload);

export const getFilterInvolvedEmployee = async (payload) =>
	await getFilter("empleado_implicado", payload);

export const getFilterActionsDone = async () => await getFilter("acciones");

export const getFilterSellLeadState = async () => await getFilter("estado_lead_venta");

export const getFilterPurchaseLeadState = async () => await getFilter("estado_lead_compra");

export const getFilterGVOBrands = async () => await getFilter("marcas_gvo");

export const getFilterGVOModels = async (payload) => await getFilter("modelos_gvo", payload);

export const getFilterSellAgent = async (payload) => await getFilter("agente_venta", payload);

export const getFilterSellAgent2 = async () => await getFilter("agente_ventas");

export const getFilterSellLeadStateActions = async () =>
	await getFilter("estado_lead_venta_acciones");

export const getFilterCompanieDealership = async (payload) =>
	await getFilter("empresas_local", payload);

export const getFilterCancellationReason = async () => await getFilter("cancellation_reason");

export const getFilterGenericVehicles = async () => await getFilter("vehiculo_generico");

export const getFilterStockDealerships = async () => await getFilter("locales_stock");

export const getFilterCarroceria = async () => await getFilter("carroceria");

export const getFilterFuel = async () => await getFilter("combustible");

export const getFilterTransmission = async () => await getFilter("transmision");

export const getFilterSeats = async () => await getFilter("plazas");

export const getFilterStockCompanies = async () => await getFilter("empresa_stock");

export const getFilterRoles = async () => await getFilter("roles");
