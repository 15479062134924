import GenericModal from "@components/GenericModal";
import EditBookingContract from "@views/Booking/EditBookingContract";
import StepsCreateBookingContract from "@views/Booking/StepsCreateBookingContract";

const BookingContractModal = ({
	isOpen,
	setIsOpen,
	lead,
	client,
	mode,
	document,
	getReservation,
	setLead,
	recall,
	getLeadData, 
	idLead
}) => {
	
	return (
		<>
			<GenericModal
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				modalClassName="modal-xl"
				headContent={`Reservar ${lead?.nombre_marca_v || document?.datos?.marca} ${
					lead?.nombre_modelo_v || document?.datos?.modelo
				} ${lead?.matricula || document?.datos?.matricula}`}
				bodyContent={
					{
						create: (
							<StepsCreateBookingContract
								data={{ lead, client }}
								onCreate={async () => {
									await getReservation();
									getLeadData(idLead);
									setLead((prevLead) => ({
										...prevLead,
										id_estado_lead: lead.id_estado_lead,
										nombre_estado_lead: lead.nombre_estado_lead
									}));
									setIsOpen(false);
								}}
							/>
						),
						edit: (
							<EditBookingContract
								document={document}
								onEdit={async () => {
									setIsOpen(false);
									await recall();
								}}
							/>
						)
					}[mode]
				}
			/>
		</>
	);
};
export default BookingContractModal;
