import FormCheckBox from "@components/Form/Checkbox/FormCheckbox";
import PaneHeader from "@components/PaneHeader";
import React from "react";
import styles from "@styles/views/BookingContract/booking-locution.module.scss";

import { useFormContext } from "react-hook-form";

const Locution = ({ data }) => {
	const { watch } = useFormContext();

	const clientProvince = watch("client.province")?.label;
	const clientName = watch("client.name");
	const clientFirstSurname = watch("client.firstSurname");
	const clientDNI = watch("client.dni");
	const clientPhone = watch("client.phone");
	const clientEmail = watch("client.email");
	const vehicleCompanie = watch("vehicle.companie")?.label;
	const bookingPrice = watch("booking.price");
	const vehiclePlate = watch("vehicle.plate");
	const vehicleKM = watch("vehicle.km");
	const bookingDate = watch("booking.date");

	return (
		<div>
			<PaneHeader title="Locución" />
			<p>Iniciar grabación</p>
			<p>¿Acepta que grabemos la conversación?</p>
			<p>[Esperar respuesta del cliente]</p>
			<p>
				{`En `}
				<span className={styles.bold}>{clientProvince}</span>
				{` a ${bookingDate}`}
			</p>
			<p>
				{`D/Dª `}
				<span className={styles.bold}>{clientName}</span>
				{` `}
				<span className={styles.bold}>{clientFirstSurname}</span>
				{` con DNI/NIE `}
				<span className={styles.bold}>{clientDNI}</span>
				{` teléfono `}
				<span className={styles.bold}>{clientPhone}</span>
				{` y email `}
				<span className={styles.bold}>{clientEmail}</span>
				{` entrega a `}
				<span className={styles.bold}>{vehicleCompanie}</span>
				{`
                con NIF: B09758327 el importe de `}
				<span className={styles.bold}>{bookingPrice} </span>
				euros a través del pago con tarjeta de crédito, en concepto de reserva del vehículo:
				<span className={styles.bold}> {data.lead.nombre_marca_v} </span>
				{` `}
				<span className={styles.bold}>{data.lead.nombre_modelo_v} </span>
				{`
                con matrícula `}
				<span className={styles.bold}>{vehiclePlate}</span>
				{` y `}
				<span className={styles.bold}>{vehicleKM} </span>
				kilómetros. La validez de la reserva tiene una duración de 7 días a partir de hoy. En el
				caso de no abonar la cantidad final en dicho plazo, siempre y cuando sea por motivos ajenos
				a nuestra gestión, la reserva perderá su validez y
				<span className={styles.bold}> {vehicleCompanie} </span>
				se guarda el derecho de devolución del importe de la misma, quedando exento de reclamación
				alguna.
			</p>
			<p>¿Acepta estas condiciones?</p>
			<p>[Esperar respuesta del cliente]</p>
			<p>Fin de la grabación</p>
			<FormCheckBox
				name="locution"
				label="¿Confirma que ha realizado la locución?"
			/>
		</div>
	);
};

export default Locution;
