import React, { forwardRef, useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import ReactSelect, { components } from "react-select";
import { MdCheck } from "react-icons/md";

import OverflowWrapper from "./OverflowWrapper";
import customStyles from "./styles";
import { errorToast } from "@helpers/toastFunctions";

export const MultiValueContainer = (props) => {
	return (
		<div
			data-targetid={props.data.value}
			{...props.innerProps}
		>
			{props.children}
		</div>
	);
};

export const ValueContainer = ({ children, ...props }) => {
	return (
		<components.ValueContainer {...props}>
			{props.hasValue ? <OverflowWrapper>{children}</OverflowWrapper> : children}
		</components.ValueContainer>
	);
};

export const Option = ({ label, isSelected, ...rest }) => (
	<components.Option {...rest}>
		<div className="d-flex justify-content-between align-items-center">
			{label}
			{isSelected && (
				<MdCheck
					size={24}
					color="#D34600"
				/>
			)}
		</div>
	</components.Option>
);

/**
 * @typedef {object} ExtraFormSelectProps
 * @property {Function} loadOptions - Function to load the options on focus
 * @property {boolean} reloadOptions - Reload options on focus
 */

/**
 * @typedef {import("react-select").Props & ExtraFormSelectProps} CustomSelectProps
 */

/**
 * @type React.ForwardRefRenderFunction<?, CustomSelectProps>
 */
const Select = forwardRef((props, ref) => {
	const { t } = useTranslation("select");
	const selectRef = useRef();

	const [options, setOptions] = useState(undefined);
	const [isLoading, setIsLoading] = useState(false);
	const [menuIsOpen, setMenuIsOpen] = useState(false);

	const lazyLoadOptions = async () => {
		if ((options === undefined && props.loadOptions) || props.reloadOptions) {
			setIsLoading(true);
			setOptions(undefined);

			try {
				const opts = await props.loadOptions();
				setOptions(opts);
			} catch (error) {
				errorToast(t("errors.loadOptions"));
			} finally {
				setIsLoading(false);
			}
		}
	};

	const handleClickOutside = (event) => {
		if (selectRef.current && !selectRef.current.contains(event.target)) {
			setMenuIsOpen(false);
		}
	};

	useEffect(() => {
		if (menuIsOpen) {
			document.addEventListener("mousedown", handleClickOutside);
		} else {
			document.removeEventListener("mousedown", handleClickOutside);
		}
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [menuIsOpen]);

	const handleMenuOpen = () => {
		lazyLoadOptions();
		setMenuIsOpen(true);
	};

	const handleMenuClose = () => {
		setMenuIsOpen(false);
	};

	const handleChange = (selectedOption) => {
		props.onChange(selectedOption);
		if (!props.isMulti) {
			setMenuIsOpen(false);
		}
	};

	return (
		<div ref={selectRef}>
			<ReactSelect
				ref={ref}
				isSearchable
				menuPlacement="auto"
				menuPosition="fixed"
				closeMenuOnSelect={!props.isMulti ?? true}
				hideSelectedOptions={false}
				maxMenuHeight={300}
				noOptionsMessage={() => t("noOptions")}
				loadingMessage={() => t("loadingOptions")}
				isClearable={props.isMulti ?? false}
				isLoading={isLoading}
				options={options}
				onMenuOpen={handleMenuOpen}
				onMenuClose={handleMenuClose}
				menuIsOpen={menuIsOpen}
				onChange={handleChange}
				{...props}
				components={{
					...props.components,
					Option,
					...(props.isMulti && {
						MultiValueContainer,
						ValueContainer
					})
				}}
				styles={{
					...customStyles(),
					...props.styles
				}}
				theme={(theme) => ({
					...theme,
					colors: {
						...theme.colors,
						primary: "#fe9800",
						primary25: "#ffc548"
					}
				})}
			/>
		</div>
	);
});

export default Select;
